import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonUtil, IResetPasswordModel, UserService } from '@libs/vc-core-lib';
import { finalize, take } from 'rxjs';
import { FormComponent, InputComponent, NotificationService } from '@libs/vc-common-ui-lib';

@Component({
    selector: 'vc-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent {
    readonly resetPasswordText = $localize`:@@ACCOUNT.RESET_PASSWORD:Reset password`;
    readonly welcomeText = $localize`:@@LOGIN.WELCOME_TO_VIKINGCLOUD:Welcome to VikingCloud!`;

    showPasswordRequirements = false;
    isWelcome = false;
    mfaEnabled = false;
    submitLoading = false;

    username!: string;
    token!: string;
    newPassword!: string;
    confirmPassword!: string;
    mfaToken!: string;

    @ViewChild('resetPasswordForm')
    resetPasswordForm!: FormComponent;

    @ViewChild('mfaInput')
    mfaInput!: InputComponent;

    constructor(
        private _router: Router,
        private route: ActivatedRoute,
        private _userService: UserService,
        private _notification: NotificationService
    ) {
        // Check URL and reset view state if necessary
        const url = this._router.routerState.snapshot.url;
        if (url === '/reset-password' || url.includes('/welcome/')) {
            this.mfaEnabled = false;
        }
        if (url === '/reset-password?verify=true' && !this.mfaEnabled) {
            this._removeParam();
        }

        const snapshot = this.route.snapshot;
        this.isWelcome = snapshot.routeConfig?.path?.includes('welcome') ?? false;
        if (snapshot.params?.username) {
            this.username = snapshot.params?.username;
            this.isWelcome
                ? this._userService
                      .sendInitToken(this.username)
                      .pipe(
                          take(1),
                          finalize(() => {
                              CommonUtil.hideAppLoader();
                          })
                      )
                      .subscribe({
                          error: () => {
                              this._notification.error(
                                  '',
                                  $localize`:@@LOGIN.SEND_RESET_ISSUE:There was an issue sending your token.  Please try again.`
                              );
                          },
                      })
                : CommonUtil.hideAppLoader();
        }

        CommonUtil.hideAppLoader();
    }

    onResetPassword() {
        if (!this.resetPasswordForm?.submit()) return;

        if (this.newPassword !== this.confirmPassword) {
            this._notification.error('', $localize`:@@LOGIN.PASSWORDS_DO_NOT_MATCH:Passwords do not match`);
        }

        this.submitLoading = true;
        this.mfaEnabled ? this._resetPasswordMfa() : this._resetPassword();
    }

    private _resetPassword() {
        const resetPasswordForm: IResetPasswordModel = {
            username: this.username,
            token: this.token,
            newPassword: this.newPassword,
        };

        this._userService
            .resetPassword(resetPasswordForm)
            .pipe(
                take(1),
                finalize(() => (this.submitLoading = false))
            )
            .subscribe({
                next: () => {
                    this._notification.success(
                        '',
                        $localize`:@@LOGIN.PASSWORD_RESET_SUCCESSFUL:Password reset successful`
                    );
                    this._router.navigate([`/login`]);
                },
                error: (error) => {
                    if (error.status == 403) {
                        this.mfaEnabled = true;
                        this._router.navigate([], {
                            queryParams: {
                                verify: true,
                            },
                        });
                        setTimeout(() => this.mfaInput?.focus(), 0); // autofocus on auth code input field when it appears
                        return;
                    }

                    this._notification.error(
                        '',
                        $localize`:@@LOGIN.PASSWORD_ISSUE:There was an issue changing your password`
                    );
                },
            });
    }

    private _resetPasswordMfa() {
        const resetPasswordForm: IResetPasswordModel = {
            username: this.username,
            token: this.token,
            newPassword: this.newPassword,
            mfaToken: this.mfaToken,
        };

        this._userService
            .resetPasswordMfa(resetPasswordForm)
            .pipe(
                take(1),
                finalize(() => (this.submitLoading = false))
            )
            .subscribe({
                next: () => {
                    this._notification.success(
                        '',
                        $localize`:@@LOGIN.PASSWORD_RESET_SUCCESSFUL:Password reset successful`
                    );
                    this._router.navigate([`/login`]);
                },
                error: () => {
                    this._notification.error('', $localize`:@@LOGIN.VALIDATION.AUTH_FAILED:Authentication failed.`);
                },
            });
    }

    navigateToForgotPassword() {
        this._router.navigate(['/forgot-password']);
    }

    private _removeParam() {
        this._router.navigate([], {
            replaceUrl: true,
            queryParams: {},
        });
    }
}
