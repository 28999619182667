<a
    class="vc-link"
    [style.color]="color"
    [style.padding]="padding"
    [style.text-decoration]="underlineText ? 'underline' : 'none'"
    [href]="url"
    [routerLink]="routerLink ? [routerLink] : null"
    [target]="target"
    [matTooltip]="tooltip ?? url"
    [matTooltipPosition]="tooltipPosition"
    (click)="linkTrigger($event)"
    [attr.tabindex]="tabindex"
    >{{ label ?? url }}<mat-icon *ngIf="showOpenInNewWindow" class="vc-link-button-icon">open_in_new</mat-icon></a
>
