import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
    selector: 'vc-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
    @Input()
    buttonMode!: 'primary' | 'basic' | 'icon';

    /** Menu button label */
    @Input()
    label!: string;

    /** Menu trigger icon */
    @Input()
    icon: string = 'more_vert';

    /** Menu trigger icon color */
    @Input()
    iconColor!: string;

    /** Whether to disable menu button */
    @Input()
    disabled = false;

    /** Menu trigger tooltip */
    @Input()
    tooltip!: string;

    /** Menu trigger tooltip */
    @Input()
    tooltipPosition: TooltipPosition = 'below';

    /** Aria label for menu trigger icon */
    @Input()
    ariaLabel!: string;

    /** Specifies button badge icon, what displays in top right corner */
    @Input()
    badgeIcon!: string | null;

    /** Event emitted when the menu is closed. */
    @Output()
    menuClosed = new EventEmitter();

    @ViewChild(MatMenuTrigger)
    trigger!: MatMenuTrigger;

    get menuButtonMode(): 'primary' | 'basic' | 'icon' {
        return this.buttonMode ? this.buttonMode : this.label ? 'basic' : 'icon';
    }

    closeMenu() {
        this.trigger.closeMenu();
    }

    openMenu() {
        this.trigger.openMenu();
    }
}
