import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginViewComponent } from './login-view/login-view.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ExternalLoginComponent } from './external-login/external-login.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';

const routes: Routes = [
    {
        path: '',
        component: LoginViewComponent,
        children: [
            { path: '', component: LoginFormComponent },
            { path: 'login', component: LoginFormComponent },
            { path: 'forgot-password', component: ForgotPasswordComponent },
            { path: 'reset-password', component: ResetPasswordComponent },
            { path: 'user-registration', component: UserRegistrationComponent },
            { path: 'welcome/:username', component: ResetPasswordComponent },
            { path: 'user/welcome/:username', redirectTo: 'welcome/:username' }, // change URL in email template and remove this
            { path: 'externalLogin', component: ExternalLoginComponent },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class LoginRoutingModule {}
