import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ValidationFunction } from '../base-field/base-field';
import { InputComponent } from '../input/input.component';

@Component({
    selector: 'vc-password',
    templateUrl: './password.component.html',
    styleUrls: ['./password.component.scss'],
})
export class PasswordComponent {
    type = 'password';
    mask: boolean = true;

    @Input()
    set value(val: string) {
        if (val != this._value) {
            this._value = val;
            this.valueChange.emit(this._value);
        }
    }

    get value(): string {
        return this._value;
    }

    private _value: string = '';

    /** Password label value. */
    @Input()
    label!: string;

    /**Description text underneath  */
    @Input()
    description!: string;

    /** If enabled, field can't be modified. */
    @Input()
    readonly: boolean = false;

    /** Whether password is disabled. */
    @Input()
    disabled: boolean = false;

    /** Whether password is required. */
    @Input()
    required: boolean = false;

    /** Whether password show password visibility trigger button. */
    @Input()
    showVisibleButton: boolean = false;

    /** Password placeholder value. */
    @Input()
    placeholder!: string;

    /** Value that must match the password value. */
    @Input()
    mustMatch!: string;

    /** Value that must not be equal the password value. */
    @Input()
    mustNotMatch!: string;

    /** Prefix icon */
    @Input()
    prefixIcon!: string;

    /** Value for autocomplete attribute on input */
    @Input()
    autocomplete!: string;

    /** Whether to show clear button */
    @Input()
    showClear: boolean = false;

    /** Whether to validate field on value changes. */
    @Input()
    validateOnValueChange: boolean = false;

    /** Minimum allowed characters in password. */
    @Input()
    minLength!: number;

    /** Maximum allowed characters in password. */
    @Input()
    maxLength!: number;

    /** Minimum of number characters in password. */
    @Input()
    minNumbers: number = 0;

    /** Minimum of uppercase characters in password. */
    @Input()
    minUpper: number = 0;

    /** Minimum of lowercase characters in password. */
    @Input()
    minLower: number = 0;

    /** Minimum of special characters in password. */
    @Input()
    minSpecial: number = 0;

    @Input()
    customValidation!: ValidationFunction<string>;

    @Input()
    validationFunction!: ValidationFunction<string>;

    /** Two ways data binding for password value. */
    @Output()
    valueChange = new EventEmitter<string>();

    @ViewChild('inputComponent')
    inputComponent!: InputComponent;

    get suffixButtonIcon(): string {
        return this.mask ? 'visibility' : 'visibility_off';
    }

    toggleVisibility() {
        this.mask = !this.mask;
        this.type = this.mask ? 'password' : 'text';
    }

    validatePassword = (): string => {
        const matchNumbers = /\d/g;
        const matchUpper = /[A-Z]/g;
        const matchLower = /[a-z]/g;
        const matchBlank = /\s/g;

        const chars = this.value?.length;
        const numbers = this.value?.match(matchNumbers)?.length ?? 0;
        const uppers = this.value?.match(matchUpper)?.length ?? 0;
        const lowers = this.value?.match(matchLower)?.length ?? 0;
        const blanks = this.value?.match(matchBlank)?.length ?? 0;
        const specialCharacters = chars - numbers - uppers - lowers - blanks;

        if (this.mustMatch) {
            return this.mustMatch !== this.value
                ? $localize`:@@COMMON_UI.PASSWORD.DOES_NOT_MATCH:Verify password does not match`
                : '';
        }

        if (this.mustNotMatch) {
            return this.mustNotMatch === this.value?.trim()
                ? $localize`:@@COMMON_UI.PASSWORD.DOES_MATCH_CURRENT:Please choose a password that does not match current password`
                : '';
        }

        if (lowers < this.minLower && chars > 0) {
            return (
                $localize`:@@COMMON_UI.PASSWORD.LOWERCASE_REQUIRED:Required minimum lowercase characters` +
                ' - ' +
                this.minLower
            );
        }

        if (uppers < this.minUpper && chars > 0) {
            return (
                $localize`:@@COMMON_UI.PASSWORD.UPPERCASE_REQUIRED:Required minimum uppercase characters` +
                ' - ' +
                this.minUpper
            );
        }

        if (numbers < this.minNumbers && chars > 0) {
            return (
                $localize`:@@COMMON_UI.PASSWORD.NUMBER_REQUIRED:Required minimum number characters` +
                ' - ' +
                this.minNumbers
            );
        }

        if (specialCharacters < this.minSpecial && chars > 0) {
            return (
                $localize`:@@COMMON_UI.PASSWORD.SPECIAL_REQUIRED:Required minimum special characters` +
                ' - ' +
                this.minSpecial
            );
        }

        return '';
    };

    setErrorState(message?: string) {
        this.inputComponent.setErrorState(message);
    }
}
