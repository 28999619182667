export enum ProductEnum {
    ALL = 'ALL',
    CCS = 'CCS',
    MSS = 'MSS',
    MST_MCS = 'MST/MCS',
    PORTAL = 'PORTAL',
    VULNERABILITY_MANAGEMENT = 'Vulnerability Management',
}

export abstract class ProductEnumHelper {
    static getProductIcon(product: ProductEnum): string {
        switch (product) {
            case ProductEnum.ALL:
                return 'notifications';
            case ProductEnum.CCS:
                return 'edit_document';
            case ProductEnum.MSS:
                return 'security';
            case ProductEnum.MST_MCS:
                return 'assignment';
            case ProductEnum.VULNERABILITY_MANAGEMENT:
                return 'network_check';
            case ProductEnum.PORTAL:
                return 'dashboard';
            default:
                return 'notifications';
        }
    }

    static getProductLabel(product: ProductEnum): string {
        switch (product) {
            case ProductEnum.ALL:
                return $localize`:@@CORE.NOTIFICATION_HISTORY.ALL_NOTIFICATIONS:All notifications.`;
            case ProductEnum.CCS:
                return $localize`:@@CORE.NOTIFICATION_HISTORY.CCS:Cloud Compliance`;
            case ProductEnum.MSS:
                return $localize`:@@CORE.NOTIFICATION_HISTORY.MSS:Managed Security`;
            case ProductEnum.MST_MCS:
                return $localize`:@@CORE.NOTIFICATION_HISTORY.ASSESSMENT:Assessment`;
            case ProductEnum.VULNERABILITY_MANAGEMENT:
                return $localize`:@@CORE.NOTIFICATION_HISTORY.VULNERABILITY_SCANNING:Vulnerability Scanning`;
            case ProductEnum.PORTAL:
                return $localize`:@@CORE.NOTIFICATION_HISTORY.PORTAL:Portal`;
            default:
                return '';
        }
    }
}
