<vc-notification></vc-notification>
<a href="javascript:void(0);" (click)="skipToMainContent()" class="sr-only sr-only-focusable">Skip to main content</a>
<div *ngIf="!loading" class="vc-main-layout">
    <div class="vc-header-container">
        <vc-header
            [orgModels]="(orgModels$ | async) ?? []"
            [user]="user"
            [notifications]="(notifications$ | async) ?? []"
            [isOrgAdmin]="isOrgAdmin"
            [isImpersonating]="userFullname"
            [theme]="theme"
            (changeOrg)="selectOrg($event)"
            (changeLang)="changeLang($event)"
            (endImpersonation)="endImpersonation()"
            (changeTheme)="changeTheme($event)"
            (logout)="logout()"></vc-header>
    </div>
    <div class="vc-content-wrapper">
        <div class="vc-navigation-component">
            <vc-navigation-menu [(navigationTree)]="navigationTree"></vc-navigation-menu>
        </div>
        <div #mainContent *ngIf="!forceMFA && !forcePasswordChangeDialog" class="vc-main-wrapper" tabindex="-1">
            <div *ngIf="breadcrumbs && breadcrumbs.length > 0" class="vc-breadcrumb">
                <vc-common-breadcrumb [breadcrumbs]="breadcrumbs"></vc-common-breadcrumb>
            </div>
            <!--TODO: Delete cdkScrollable after upgrade to version 15-16 of material ui-->
            <div
                *ngIf="user"
                cdkScrollable
                class="vc-main-content"
                [class.has-breadcrumbs]="breadcrumbs && breadcrumbs.length > 0">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>

<vc-change-password-dialog
    [username]="user.getUsername()"
    [(visible)]="forcePasswordChangeDialog"
    [forceReset]="true"
    (passwordChange)="updateForcePasswordChange($event)"></vc-change-password-dialog>

<vc-enable-mfa-dialog
    [visible]="showEnableMFA"
    [showEnd]="true"
    [showSecondaryTrigger]="false"
    [showCloseIcon]="false"
    (mfaComplete)="onUpdateMFA()"></vc-enable-mfa-dialog>
