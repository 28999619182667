<div class="vc-notifications">
    <div *ngFor="let notification of notifications" class="vc-notification" role="alert" [ngClass]="notification.type">
        <div *ngIf="notification.title !== ''" class="vc-notification-title">
            <div>{{ notification.title }}</div>
            <button
                mat-icon-button
                class="vc-notification-close-button"
                aria-label="Close the notification"
                (click)="close(notification)">
                <mat-icon>cancel</mat-icon>
            </button>
        </div>
        <div class="vc-notification-wrapper">
            <div class="vc-notification-icon">
                <mat-icon aria-hidden="false" [fontIcon]="notification.type | notificationIcon"></mat-icon>
            </div>
            <div class="vc-notification-message-wrapper">
                <div class="vc-notification-message">{{ notification.message }}</div>
                <button
                    *ngIf="notification.title === ''"
                    mat-icon-button
                    class="vc-notification-close-button"
                    aria-label="Close the notification"
                    (click)="close(notification)">
                    <mat-icon>cancel</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
