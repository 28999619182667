<span #tooltip="matTooltip"
      [style.display]="'block'"
      [matTooltip]="tooltipMessage ? tooltipMessage : text"
      [matTooltipDisabled]="true"
      [matTooltipPosition]="position"
      [attr.aria-label]="tooltipMessage ? tooltipMessage : text"
      [ngClass]="[textClass, 'tooltip-text']"
      (mouseover)="checkIfShowTooltip($event)">
    {{ text }}
</span>
