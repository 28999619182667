import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { IUserModel, Language, LanguageHelper, VcCoreLibModule } from '@libs/vc-core-lib';
import { FormComponent, Phone, PhoneUtil, Validation, VcCommonUiLibModule } from '@libs/vc-common-ui-lib';
import { cloneDeep, isEqual } from 'lodash-es';
import { IOrgModel } from '@libs/vc-org-lib';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
    selector: 'vc-user-details',
    templateUrl: './user-details.component.html',
    styleUrls: ['./user-details.component.scss'],
    standalone: true,
    imports: [VcCommonUiLibModule, VcCoreLibModule, CommonModule, MatTooltipModule],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDetailsComponent {
    private _initialUser!: IUserModel;
    username: string = '';
    password!: string;
    email: string = '';
    orgId: string = '';
    firstName: string = '';
    lastName: string = '';
    language: Language | null = Language.EN;
    notes: string = '';

    primaryPhone!: Phone;
    prevOrg!: IOrgModel | null;
    languages: Language[] = Object.values(Language);

    @Input()
    set user(user: IUserModel) {
        if (user && !isEqual(this._user, user)) {
            this._user = user;
            this._initialUser = cloneDeep(user);
            this._initFields(this._user);
            this.initializeOrg();
        }
    }
    get user(): IUserModel {
        return this._user;
    }
    private _user!: IUserModel;

    @Input()
    showPassword = false;

    @Input()
    passwordRequired = false;

    @Input()
    set orgs(orgs: IOrgModel[]) {
        this._orgs = orgs;
        this.initializeOrg();
    }
    get orgs(): IOrgModel[] {
        return this._orgs;
    }
    private _orgs!: IOrgModel[];

    @Input()
    set org(value: IOrgModel | null) {
        this._org = value;
        if (!this.prevOrg) {
            this.prevOrg = value;
        }
    }

    get org(): IOrgModel | null {
        return this._org;
    }
    private _org!: IOrgModel | null;

    @Input()
    readonly: boolean = false;

    @Input()
    firstNameRequired: boolean = true;

    @Input()
    lastNameRequired: boolean = true;

    @Input()
    readonlyUsername: boolean = true;

    @Input()
    showOrg: boolean = true;

    @Input()
    orgRequired: boolean = true;

    @Input()
    readonlyOrg: boolean = false;

    @Input()
    showNotes: boolean = false;

    @Input()
    showSidePanel: boolean = false;

    @Input()
    showButtons: boolean = true;

    @Input()
    showSecondaryButton: boolean = false;

    @Input()
    showPrimaryButton: boolean = true;

    @Input()
    resetToInitialOnSecondary: boolean = false;

    @Input()
    showLanguageSelector: boolean = true;

    @Output()
    userChange = new EventEmitter<IUserModel>();

    @Output()
    submitForm = new EventEmitter<IUserModel>();

    @Output()
    close = new EventEmitter<void>();

    @Output()
    filterChange = new EventEmitter<string>();

    @ViewChild('userDetailsForm')
    userDetailsForm!: FormComponent;

    initializeOrg() {
        if (this.orgId && this.orgs) {
            this.org = this.orgs.find((org) => org.id === this.orgId) ?? null;
        }
    }

    updateOrg(org: IOrgModel | null) {
        if (this.user) {
            this.user.orgId = org?.id ?? '';
        }
    }

    updatePrimaryPhone(phone: Phone) {
        if (this.user) {
            this.user.primaryPhoneCountryCode = phone.country?.phoneCode ?? '';
            this.user.primaryPhone = phone.number ?? '';
            this.user.primaryPhoneExtension = phone.extension ?? '';
        }
    }

    updateLanguage(lang: Language | null) {
        if (lang && this.user) {
            this.user.locale = lang;
        }
    }

    validateAndSubmit(valid: boolean) {
        if (!valid || !this.user) return;

        this.submitForm.emit(this.user);
    }

    validateEmail = (value: string): string => {
        return value != '' && !Validation.validateEmail(value)
            ? $localize`:@@EMAIL_VALIDATOR.EMAIL_IS_NOT_VALID:Email is not valid`
            : '';
    };

    orgRenderer = (org: IOrgModel) => org?.name ?? '';

    getLanguageLabel(lang: Language): string {
        return LanguageHelper.getLanguageLocale(lang);
    }

    resetDetails() {
        this.close.emit();
        if (this.resetToInitialOnSecondary) {
            this._initFields(this._initialUser);
            this.prevOrg ? (this._org = this.prevOrg) : this.initializeOrg();
        }
    }

    private _initFields(user: IUserModel) {
        this.username = user.userKey?.username ?? '';
        this.email = user.email ?? '';
        this.orgId = user.orgId ?? '';
        this.firstName = user.firstName ?? '';
        this.lastName = user.lastName ?? '';

        this.primaryPhone = PhoneUtil.getPhoneFromNumberAndCode(
            user.primaryPhoneCountryCode,
            user.primaryPhone,
            user.primaryPhoneExtension
        );

        this.notes = user.notes;
        this.language = user.locale;

        if (user.password) {
            this.password = user.password;
        }
    }
}
