export abstract class StringUtil {
    public static join(strs: Array<string>, delimiter: string): string {
        let returnStr: string = '';
        for (let str of strs) {
            if (this.isNotBlank(str)) {
                returnStr += str + delimiter;
            }
        }
        returnStr = returnStr.substring(0, returnStr.length - delimiter.length);
        return returnStr;
    }

    public static isBlank(str: string): boolean {
        return !str || '' === str;
    }

    public static isNotBlank(str: string): boolean {
        return !this.isBlank(str);
    }

    public static isContains(fullString: string, subString: string): boolean {
        if (this.isBlank(fullString) || this.isBlank(subString)) {
            return false;
        }
        return fullString.includes(subString);
    }

    public static toTitle(str: string): string {
        if (this.isBlank(str)) {
            return str;
        }
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    public static toTitleOnlyFirstChar(str: string): string {
        if (this.isBlank(str)) {
            return str;
        }
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1);
        });
    }

    public static format(str: string, ...values: Array<string>): string {
        if (this.isBlank(str) || values.length <= 0) {
            return str;
        }
        values.forEach((value, idx) => {
            str = str.replace(`{${idx}}`, value);
        });
        return str;
    }

    public static isBlankWithTrim(str: string): boolean {
        if (!str || '' === str || '' === str.trim()) {
            return true;
        }
        return false;
    }

    public static isNotBlankWithTrim(str: string): boolean {
        return !this.isBlankWithTrim(str);
    }
}
