<div class="content_box">
    <mat-icon svgIcon="vc-logo" class="vc-login-logo"></mat-icon>

    <div class="content_box__warning">
        <div class="site-blocked">
            <vc-icon name="block" color="var(--error-color)"></vc-icon>

            <div class="title">Site Blocked</div>

            <p class="site-blocked__link-blocked">{{ domain }}</p>

            <div class="site-blocked__description">
                The URL you tried to visit has been blocked by your company policy.
                Please contact your IT support and let them know the page you were trying to view was
                blocked by VikingCloud SecureEdge.
            </div>
        </div>
    </div>
</div>

<div class="vc-tech-graphic">
    <div class="vc-tech-graphic__left"></div>
    <div class="vc-tech-graphic__right"></div>
</div>