import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'vc-disable-mfa-dialog',
    templateUrl: './disable-mfa-dialog.component.html',
    styleUrls: ['./disable-mfa-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisableMfaDialogComponent {
    private _visible = false;

    dialogTitle = $localize`:@@ACCOUNT.DISABLE_MFA:Disable MFA`;
    verificationCode!: string;

    @Input()
    set visible(value: boolean) {
        if (value !== this._visible) {
            this._visible = value;
            this.visibleChange.emit(this._visible);
        }
    }
    get visible(): boolean {
        return this._visible;
    }

    @Output()
    disableMFA = new EventEmitter<string>();

    @Output()
    visibleChange = new EventEmitter<boolean>();

    nextStep() {
        if (this.verificationCode || this.verificationCode !== '') {
            this.disableMFA.emit(this.verificationCode);
            this.visible = false;
        }
        return;
    }
}
