<ng-container *ngIf="!mfaEnabled; else mfaDescription">
    <h1 class="vc-login-main-greeting headline-l">
        {{ greeting }}<br /><span i18n="@@LOGIN.WELCOME_BACK">Welcome back.</span>
    </h1>
    <span id="vc-login-form-description" style="display: none">Login Form</span>
</ng-container>
<ng-template #mfaDescription>
    <div class="vc-login-main-greeting-wrapper">
        <h1 class="vc-login-main-greeting title-m" i18n="@@LOGIN.MFA">Multifactor authentication</h1>
        <div i18n="@@LOGIN.MFA_DESCRIPTION" class="body-m-1">
            Please open your authenticator app and enter the latest generated 6-digit code.
        </div>
    </div>
</ng-template>

<vc-common-form
    class="vc-login-form-common"
    #loginFormComponent
    primaryLabel="{{ submitButtonText }}"
    [ariaDescribedBy]="'vc-login-form-description'"
    [showPrimaryButton]="false"
    [showSecondaryButton]="false">
    <vc-input
        #usernameComponent
        [hidden]="mfaEnabled"
        label="Username"
        i18n-label="@@LOGIN.FORM.USERNAME"
        class="vc-login-form-username"
        [disabled]="mfaEnabled"
        [required]="true"
        [autocomplete]="'username'"
        [(value)]="username"
        [showClear]="true"></vc-input>
    <vc-password
        #userPasswordComponent
        [hidden]="mfaEnabled"
        label="Password"
        i18n-label="@@LOGIN.FORM.PASSWORD"
        class="vc-login-form-password"
        [disabled]="mfaEnabled"
        [required]="true"
        [autocomplete]="'current-password'"
        [(value)]="password"
        [showClear]="true"></vc-password>
    <vc-input
        #mfaInput
        *ngIf="mfaEnabled"
        label="Authentication Code"
        i18n-label="@@LOGIN.FORM.AUTHENTICATION_CODE"
        [required]="true"
        [(value)]="mfaToken"
        [showClear]="true"></vc-input>
    <div buttons class="vc-login-form-buttons">
        <vc-button
            type="submit"
            mode="primary"
            label="{{ submitButtonText }}"
            [loading]="loading"
            [width]="'160px'"
            (trigger)="signIn()"></vc-button>
    </div>
</vc-common-form>

<div class="vc-login-form-links">
    <vc-link
        *ngIf="!mfaEnabled"
        label="Forgot Password?"
        i18n-label="@@LOGIN.FORGOT_PASSWORD"
        [routerLink]="'/forgot-password'"
        [color]="'var(--primary-700)'"
        [target]="'_self'"
        [underlineText]="true"></vc-link>
    <vc-link
        *ngIf="mfaEnabled"
        label="Back to login"
        i18n-label="@@LOGIN.BACK_TO_LOGIN"
        [color]="'var(--primary-700)'"
        [target]="'_self'"
        [underlineText]="true"
        (trigger)="backToLogin()"></vc-link>
    <vc-link
        label="Need help?"
        i18n-label="@@LOGIN.NEED_HELP"
        [underlineText]="true"
        [color]="'var(--primary-700)'"
        [url]="'https://www.vikingcloud.com/company/support/'"
        [showOpenInNewWindow]="true"></vc-link>
</div>
