<label [id]="labelId" class="vc-radiobuttons-group-label">{{ label }}</label>
<mat-radio-group
    class="vc-radiobuttons-group"
    [class.invalid]="!valid"
    [(ngModel)]="value"
    [attr.aria-labelledby]="labelId"
    [class.vc-direction-row]="direction === 'row'"
    [class.vc-direction-column]="direction === 'column'">
    <mat-radio-button
        *ngFor="let radiobutton of radiobuttons"
        [disabled]="radiobutton.disabled"
        [value]="radiobutton.value">
        {{ radiobutton.label }}
    </mat-radio-button>
    <mat-hint *ngIf="!valid" class="mat-error vc-error-message">{{ errorMessage }}</mat-hint>
</mat-radio-group>
