export class TableExportRequest {
    constructor(
        public format: string,
        public fileName: string,
        public dateFormat?: string,
        public data?: string,
        public includeHeaders?: boolean,
        public compression?: boolean,
        public columnNames?: string[],
        public dataFields?: string[],
        public parameters?: { [key: string]: any }
    ) {
        this.columnNames = columnNames ?? [];
        this.dataFields = dataFields ?? [];
        this.parameters = parameters ?? {};
    }
}
