import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'vc-chart-wrapper',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './chart-wrapper.component.html',
    styleUrls: ['./chart-wrapper.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartWrapperComponent {
    /** Title to use for header. Overrides [chartActionsTitle] if set. **/

    @Input()
    title!: string;

    @Input()
    contentHeight!: string;
}
