<vc-dialog
    [(visible)]="visible"
    width="500px"
    [title]="exportHeader"
    [showPrimary]="true"
    [clearFormOnClose]="true"
    [closeOnSecondary]="true"
    [primaryLabel]="exportButtonLabel"
    [primaryDisabled]="disableSave"
    (primaryTrigger)="onExport()"
    (secondaryTrigger)="visible = false">
    <vc-common-form
        #exportForm
        class="vc-table-export-form-wrapper"
        [showButtons]="false">
        <vc-radiogroup class="vc-table-export-formats"
                       label="Format: "
                       direction="row"
                       [(value)]="selectedFormat">
            <ng-template
                vcRadiobutton
                *ngFor="let format of supportedFormats"
                [label]="format"
                [value]="format"></ng-template>
        </vc-radiogroup>
        <vc-list #listComponent
                 checkboxPosition="before"
                 searchKey="header"
                 [showSearch]="true"
                 [multiple]="true"
                 [ariaDescribedby]="!isSelectedColumn ? 'vcTableExportSelectItem' : null"
                 [options]="columns"
                 [itemRenderer]="columnItemRenderer"
                 [(values)]="selectedColumns"
                 (valuesChange)="handleSelectionChange()"></vc-list>
        <mat-hint *ngIf="!isSelectedColumn"
                  id="vcTableExportSelectItem"
                  class="mat-error vc-table-export-column-error-message">Please select at least one column</mat-hint>
    </vc-common-form>
    <vc-toggle dialogActionLeft
               label="Include header row"
               [style.width]="'100%'"
               [(checked)]="includeHeaderRow"></vc-toggle>
</vc-dialog>
