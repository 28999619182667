<div class="vc-table-wrapper" [vcProgressSpinner]="loading">
    <h3 *ngIf="name" class="vc-table-title">{{ name }}</h3>
    <div *ngIf="showToolbar" class="vc-table-toolbar">
        <div class="vc-table-action-buttons" [class.vc-table-actions-left-side-no-grow]="stretchFilter">
            <vc-button
                *ngIf="showDetailsAction"
                iconName="zoom_in"
                tooltip="Show Details"
                mode="icon"
                [disabled]="!selectedRowsExists || disableDetailsAction"
                (trigger)="showDetailsTrigger()"></vc-button>
            <vc-button
                *ngIf="showEditAction"
                iconName="edit"
                tooltip="Edit"
                mode="icon"
                [disabled]="!selectedRowsExists || disableEditAction"
                (trigger)="editActionTrigger()"></vc-button>
            <vc-button
                *ngIf="showDeleteAction"
                iconName="delete"
                tooltip="Delete"
                mode="icon"
                [disabled]="!selectedRowsExists || disableDeleteAction"
                (trigger)="deleteActionTrigger()"></vc-button>
            <ng-content select="[tableAction]"></ng-content>
        </div>
        <div class="vc-table-actions-right-side">
            <vc-input
                *ngIf="showFilter"
                class="vc-table-search"
                prefixIcon="search"
                [placeholder]="filterPlaceholder"
                [label]="filterLabel"
                [showClear]="showFilterClearButton"
                [(value)]="filterValue"
                (valueChange)="applyFilter($event)"></vc-input>
            <ng-content select="[tableFilter]"></ng-content>
            <vc-button
                *ngIf="showAddAction"
                iconName="add"
                tooltip="Add"
                i18n-tooltip="@@COMMON_UI.TABLE.ADD"
                mode="icon"
                [disabled]="disableAddAction"
                (trigger)="add.emit()"></vc-button>
            <vc-button
                *ngIf="showFilterRowAction"
                iconName="filter_list"
                tooltip="Filter"
                mode="icon"
                (trigger)="showFilterRow = !showFilterRow"></vc-button>
            <vc-button
                *ngIf="showReloadAction"
                iconName="replay"
                tooltip="Reload"
                i18n-tooltip="@@COMMON_UI.TABLE.RELOAD"
                mode="icon"
                (trigger)="reloadData()"></vc-button>
            <ng-content select="[tableActionRightSide]"></ng-content>
            <vc-button
                *ngIf="showExportAction"
                iconName="file_download"
                tooltip="Export"
                i18n-tooltip="@@COMMON_UI.TABLE.EXPORT"
                mode="icon"
                (trigger)="showExportDialog()"></vc-button>
            <vc-button
                *ngIf="showColumnSelector"
                iconName="view_columns"
                tooltip="Columns"
                mode="icon"
                (trigger)="showColumnSelectorDialog = true"></vc-button>
            <vc-button
                *ngIf="showClearFilterAction"
                iconName="filter_alt_off"
                mode="icon"
                i18n-tooltip="@@COMMON_UI.TABLE.CLEAR_FILTERS"
                tooltip="Clear filters"
                (trigger)="clearFilters.emit()"></vc-button>
        </div>
    </div>
    <div class="vc-table-container" [style.max-height]="scrollHeight" cdkScrollable>
        <table mat-table
               matSort
               [multiTemplateDataRows]="true"
               [attr.aria-label]="name"
               [attr.aria-describedby]="describedBy"
               [class.vc-table-no-data]="totalItemsDisplay === 0"
               [dataSource]="dataSource"
               [matSortActive]="activeSortField"
               [matSortDirection]="activeSortDirection"
               (matSortChange)="sortingChange($event)">
            <!-- Checkbox Column -->
            <ng-container *ngIf="multiselect" [matColumnDef]="selectColumnID">
                <th mat-header-cell *matHeaderCellDef class="vc-table-multiselect-cell">
                    <mat-checkbox
                        (change)="$event ? toggleAllRows() : null"
                        [checked]="selection.hasValue() && (isAllSelected ?? false)"
                        [indeterminate]="selection.hasValue() && !isAllSelected">
                        <span [hidden]="true">{{ checkboxLabel() }}</span>
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row" class="vc-table-multiselect-cell">
                    <mat-checkbox
                        (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row) ?? false">
                        <span [hidden]="true">{{ checkboxLabel(row) }}</span>
                    </mat-checkbox>
                </td>
            </ng-container>

            <!-- Empty column for table with filtering row and multiselect -->
            <ng-container
                *ngIf="multiselect && (showFilterRowAction || showFilterRow)"
                [matColumnDef]="filterSelectColumnID">
                <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
            </ng-container>

            <ng-container *ngFor="let column of columns; index as i">
                <ng-container [matColumnDef]="column.field">
                    <th mat-header-cell
                        [mat-sort-header]="column.field"
                        vcResizableColumn
                        class="vc-table-column-header"
                        [resizable]="column.resizable && i !== columns.length - 1"
                        [disabled]="!column.sortable || totalItemsDisplay === 0"
                        [width]="column.width"
                        [minWidth]="column.minWidth"
                        (widthChange)="column.width = $event; columnSizeChange.emit(column)"
                        *matHeaderCellDef>
                        <vc-tooltip-text [text]="column.header"></vc-tooltip-text>
                    </th>
                    <td mat-cell
                        class="vc-table-column"
                        [style.width]="column.width"
                        *matCellDef="let data; let dataIndex = dataIndex">
                        <ng-template
                            [ngTemplateOutlet]="column.columnTemplate"
                            [ngTemplateOutletContext]="{ $implicit: data, index: dataIndex }"></ng-template>
                    </td>
                </ng-container>
            </ng-container>

            <!-- Column for filter, shows up if showFilterRow is true -->
            <ng-container *ngFor="let filterColumn of columns">
                <ng-container [matColumnDef]="filterColumn.filterField">
                    <th mat-header-cell class="vc-table-filter-row-column" *matHeaderCellDef>
                        <ng-template
                            [ngTemplateOutlet]="filterColumn.filterTemplate"
                            [ngTemplateOutletContext]="{ $implicit: filterColumn }"></ng-template>
                    </th>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="expandedRowTemplate && showRowExpandButton">
                <ng-container [matColumnDef]="expandColumnID">
                    <th mat-header-cell class="vc-table-expand-header" *matHeaderCellDef aria-label="row actions">
                        &nbsp;
                    </th>
                    <td mat-cell class="vc-table-expand-column" *matCellDef="let rowData">
                        <button
                            mat-icon-button
                            aria-label="expand row"
                            (click)="expandCollapseRow(rowData); $event.stopPropagation()">
                            <mat-icon *ngIf="expandedData !== rowData">keyboard_arrow_down</mat-icon>
                            <mat-icon *ngIf="expandedData === rowData">keyboard_arrow_up</mat-icon>
                        </button>
                    </td>
                </ng-container>
            </ng-container>

            <!-- Expanded Content Column -->
            <ng-container matColumnDef="expandedRow">
                <td mat-cell
                    *matCellDef="let rowData; let dataIndex = dataIndex"
                    [attr.colspan]="visibleColumnsFields.length">
                    <div class="vc-expanded-row-content" [hidden]="rowData !== expandedData">
                        <ng-container
                            [ngTemplateOutlet]="expandedRowTemplate"
                            [ngTemplateOutletContext]="{ $implicit: rowData, index: dataIndex }"></ng-container>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="visibleColumnsFields; sticky: true"></tr>
            <tr [hidden]="!showFilterRow"
                mat-header-row
                *matHeaderRowDef="filterColumns"
                [class.sticky-filter-row]="stickyFilterRow"></tr>
            <tr mat-row
                class="vc-table-row"
                *matRowDef="let row; columns: visibleColumnsFields"
                [tabIndex]="highlightable ? 0 : -1"
                [attr.aria-describedby]="highlightable ? 'vcTableViewDetails' : null"
                [class.vc-selectable-row]="highlightable"
                [class.vc-highlight-row]="row === highlightedRow"
                [class.vc-row-hover]="rowHover"
                [class.vc-truncate-column-text]="truncateColumn"
                (click)="rowTrigger(row)"
                (dblclick)="doubleClick.emit(row)"
                (keydown.enter)="$event.stopPropagation(); rowTrigger(row)"></tr>
            <tr mat-row
                class="vc-table-expanded-row"
                [hidden]="!expandedRowTemplate"
                *matRowDef="let row; columns: ['expandedRow']"></tr>
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell vc-table-no-data" [attr.colspan]="visibleColumnsFields.length">
                    <span *ngIf="!noDataTemplate">No data available</span>
                    <ng-container [ngTemplateOutlet]="noDataTemplate"></ng-container>
                </td>
            </tr>
        </table>
    </div>
    <mat-paginator
        *ngIf="showPagination"
        aria-label="Select page of periodic elements"
        [showFirstLastButtons]="true"
        [length]="totalCount"
        [pageSizeOptions]="pageSizeOptions"
        [pageSize]="pageSize"
        [selectConfig]="{ disableOptionCentering: true }"
        [pageIndex]="pageIndex"
        (page)="pageChange($event)">
    </mat-paginator>
    <div *ngIf="showTotal" class="vc-table-total-items">
        <span *ngIf="showTotal"><span i18n="@@CORE.TOTAL_ITEMS">Total items:</span> {{ totalItemsDisplay ?? 0 }}</span>
        <ng-content select="[total-content]"></ng-content>
    </div>
</div>

<vc-dialog
    [(visible)]="showColumnSelectorDialog"
    title="Columns"
    primaryLabel="Select"
    width="500px"
    [closeOnPrimary]="true"
    (primaryTrigger)="applyColumnsVisible()">
    <vc-common-form class="vc-table-columns-form-wrapper" [showButtons]="false">
        <vc-list
            #listComponent
            checkboxPosition="before"
            searchKey="header"
            [showSearch]="true"
            [multiple]="true"
            [options]="columnsOptions"
            [itemRenderer]="columnItemRenderer"
            [(values)]="visibleColumnList"
            (valuesChange)="changeColumnsVisible($event)"></vc-list>
    </vc-common-form>
</vc-dialog>

<vc-table-export
    *ngIf="showExportAction"
    [(visible)]="exportDialogVisible"
    [columns]="columnsOptions"
    [tableValues]="data"
    [exportServiceUrl]="exportServiceUrl"
    [supportedFormats]="exportAvailableFormats"
    [exportFunction]="exportFunction"
    [selectedFormat]="exportDefaultFormat"
    [exportStrategy]="exportStrategy">
</vc-table-export>
<p id="vcTableViewDetails" [hidden]="true">Click to view details</p>
