/** Angular modules */
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

/** Material modules */
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatTreeModule } from '@angular/material/tree';
import { MatButtonModule } from '@angular/material/button';

import 'hammerjs';

/** Other Dependencies */
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { HighchartsChartModule } from 'highcharts-angular';

/** Core UI components and modules */
import { AppInitService } from '../appInit/app-init.service';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing.component';
import { PostLoginLayoutComponent } from './layout/post-login.layout.component';
import { LoginLayoutComponent } from './layout/login.layout.component';
import { SecureEdgePublicWebsiteComponent } from './component/secure-edge-public-website/secure-edge-public-website.component';

import { NavigationMenuComponent } from './component/navigation-menu/navigation-menu.component';
import { HeaderComponent } from './layout/header/header.component';
import { MatMenuModule } from '@angular/material/menu';
import { CountryImagePipe } from './common/pipes/country/country-image.pipe';
import { CountryLabelPipe } from './common/pipes/country/country-label.pipe';
import { LoginModule } from './component/login/login.module';
import { AccountSettingsModule } from './component/account-settings/account-settings.module';
import { ChangePasswordDialogComponent } from './component/change-password-dialog/change-password-dialog.component';

/** Asgard Library Dependencies */
import { VcOrgLibModule } from '@libs/vc-org-lib';
import { VcReferenceDataLibModule } from '@libs/vc-reference-data-lib';
import { VcCommonUiLibModule } from '@libs/vc-common-ui-lib';
import {
    UserContextService,
    ErrorInterceptor,
    AuthInterceptor,
    AuthGuard,
    VcCoreLibModule,
    ENV_CONFIG,
} from '@libs/vc-core-lib';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NotificationIconPipe } from './common/pipes/notification-icon.pipe/notification-icon.pipe';
import { Observable } from 'rxjs';
import { ChartWrapperComponent, MfaModule, OrgPickerComponent } from '@libs/vc-shared-ui-lib';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FileBrowserModule } from './file-browser/file-browser.module';

export function initializeApp(appInitService: AppInitService) {
    return (): Observable<void> => appInitService.InitTranslations();
}

@NgModule({
    declarations: [
        AppComponent,
        LoginLayoutComponent,
        PostLoginLayoutComponent,
        LandingComponent,
        SecureEdgePublicWebsiteComponent,
        NavigationMenuComponent,
        HeaderComponent,
        CountryImagePipe,
        CountryLabelPipe,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: 'environment', useValue: environment },
        { provide: ENV_CONFIG, useValue: environment },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeApp,
            deps: [AppInitService],
            multi: true,
        },
        UserContextService,
        AuthGuard,
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        VcCommonUiLibModule,
        VcCoreLibModule,
        VcOrgLibModule,
        VcReferenceDataLibModule,
        BrowserAnimationsModule,
        NgxJsonViewerModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatDatepickerModule,
        MatDialogModule,
        MatIconModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatSelectModule,
        MatTreeModule,
        MatTooltipModule,
        HighchartsChartModule,
        LoginModule,
        AccountSettingsModule,
        ChangePasswordDialogComponent,
        NotificationIconPipe,
        StoreModule.forRoot({}),
        EffectsModule.forRoot([]),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
        }),
        MfaModule,
        OrgPickerComponent,
        FileBrowserModule,
        ChartWrapperComponent,
    ],
})
export class AppModule {
    constructor(private _domSanitizer: DomSanitizer, private _matIconRegistry: MatIconRegistry) {
        this._matIconRegistry.addSvgIcon(
            'vc-logo',
            this._domSanitizer.bypassSecurityTrustResourceUrl('assets/images/logo/VC-Asgard-DualLogo-FC.svg')
        );
    }
}
