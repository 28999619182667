import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NotificationService, Notification } from './notification-service';

@Component({
    selector: 'vc-notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
    private _subscription!: Subscription;

    notifications: Notification[] = [];

    constructor(private _notificationSvc: NotificationService) {}

    ngOnInit() {
        this._subscription = this._notificationSvc
            .notify()
            .subscribe((notification: Notification) => this._addNotification(notification));
    }

    close(notification: Notification) {
        this.notifications = this.notifications.filter((ntfc) => ntfc.id !== notification.id);
    }

    private _addNotification(notification: Notification) {
        this.notifications.unshift(notification);

        if (notification.timeout !== 0) {
            setTimeout(() => this.close(notification), notification.timeout);
        }

        if (notification.showAlone) {
            this.notifications = [notification];
        }
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
    }
}
