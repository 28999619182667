<vc-dialog
    [title]="title"
    [primaryLabel]="primaryLabel"
    [(visible)]="visible"
    [loading]="loading"
    [closeOnPrimary]="true"
    [primaryDisabled]="!highlightedOrg"
    (primaryTrigger)="selectOrg()">
    <vc-tree
        style="width: 700px; overflow-y: hidden"
        [(highlighted)]="highlightedOrg"
        [showCheckIconForHighlighted]="!showTags"
        [data]="orgTreeNodes"
        [itemRenderer]="orgItemRenderer"
        [highlightable]="true"
        height="630px">
        <ng-template let-org>
            <div
                *ngIf="showTags"
                class="vc-org-picker-node"
                [style.margin-right]="highlightedOrg === org ? '8px' : '44px'">
                <vc-chip-tags [tags]="org.tags" [showCount]="1" [wrap]="false"></vc-chip-tags>
                <vc-icon
                    *ngIf="highlightedOrg === org"
                    class="vc-highlighted-node-icon"
                    name="done"
                    color="var(--text-low-emphasis)"></vc-icon>
            </div>
        </ng-template>
    </vc-tree>
</vc-dialog>
