import { Pipe, PipeTransform } from '@angular/core';
import { TagPrefix } from '../../enums/tag-prefix';

@Pipe({
    name: 'tagIcon',
})
export class TagIconPipe implements PipeTransform {
    transform(prefix: string): string {
        prefix = prefix.replace(/[/]/g, '');
        switch (prefix) {
            case TagPrefix.EXTERNAL:
                return 'language';
            case TagPrefix.INTERNAL:
                return 'people';
            case TagPrefix.PRIVATE:
                return 'fingerprint';
            default:
                return 'primary';
        }
    }
}
