import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LoaderComponent } from './loader/loader.component';
import { CommonModule } from '@angular/common';
import { VCDatePipe } from './pipe/date.pipe';
import { VCCurrencyPipe } from './pipe/currency.pipe';
import {
    AdHocTaskPurposePipe,
    AssessmentStatusPipe,
    ReferenceTaskTypePipe,
    TaskStatusPipe,
} from './pipe/status.pipe';
import { TextFormatEllipsisPipe } from './pipe/text.pipe';
import { SequenceNumberFormatPipe } from './pipe/sequence-number.pipe';

import '@angular/localize/init';
import { VCPFPLFullNamePipe } from './pipe/pfpl-full-name.pipe';
import { ConvertToPagePipe } from './pipe/convert-to-page.pipe';

@NgModule({
    declarations: [
        LoaderComponent,
        VCDatePipe,
        VCCurrencyPipe,
        VCPFPLFullNamePipe,
        AssessmentStatusPipe,
        TaskStatusPipe,
        AdHocTaskPurposePipe,
        ReferenceTaskTypePipe,
        TextFormatEllipsisPipe,
        SequenceNumberFormatPipe,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ConvertToPagePipe,
    ],
    exports: [
        LoaderComponent,
        VCDatePipe,
        VCCurrencyPipe,
        VCPFPLFullNamePipe,
        AssessmentStatusPipe,
        AdHocTaskPurposePipe,
        ReferenceTaskTypePipe,
        TaskStatusPipe,
        TextFormatEllipsisPipe,
        SequenceNumberFormatPipe,
        ConvertToPagePipe,
    ],
})
export class VcCoreLibModule {}
