export abstract class ColorConstant {
    //   Critical / Fail = Red (#d43f3a)
    public static CRITICAL: string = '#d43f3a';
    // High / Expired = Orange (#ee9336)
    public static HIGH: string = '#ee9336';
    // Medium = Yellow (#fdc431)
    public static MEDIUM: string = '#fdc431';
    // Low / Pass = Green (#3fae49)
    public static LOW: string = '#3fae49';
    // Info / Incomplete = Blue (#0071b9)
    public static INFO: string = '#0071b9';
    // Not started Grey (#777777)
    public static NOT_STARTED: string = '#777777';
    // UNKNOWN Grey (#808080)
    public static UNKNOWN: string = '#808080';

    public static SEVERITY_COLOR_LIST: Array<string> = [
        ColorConstant.CRITICAL,
        ColorConstant.HIGH,
        ColorConstant.MEDIUM,
        ColorConstant.LOW,
        ColorConstant.INFO,
        ColorConstant.NOT_STARTED,
        ColorConstant.UNKNOWN,
    ];

    public static SEVERITY_COLOR_MAP: Map<string, string> = new Map<string, string>()
        .set('CRITICAL', ColorConstant.CRITICAL)
        .set('HIGH', ColorConstant.HIGH)
        .set('MEDIUM', ColorConstant.MEDIUM)
        .set('LOW', ColorConstant.LOW)
        .set('INFO', ColorConstant.INFO)
        .set('NOT_STARTED', ColorConstant.NOT_STARTED)
        .set('UNKNOWN', ColorConstant.UNKNOWN);

    public static BLUE_1: string = '#70c3d5';
    public static BLUE_2: string = '#00a0da';
    public static BLUE_3: string = '#7388bf';
    public static BLUE_4: string = '#70c4ff';
    public static BLUE_5: string = '#0074c9';
    public static BLUE_6: string = '#6495ED';
    public static BLUE_7: string = '#00BFFF';
    public static BLUE_8: string = '#5B92AB';
    public static BLUE_9: string = '#3939AC';
    public static BLUE_10: string = '#87CEFA';

    public static BLUE_COLOR_List: Array<string> = [
        ColorConstant.BLUE_1,
        ColorConstant.BLUE_2,
        ColorConstant.BLUE_3,
        ColorConstant.BLUE_4,
        ColorConstant.BLUE_5,
        ColorConstant.BLUE_6,
        ColorConstant.BLUE_7,
        ColorConstant.BLUE_8,
        ColorConstant.BLUE_9,
        ColorConstant.BLUE_10,
    ];

    public static ASSESSMENT_DASHBOARD_COLOR_LIST: Array<string> = [
        '#F7819F',
        '#81DAF5',
        '#a1b569',
        '#2EFE64',
        '#DF013A',
        '#B404AE',
        '#2E64FE',
        '#FE9A2E',
        '#2ebf85',
        '#D7DF01',
        '#04B4AE',
        '#086A87',
        '#81F781',
        '#c96d6d',
        '#81F7F3',
    ];

    public static TASK_COLORS: Map<string, string> = new Map<string, string>()
        .set('PENDING', ColorConstant.INFO)
        .set('IN_PROGRESS', ColorConstant.MEDIUM)
        .set('SUBMITTED', ColorConstant.HIGH)
        .set('IN_REVIEW', ColorConstant.BLUE_1)
        .set('REJECTED', ColorConstant.CRITICAL)
        .set('COMPLETE', ColorConstant.LOW);
}

export abstract class DateTimeInterval {
    public static HOUR: string = 'HOUR';
    public static DAY: string = 'DAY';
    public static WEEK: string = 'WEEK';
    public static MONTH: string = 'MONTH';
}

export abstract class ProtocolConstant {
    public static PROTOCOL_LIST: Array<string> = ['TCP', 'UDP', 'ICMP'];
}

export abstract class ChartConstant {
    public static TIMELINE_LEGEND_TYPE: Map<string, string> = new Map<string, string>()
        .set('START', 'k-i-checkbox')
        .set('MILESTONE', 'k-i-sharpen')
        .set('MEETING', 'k-i-radiobutton')
        .set('DUE', 'k-i-close');

    public static TIMELINE_MARKER_TYPE: Map<string, string> = new Map<string, string>()
        .set('START', 'square')
        .set('MILESTONE', 'triangle')
        .set('MEETING', 'circle')
        .set('DUE', 'cross');

    public static ASSESSMENT_COUNT: Map<string, string> = new Map<string, string>()
        .set('ending_soon', 'Ending Soon')
        .set('starting_soon', 'Starting Soon')
        .set('total', 'Total');
}

export abstract class SearchConfigConstant {
    public static BASE_SEARCH_CONFIG: any = { hasSearchKey: true, hasSearchUrl: true };
    public static CUSTOM_SEARCH_CONFIG: any = { hasSearchKey: true, hasSearchUrl: false };
}

export abstract class DefaultSearchConstant {
    public static BASE_SEARCH_DTO: string = 'BASE_SEARCH_DTO';
    public static DEFAULT_SEARCH_DTO: string = 'DEFAULT_SEARCH_DTO';
    public static CUSTOM_SEARCH_DTO_WITH_TAGS: string = 'CUSTOM_SEARCH_DTO_WITH_TAGS';

    public static SEARCH_DTOS: Map<string, any> = new Map<string, any>()
        .set(DefaultSearchConstant.BASE_SEARCH_DTO, { orderBy: [{}] })
        .set(DefaultSearchConstant.DEFAULT_SEARCH_DTO, {
            pageRequest: { pageSize: 25, pageNumber: 0 },
            criteria: {},
        })
        .set(DefaultSearchConstant.CUSTOM_SEARCH_DTO_WITH_TAGS, {
            pageRequest: { pageSize: 25, pageNumber: 0 },
            criteria: { tags: [] as Array<string> },
        });

    public static getDTO(type: string): any {
        return JSON.parse(JSON.stringify(DefaultSearchConstant.SEARCH_DTOS.get(type)));
    }
}

export class UserPreferenceConstant {
    //category
    public static readonly CATEGORY_UI: string = 'ui';
    public static readonly CATEGORY_UI_ASSESSMENT: string = 'ui.assessment';
    public static readonly CATEGORY_UI_FINDING: string = 'ui.finding';
    public static readonly CATEGORY_UI_FINDING_ENDPOINT: string = 'ui.finding-endpoint';
    public static readonly CATEGORY_UI_ASSET: string = 'ui.asset';
    public static readonly CATEGORY_UI_EVENT: string = 'ui.event';
    public static readonly CATEGORY_UI_ADMIN: string = 'ui.admin';
    public static readonly CATEGORY_UI_SCAN: string = 'ui.scan';
    public static readonly CATEGORY_UI_ENDPOINT: string = 'ui.endpoint';

    //subCategory
    public static readonly SUBCATEGORY_LAYOUT: string = 'layout';
    public static readonly SUBCATEGORY_QUERIES: string = 'queries';

    //attributeKey
    public static readonly ATTRIBUTEKEY_THEME: string = 'theme';
    public static readonly ATTRIBUTEKEY_ASSESSMENT_SEARCH: string = 'assessment.search';
    public static readonly ATTRIBUTEKEY_TASK_SEARCH: string = 'task.search';
    public static readonly ATTRIBUTEKEY_ASSESSMENT_SEARCH_GRID: string = 'assessment.search.grid';
    public static readonly ATTRIBUTEKEY_ASSESSMENT_SEARCH_COLUMNS: string = 'assessment.search.columns';
    public static readonly ATTRIBUTEKEY_TASK_SEARCH_GRID: string = 'task.search.grid';
    public static readonly ATTRIBUTEKEY_TASK_SEARCH_COLUMNS: string = 'task.search.columns';
    public static readonly TASK_SEARCH_TASKS_FOR_NAVIGATION: string = 'task.search.tasks_for_navigation';
    public static readonly ATTRIBUTEKEY_ASSESSMENT_SEARCH_PAGE_SIZE: string = 'assessment.search.page-size';
    public static readonly ATTRIBUTEKEY_TASK_SEARCH_PAGE_SIZE: string = 'task.search.page-size';
    public static readonly ATTRIBUTEKEY_CONTROL_SEARCH_COLUMNS: string = 'control.search.columns';
    public static readonly ATTRIBUTEKEY_CONTROL_SEARCH_PAGE_SIZE: string = 'control.search.page-size';
    public static readonly ATTRIBUTEKEY_REFTASK_SEARCH_COLUMNS: string = 'reftask.search.columns';
    public static readonly ATTRIBUTEKEY_REFTASK_SEARCH_PAGE_SIZE: string = 'reftask.search.page-size';
    public static readonly ATTRIBUTEKEY_FRAMEWORK_SEARCH_COLUMNS: string = 'framework.search.columns';
    public static readonly ATTRIBUTEKEY_FRAMEWORK_SEARCH_PAGE_SIZE: string = 'framework.search.page-size';
    public static readonly ATTRIBUTEKEY_FINDING_SEARCH_PAGE_SIZE: string = 'finding.search.page-size';
    public static readonly ATTRIBUTEKEY_FINDING_ENDPOINT_SEARCH_PAGE_SIZE: string = 'finding-endpoint.search.page-size';
    public static readonly ATTRIBUTEKEY_ASSET_SEARCH_PAGE_SIZE: string = 'asset.search.page-size';
    public static readonly ATTRIBUTEKEY_EVENT_SEARCH_PAGE_SIZE: string = 'event.search.page-size';
    public static readonly ATTRIBUTEKEY_SCAN_ADMIN_SEARCH_PAGE_SIZE: string = 'scan-admin.search.page-size';
    public static readonly ATTRIBUTEKEY_SCAN_SCANNERS_SEARCH_PAGE_SIZE: string = 'scan-scanners.search.page-size';
    public static readonly ATTRIBUTEKEY_SCAN_DISPUTES_SEARCH_PAGE_SIZE: string = 'scan-disputes.search.page-size';
    public static readonly ATTRIBUTEKEY_SCAN_PROFILE_SEARCH_PAGE_SIZE: string = 'scan-profile.search.page-size';
    public static readonly ATTRIBUTEKEY_SCAN_HISTORY_SEARCH_PAGE_SIZE: string = 'scan-history.search.page-size';
    public static readonly ATTRIBUTEKEY_SCAN_APPLIANCE_SEARCH_PAGE_SIZE: string = 'scan-appliance.search.page-size';
    public static readonly ATTRIBUTEKEY_SCAN_RESULTS_SEARCH_PAGE_REQUEST: string = 'scan-results.search.page-request';
    public static readonly ATTRIBUTEKEY_ORGANIZATION_SEARCH_PAGE_SIZE: string = 'organization.search.page-size';
    public static readonly ATTRIBUTEKEY_USER_SEARCH_PAGE_SIZE: string = 'user.search.page-size';
    public static readonly ATTRIBUTEKEY_ROLE_SEARCH_PAGE_SIZE: string = 'role.search.page-size';
    public static readonly ATTRIBUTEKEY_PRIVILEGE_SEARCH_PAGE_SIZE: string = 'privilege.search.page-size';
    public static readonly ATTRIBUTEKEY_ENDPOINT_DEVICE_SEARCH_PAGE_SIZE: string = 'endpoint-device.search.page-size';
    public static readonly ATTRIBUTEKEY_ENDPOINT_FINDING_SEARCH_PAGE_SIZE: string = 'endpoint-finding.search.page-size';
    public static readonly ATTRIBUTEKEY_ASSESSMENT_INDIVIDUAL_DASHBOARD_WIDGETS: string =
        'assessment.individual-dashboard.widgets';
    public static readonly FRAMEWORKS_SEARCH_FILTERS: string = 'frameworks.search.filters';
    public static readonly EVENTS_SEARCH_FILTERS: string = 'events.search.filters';
    public static readonly FINDINGS_SEARCH_FILTERS: string = 'findings.search.filters';
    public static readonly ENDPOINT_FINDINGS_SEARCH_FILTERS: string = 'endpoint-findings.search.filters';
    public static readonly USERS_SEARCH_FILTERS: string = 'users.search.filters';
    public static readonly USERS_SEARCH_COLUMNS: string = 'users.search.columns';
    public static readonly ORGANIZATION_SEARCH_FILTERS: string = 'organization.search.filters';
    public static readonly ORGANIZATION_SEARCH_COLUMNS: string = 'organization.search.columns';

    public static readonly PERSISTED_ASSESSMENT: string = 'persistedAssessment';
    public static readonly PERSISTED_TASK: string = 'persistedTask';
    public static readonly PERSISTED_PAGE_SIZE: string = 'persistedPageSize';
}

export class CoreConstant {
    public static readonly X_AUTH_TOKEN: string = 'X-Auth-Token';
    public static readonly VC_THEME: string = 'VC_THEME';
    public static readonly VC_LANGUAGE_ABBREVIATION: string = 'VC_LANGUAGE_ABBREVIATION';
}
