import {Component, OnInit, ViewChild, TemplateRef, ViewContainerRef, ViewEncapsulation} from '@angular/core';
import { VCEvent } from '../event/event';
import { IEventListener } from '../event/event.listener';

@Component({
    selector: 'vc-loader',
    template: `
        <ng-template #vcLoaderTemplate>
            <div class="vc-loader-container">
                <div class="loader">VikingCloud</div>
            </div>
        </ng-template>
    `,
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent implements IEventListener, OnInit {
    public static SHOW_LOADER = 'VC_SHOW_LOADER';
    public static REMOVE_LOADER = 'VC_REMOVE_LOADER';
    @ViewChild('vcLoaderTemplate') private vcLoaderTemplate!: TemplateRef<any>;
    private vcLoader: any;
    constructor(private _vcr: ViewContainerRef) {}

    ngOnInit() {
        VCEvent.addListener(this);
    }

    onEvent(name: string, vcEvent: VCEvent): void {
        if (LoaderComponent.SHOW_LOADER == name && this._vcr.length === 0) {
            this.vcLoader = this._vcr.createEmbeddedView(this.vcLoaderTemplate);
        }
        if (LoaderComponent.REMOVE_LOADER == name) {
            setTimeout(() => {
                if (this.vcLoader && this._vcr.indexOf(this.vcLoader) > -1) {
                    this._vcr.remove(this._vcr.indexOf(this.vcLoader));
                }
            }, 300);
        }
    }
}
