import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    Renderer2,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataUnit } from '../../enums/data-unit';

@Component({
    selector: 'vc-file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploaderComponent implements OnDestroy {
    files: File[] = [];
    fileName!: string;
    errorMessage!: string;
    fileHover: boolean = false;

    targetNode!: HTMLElement;
    maskNode!: ElementRef;
    enterUnlistener!: Function;
    overUnlistener!: Function;
    leaveUnlistener!: Function;
    dropUnlistener!: Function;

    /** Whether to allow multiple file uploads.  Defaults to a single file */
    @Input()
    multiple: boolean = false;

    /** Whether to show only browse button */
    @Input()
    showOnlyBrowseButton: boolean = false;

    /** Will apply drag and drop file functionality and UI to the element containing class name specified.  There should only be one element in the DOM with specified class name.
     *  If using inside tab component, make sure this component is wrapped by another element like a div.  Closest parent container cannot be ng-template.
     *
     *  It isn't recommended to have this rendered more than once on a screen as dropzones may interfere each other
     * */
    @Input()
    set dragAndDropTo(value: string) {
        this._dragAndDropTo = value;
        setTimeout(() => this._createDropZone(), 0);
    }
    get dragAndDropTo(): string {
        return this._dragAndDropTo;
    }
    private _dragAndDropTo!: string;

    /** Set maximum file size in bytes.  No limits by default. */
    @Input()
    fileLimit!: number;

    /** Will display maximum file size in bytes (kb) if set.  No limits by default. Recommended for most types is 150 MB (150000000 B) */
    @Input()
    maxFileSize!: number;

    /** Array of extensions (strings) to whitelist the allowed file types such as "image/*" */
    @Input()
    acceptedTypes!: string[];

    /** Define a custom Upload Button label.  Defaults to Select files(s)/Select file */
    @Input()
    selectFilesLabel: string = $localize`:@@COMMON_UI.FILE_UPLOADER.BROWSE_TO_UPLOAD:Browse to upload`;

    /** Custom Upload Button label.  Defaults to Upload files(s)/Upload file */
    @Input()
    set uploadLabel(value: string) {
        this._uploadLabel = value;
    }
    get uploadLabel(): string {
        if (!this._uploadLabel) {
            return this.files.length > 1
                ? $localize`:@@COMMON_UI.FILE_UPLOADER.UPLOAD_FILE:Upload file`
                : $localize`:@@COMMON_UI.FILE_UPLOADER.UPLOAD_FILES:Upload files`;
        }
        return this._uploadLabel;
    }
    private _uploadLabel!: string;

    /** Optionally show/hide upload button. */
    @Input()
    showUploadFilesButton: boolean = false;

    /** Optionally show/hide reset button. */
    @Input()
    showResetButton: boolean = false;

    /** Emitted after files are selected/removed */
    @Output()
    selectionChange = new EventEmitter<File[]>();

    /** Emitted after upload button is clicked */
    @Output()
    uploadTriggered = new EventEmitter<File[]>();

    get acceptedExtensions(): string {
        if (this.acceptedTypes && this.acceptedTypes.length > 0) {
            return this.acceptedTypes.join(', ');
        }
        return '';
    }

    constructor(
        public sanitizer: DomSanitizer,
        private renderer: Renderer2,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    handleFileInput(event: any) {
        this._validateAndAddFiles(event.target.files);
    }

    private _validateAndAddFiles(files: File[]) {
        this.errorMessage = '';

        if (files) {
            for (let file of files) {
                if (this.files.length + 1 > this.fileLimit) {
                    // TODO: add error toast
                    this.errorMessage = $localize`:@@COMMON_UI.FILE_UPLOADER.MAX_FILE_LIMIT:Maximum file limit has been reached`;
                    continue;
                }

                const containsType =
                    !this.acceptedTypes ||
                    this.acceptedTypes.find((type) => type === this.getFileExtension(file)) !== undefined;

                if (this.acceptedTypes && !containsType) {
                    // TODO: add error toast
                    this.errorMessage = $localize`:@@COMMON_UI.FILE_UPLOADER.FILE_TYPE_NOT_ACCEPTED:File type not accepted`;
                    continue;
                }

                if (this.maxFileSize && file.size > this.maxFileSize * 1000) {
                    // TODO: add error toast
                    this.errorMessage = $localize`:@@COMMON_UI.FILE_UPLOADER.MAX_FILE_SIZE_EXCEEDED_FOR:Maximum file size exceeded for ${file.name}`;
                    continue;
                }

                if (!this.multiple) {
                    this.files = [file];
                    continue;
                }

                this.files = [...this.files, file];
            }
        }

        this.changeDetectorRef.detectChanges();
        this.selectionChange.emit(this.files);
    }

    private _createDropZone(): void {
        if (!this._dragAndDropTo || this.maskNode) return;

        // Create div to be inserted
        this.maskNode = this.renderer.createElement('div');
        this.renderer.addClass(this.maskNode, 'vc-file-uploader-drop-zone');
        this.renderer.setAttribute(this.maskNode, 'draggable', 'true');

        // Find parent container
        this.targetNode = document.getElementsByClassName(this.dragAndDropTo).item(0) as HTMLElement;

        if (!this.targetNode) {
            console.error(`Cannot find dragAndDropTo element with class ${this.dragAndDropTo}`);
            return;
        }

        // Append generated div to dragAndDropTo element
        this.renderer.appendChild(this.targetNode, this.maskNode);

        // Add listeners for DOM dragging events
        this.enterUnlistener = this.renderer.listen(this.targetNode, 'dragenter', (e) => this.onDragEnter(e));
        this.overUnlistener = this.renderer.listen(this.maskNode, 'dragover', (e) => {
            e.preventDefault();
            e.stopPropagation();
        });
        this.dropUnlistener = this.renderer.listen(this.maskNode, 'drop', (e) => this.onDrop(e));
        this.leaveUnlistener = this.renderer.listen(this.maskNode, 'dragleave', (e) => this.onDragLeave(e));
    }

    onDragEnter(e: DragEvent) {
        e.stopPropagation();
        e.preventDefault();
        this.fileHover = true;

        if (this.maskNode) {
            this.renderer.addClass(this.maskNode, 'vc-file-uploader-drop-zone-mask');
        }
    }

    onDragLeave(e: DragEvent) {
        e.stopPropagation();
        e.preventDefault();
        this.fileHover = false;

        if (this.maskNode) {
            this.renderer.removeClass(this.maskNode, 'vc-file-uploader-drop-zone-mask');
        }
    }

    onDrop(e: DragEvent) {
        e.stopPropagation();
        e.preventDefault();
        this.fileHover = false;

        if (this.maskNode) {
            this.renderer.removeClass(this.maskNode, 'vc-file-uploader-drop-zone-mask');
        }

        if (!e.dataTransfer) return;
        const files: File[] = [];

        if (e.dataTransfer.items) {
            // Use DataTransferItemList interface to access the file(s)
            // @ts-ignore
            for (let item of e.dataTransfer.items) {
                if (item.kind === 'file') {
                    files.push(item.getAsFile() as File);
                }
            }
        } else {
            // Use DataTransfer interface to access the file(s)
            // Supported by older browsers
            // @ts-ignore
            for (let file of e.dataTransfer.files) {
                if (file) {
                    files.push(file as File);
                }
            }
        }

        this._validateAndAddFiles(files);
    }

    onUploadFiles() {
        this.uploadTriggered.emit(this.files);
    }

    deleteFileAtIndex(index: number) {
        this.fileName = '';
        this.files.splice(index, 1);
        this.selectionChange.emit(this.files);
    }

    reset() {
        this.files = [];
        this.fileName = '';
        this.errorMessage = '';
        this.selectionChange.emit(this.files);
    }

    getFormatSize(size: number) {
        if (size == 0) {
            return '0 B';
        }
        let k = 1000,
            dm = 3,
            sizes = Object.values(DataUnit),
            i = Math.floor(Math.log(size) / Math.log(k));

        return parseFloat((size / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    getFileExtension(file: File): string {
        return `.${file.name.toLowerCase().split('.').pop()}`;
    }

    getThumbnail(file: File) {
        const url = URL.createObjectURL(file);
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    isImage(file: File): boolean {
        return /^image\//.test(file.type);
    }

    ngOnDestroy() {
        if (this.targetNode && this.maskNode) {
            this.renderer.removeChild(this.targetNode, this.maskNode);
        }

        this.enterUnlistener && this.enterUnlistener();
        this.leaveUnlistener && this.leaveUnlistener();
        this.dropUnlistener && this.dropUnlistener();
        this.overUnlistener && this.overUnlistener();
    }
}
