<mat-tab-group
    dynamicHeight
    [style.height]="'100%'"
    [animationDuration]="animationDuration"
    [(selectedIndex)]="selectedIndex"
    (selectedIndexChange)="changeIndex($event)">
    <ng-container *ngFor="let tab of tabs; let index = index">
        <mat-tab
            [label]="tab.label"
            [disabled]="tab.disabled"
            [attr.aria-label]="tab.ariaLabel"
            [attr.aria-labelledby]="tab.ariaLabelledby">
            <ng-template mat-tab-label>
                <mat-icon *ngIf="tab.icon">{{ tab.icon }}</mat-icon>
                <a
                    *ngIf="tab.link; else label"
                    tabindex="-1"
                    class="vc-tabs-link-header"
                    matBadge="{{ tab.badge }}"
                    matBadgeOverlap="false"
                    matBadgeSize="medium"
                    [matBadgeHidden]="tab.badge === 0"
                    >{{ tab.label }}</a
                >
                <ng-template #label>
                    <span matBadge="{{ tab.badge }}" matBadgeOverlap="false" matBadgeSize="small">
                        {{ tab.label }}
                    </span>
                </ng-template>
            </ng-template>
            <ng-template
                *ngIf="visitedIndexes.has(index) || index === selectedIndex"
                [ngTemplateOutlet]="tab.tabTemplate"></ng-template>
        </mat-tab>
    </ng-container>
</mat-tab-group>
