export enum Theme {
    light = 'light',
    dark = 'dark',
}

export class ThemeHelper {
    static getThemeLocale(theme: Theme) {
        switch (theme) {
            case Theme.light:
                return $localize`:@@THEME.LIGHT:Light`;
            case Theme.dark:
                return $localize`:@@THEME.DARK:Dark`;
            default:
                return '';
        }
    }
}
