export class PageDTO<T> {
    pageSize: number = 25;
    pageNumber: number = 0;
    items: T[];
    totalCount: number = 0;
    totalPages?: number = 1;
    skip: number = 0;

    constructor(pageNumber: number, pageSize: number, items: T[], totalCount: number) {
        this.pageNumber = pageNumber;
        this.pageSize = pageSize;
        this.items = items;
        this.totalCount = totalCount;
        this.skip = pageNumber * pageSize;
    }
}
