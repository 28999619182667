import {
    AfterViewInit,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChild,
} from '@angular/core';
import { StepDirective } from '../step.directive';
import { CdkStep, CdkStepper, StepperSelectionEvent, StepState } from '@angular/cdk/stepper';

@Component({
    selector: 'vc-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements AfterViewInit {
    visitedIndexes: Set<number> = new Set([0]);

    /** The index of the selected step. */
    @Input()
    selectedIndex: number = 0;

    /** Whether the validity of previous steps should be checked or not. */
    @Input()
    linear: boolean = true;

    /** 'next' button label. */
    @Input()
    nextLabel: string = 'Next';

    /** 'back' button label. */
    @Input()
    backLabel: string = 'Back';

    /** 'reset' button label. */
    @Input()
    resetLabel: string = 'Reset';

    /** Stepper label position */
    @Input()
    labelPosition: 'bottom' | 'end' = 'bottom';

    /** Whether to show 'next' button. */
    @Input()
    showNext: boolean = true;

    /** Whether to show 'back' button. */
    @Input()
    showBack: boolean = true;

    /** Whether to show 'reset' button. */
    @Input()
    showReset: boolean = true;

    /** Whether to disable 'next' button. */
    @Input()
    disableNext: boolean = false;

    /** Whether to disable 'back' button. */
    @Input()
    disableBack: boolean = false;

    /** Whether to show 'disable' button. */
    @Input()
    disableReset: boolean = false;

    /** Whether to show the checkmark icon for completed tasks.  If false, will show step number instead */
    @Input()
    showCheckmarkForCompleted: boolean = false;

    /** Whether to show the checkmark icon for completed tasks.  If false, will show step number instead */
    @Input()
    showPencilForEditable: boolean = false;

    /** Event emitted when the next button click. */
    @Output()
    next = new EventEmitter<number>();

    /** Event emitted when the back button click. */
    @Output()
    back = new EventEmitter<number>();

    /** Event emitted when the reset button click. */
    @Output()
    resetAllSteps = new EventEmitter();

    /** Event emitted when the selected step has changed. */
    @Output()
    selectedIndexChange = new EventEmitter<number>();

    @ViewChild('stepper')
    stepper!: CdkStepper;

    @ContentChildren(StepDirective)
    set steps(value: QueryList<StepDirective>) {
        this._steps = value;
    }

    get steps(): QueryList<StepDirective> {
        return this._steps;
    }

    get nextVisible(): boolean {
        return this.showNext && this.stepper?.selectedIndex < this.steps?.length - 1;
    }

    get backVisible(): boolean {
        return this.showBack && this.stepper?.selectedIndex !== 0;
    }

    get nextDisabled(): boolean {
        return this.disableNext || (!this.steps.get(this.selectedIndex)?.completed ?? false);
    }

    get resetVisible(): boolean {
        return this.showReset && this.stepper?.selectedIndex === this.steps?.length - 1;
    }

    get selectedStepIndex(): number {
        return this.stepper?.selectedIndex ?? 0;
    }

    private _steps!: QueryList<StepDirective>;

    ngAfterViewInit() {
        this.stepper._getIndicatorType = (index: number, state?: StepState) => {
            const step: CdkStep | undefined = this.stepper.steps.get(index);
            if (step?.hasError) return 'error';
            if (step?.completed && this.showCheckmarkForCompleted) return 'done';
            if (step?.editable && this.showPencilForEditable) return 'edit';

            return 'number';
        };
    }

    nextTrigger() {
        this.next.emit(this.stepper?.selectedIndex);
        setTimeout(() => {
            this.stepper.next();
        }, 100);
    }

    backTrigger() {
        this.back.emit(this.stepper?.selectedIndex);
        setTimeout(() => {
            this.stepper.previous();
        }, 100);
    }

    resetTrigger() {
        this.stepper.selectedIndex = 0;
        this.resetAllSteps.emit();
    }

    selectedIndexChanged(event: StepperSelectionEvent) {
        this.selectedIndexChange.emit(event.selectedIndex);
        this.visitedIndexes.add(event.selectedIndex);
    }

    goNext() {
        this.stepper.next();
    }

    goBack() {
        this.stepper.previous();
    }

    resetStepper() {
        this.stepper.reset();
    }

    moveToStep(index: number) {
        setTimeout(() => {
            this.stepper.selectedIndex = index;
        }, 300);
    }
}
