import { Component, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { Subject, finalize, takeUntil } from 'rxjs';
import { FileUploaderComponent, NotificationService } from '@libs/vc-common-ui-lib';
import { FileLockerService, IFileModel } from '@libs/vc-file-locker-lib';
import { IFolderTreeModel } from '../../common/models/folder-tree.model';
import { FileOrFolder, FileRegion, ITagModel, TagUtil } from '@libs/vc-core-lib';
import { isEmpty } from 'lodash-es';
import { ReferenceTagService } from '@libs/vc-reference-data-lib';
import { TagsSelectorComponent } from '@libs/vc-shared-ui-lib';

@Component({
    selector: 'vc-file-browser-form-dialog',
    templateUrl: './file-browser-form-dialog.component.html',
    styleUrls: ['./file-browser-form-dialog.component.scss'],
    providers: [FileLockerService, ReferenceTagService],
})
export class FileBrowserFormDialogComponent implements OnDestroy {
    @ViewChild('tagsSelector') tagsSelector!: TagsSelectorComponent;
    @ViewChild('fileUploaderComponent') fileUploaderComponent!: FileUploaderComponent;
    @Input() isNew: boolean = true;
    @Input() orgId!: string;
    @Input() folder!: IFolderTreeModel;
    @Input() file: IFileModel = {} as IFileModel;

    @Input()
    set visible(value: boolean) {
        if (this._visible != value) {
            this._visible = value;
            this.visibleChange.emit(this._visible);
        }
        this.preOpenDialog();
    }
    get visible(): boolean {
        return this._visible;
    }

    @Output() visibleChange = new EventEmitter<boolean>();
    @Output() public onSaveSuccess = new EventEmitter();

    private _visible: boolean = false;
    loading: boolean = false;
    uploadFile: File | null = null;
    location!: string;
    title: string = '';
    tags: ITagModel[] = [];

    private _destroy$: Subject<any> = new Subject<any>();

    constructor(
        private _fileLockerService: FileLockerService,
        private _referenceTagService: ReferenceTagService,
        private _notificationService: NotificationService
    ) {}

    preOpenDialog() {
        this.tags = [];
        if (this.isNew) {
            this.title = $localize`:@@FILE_BROWSER.LABEL.ADD_FILE:Add file`;
            this.location = $localize`:@@FILE_BROWSER.LABEL.LOCATION:Location: ` + this.folder?.path;
            this.file = {} as IFileModel;
            this.file.orgId = this.orgId;
            this.file.path = this.folder?.path;
            this.file.region = FileRegion.GENERIC;
            this.file.fileOrFolder = FileOrFolder.FILE;
        } else {
            this.title = $localize`:@@FILE_BROWSER.LABEL.UPDATE_FILE:Update file`;
            this.location = $localize`:@@FILE_BROWSER.LABEL.LOCATION:Location: ` + this.file?.path;
            this.tags = TagUtil.convertArrayStringToTags(this.file.tags);
        }
        this.uploadFile = null;
        this.fileUploaderComponent?.reset();
    }

    uploadFiles(files: File[]): void {
        if (this.isNew) {
            if (files?.length > 0) {
                this.uploadFile = files[0];
                if (isEmpty(this.file.name)) {
                    this.file.name = this.uploadFile.name;
                }
            } else {
                this.uploadFile = null;
                this.file.name = '';
            }
        }
    }

    public save(valid: boolean): void {
        if (this.isNew) {
            if (this.uploadFile) {
                this.createFile(this.uploadFile);
            } else {
                this._notificationService.error(
                    '',
                    $localize`:@@FILE_BROWSER.FORM.ADD_FILE.SELECT_FILE:Please select a file.`
                );
            }
        } else {
            this.updateFile();
        }
    }

    private createFile(uploadFile: File): void {
        this.loading = true;
        this.file.tags = TagUtil.getTagsAsStrings(this.tags);
        this._fileLockerService
            .createFile(this.file, uploadFile)
            .pipe(takeUntil(this._destroy$))
            .subscribe({
                next: (file: IFileModel) => {
                    this.upsertTags();
                    this.onSaveSuccess.emit(file);
                    this.visible = false;
                },
                error: () => {
                    this.loading = false;
                    this._notificationService.error(
                        '',
                        $localize`:@@FILE_BROWSER.FORM.ADD_FILE.ERROR:Error while adding file details`
                    );
                },
            });
    }
    private updateFile(): void {
        this.loading = true;
        this.file.tags = TagUtil.getTagsAsStrings(this.tags);
        this._fileLockerService
            .updateFile(this.file.id, this.file, null)
            .pipe(takeUntil(this._destroy$))
            .subscribe({
                next: (file: IFileModel) => {
                    this.upsertTags();
                    this.onSaveSuccess.emit(file);
                    this.visible = false;
                },
                error: () => {
                    this.loading = false;
                    this._notificationService.error(
                        '',
                        $localize`:@@FILE_BROWSER.FORM.UPDATE_FILE.ERROR:Error while updating file details`
                    );
                },
            });
    }
    private upsertTags(): void {
        const tags = TagUtil.manageUpsertTagModels(this.tags);
        if (!isEmpty(tags)) {
            this._referenceTagService
                .upsertTags(tags, this.orgId)
                .pipe(
                    takeUntil(this._destroy$),
                    finalize(() => (this.loading = false))
                )
                .subscribe({
                    next: () => {
                        this.tagsSelector.refreshTags();
                    },
                    error: () => {
                        this._notificationService.error(
                            '',
                            $localize`:@@FILE_BROWSER.SAVE_TAGS.ERROR:Error while saving tags.`
                        );
                    },
                });
        } else {
            this.loading = false;
        }
    }

    ngOnDestroy(): void {
        this._destroy$.next(null);
        this._destroy$.complete();
    }
}
