import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '../../base/base.service';
import { INotificationPreferenceModel } from '../../model/user/notification-preference.model';
import { NotificationCriteria } from '../../model/user/notification-criteria.model';
import { Notification } from '../../model/user/notification.model';

@Injectable()
export class NotificationService extends BaseService<any> {
    constructor(protected injector: Injector) {
        super(injector, 'preference');
    }

    public saveNotificationPreference(
        notificationPreference: INotificationPreferenceModel
    ): Observable<INotificationPreferenceModel> {
        return this.httpClient.post<INotificationPreferenceModel>(
            `${this.environment.APP.VC_NOTIFICATION_BASE_URL}/${this.serviceBaseUrl}`,
            notificationPreference
        );
    }

    public updateNotificationPreference(
        notificationPreference: INotificationPreferenceModel
    ): Observable<INotificationPreferenceModel> {
        return this.httpClient.put<INotificationPreferenceModel>(
            `${this.environment.APP.VC_NOTIFICATION_BASE_URL}/${this.serviceBaseUrl}`,
            notificationPreference
        );
    }

    public getNotificationPreference(
        username: string,
        authenticator: string
    ): Observable<INotificationPreferenceModel> {
        return this.httpClient.get<INotificationPreferenceModel>(
            `${this.environment.APP.VC_NOTIFICATION_BASE_URL}/${this.serviceBaseUrl}/${encodeURIComponent(
                username
            )}/${encodeURIComponent(authenticator)}`
        );
    }

    public getNotifications(criteria: NotificationCriteria): Observable<Notification[]> {
        const url = `${this.environment.APP.VC_NOTIFICATION_BASE_URL}/notifications/search`;
        return this.httpClient.post<Notification[]>(url, criteria);
    }
}
