<div class="vc-login-view-content-container">
    <div class="vc-login-view-content">
        <mat-icon svgIcon="vc-logo" class="vc-login-logo"></mat-icon>

        <router-outlet></router-outlet>

        <div class="vc-login-form-copyright body-s">Copyright {{ currentYear }} © VikingCloud</div>
    </div>
</div>
<vc-notification></vc-notification>
