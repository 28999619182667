<h4 *ngIf="header">{{ header }}</h4>
<div class="vc-pick-list-source-panel">
    <div class="vc-pick-list-header">
        <mat-label>{{ sourceLabel }}</mat-label>
        <vc-input
            *ngIf="showSourceSearch"
            [label]="sourceSearchLabel"
            [placeholder]="sourceSearchPlaceholder"
            [showClear]="true"
            [prefixIcon]="'search'"
            [(value)]="sourceSearch"></vc-input>
    </div>
    <mat-selection-list
        #sourceList
        cdkDropList
        class="vc-pick-list-source"
        [multiple]="false"
        [tabIndex]="0"
        #todoList="cdkDropList"
        [disableRipple]="true"
        [cdkDropListData]="source"
        [cdkDropListConnectedTo]="[doneList]"
        [attr.aria-label]="sourceAriaLabel"
        (selectionChange)="onSourceChange($event)"
        (cdkDropListDropped)="drop($event)">
        <mat-list-option
            cdkDrag
            class="vc-pick-list-option"
            *ngFor="let item of source | search : sourceSearchKey : sourceSearch; index as i; trackBy: trackBy"
            [value]="item">
            <ng-container *ngTemplateOutlet="sourceItemTemplate; context: { $implicit: item, index: i }"></ng-container>
        </mat-list-option>
    </mat-selection-list>
</div>
<div class="vc-pick-list-buttons-panel">
    <button
        mat-stroked-button
        color="primary"
        aria-label="Move to right"
        type="button"
        [disabled]="moveToTargetButtonDisabled"
        (click)="onMoveToTarget()">
        <mat-icon fontIcon="chevron_right"></mat-icon>
    </button>
    <button
        *ngIf="showMoveAllToTarget"
        mat-stroked-button
        color="primary"
        aria-label="Move all to right"
        type="button"
        [disabled]="moveAllToTargetButtonDisabled"
        (click)="onMoveAllToTarget()">
        <mat-icon fontIcon="keyboard_double_arrow_right"></mat-icon>
    </button>
    <button
        mat-stroked-button
        color="primary"
        aria-label="Move to left"
        type="button"
        [disabled]="moveToSourceButtonDisabled"
        (click)="onMoveToSource()">
        <mat-icon fontIcon="chevron_left"></mat-icon>
    </button>
    <button
        *ngIf="showMoveAllToSource"
        mat-stroked-button
        color="primary"
        aria-label="Move all to left"
        type="button"
        [disabled]="moveAllToSourceButtonDisabled"
        (click)="onMoveAllToSource()">
        <mat-icon fontIcon="keyboard_double_arrow_left"></mat-icon>
    </button>
</div>

<div class="vc-pick-list-target-panel">
    <div class="vc-pick-list-header">
        <mat-label>{{ targetLabel }}</mat-label>
        <vc-input
            *ngIf="showTargetSearch"
            [placeholder]="targetSearchPlaceholder"
            [label]="targetSearchLabel"
            [showClear]="true"
            [prefixIcon]="'search'"
            [(value)]="targetSearch"></vc-input>
    </div>
    <mat-selection-list
        cdkDropList
        class="vc-pick-list-target"
        [multiple]="false"
        [tabIndex]="0"
        #doneList="cdkDropList"
        #targetList
        [disableRipple]="true"
        [cdkDropListData]="target"
        [cdkDropListConnectedTo]="[todoList]"
        [attr.aria-label]="targetAriaLabel"
        (selectionChange)="onTargetChange($event)"
        (cdkDropListDropped)="drop($event)">
        <mat-list-option
            cdkDrag
            class="vc-pick-list-option"
            *ngFor="let item of target | search : targetSearchKey : targetSearch; index as i; trackBy: trackBy"
            [value]="item">
            <ng-container *ngTemplateOutlet="targetItemTemplate; context: { $implicit: item, index: i }"></ng-container>
        </mat-list-option>
    </mat-selection-list>
</div>
