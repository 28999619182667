export enum TaskStatus {
    PENDING = 'PENDING',
    IN_PROGRESS = 'IN_PROGRESS',
    SUBMITTED = 'SUBMITTED',
    IN_REVIEW = 'IN_REVIEW',
    REJECTED = 'REJECTED',
    COMPLETE = 'COMPLETE',
}

export class TaskStatusHelper {
    static getTaskStatusString(status: TaskStatus): string {
        switch (status) {
            case TaskStatus.PENDING:
                return $localize`:@@CORE.TASK_STATUSES.PENDING:Pending`;
            case TaskStatus.IN_PROGRESS:
                return $localize`:@@CORE.TASK_STATUSES.IN_PROGRESS:In Progress`;
            case TaskStatus.SUBMITTED:
                return $localize`:@@CORE.TASK_STATUSES.SUBMITTED:Submitted`;
            case TaskStatus.IN_REVIEW:
                return $localize`:@@CORE.TASK_STATUSES.IN_REVIEW:In Review`;
            case TaskStatus.REJECTED:
                return $localize`:@@CORE.TASK_STATUSES.REJECTED:Rejected`;
            case TaskStatus.COMPLETE:
                return $localize`:@@CORE.TASK_STATUSES.COMPLETE:Completed`;
            default:
                return '';
        }
    }
}
