import { Injectable, Injector } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { Options } from '../../models/api/api-options';
import { TableExportRequest } from '../../models/exports/table-export';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root',
})
export class TableExportService {
    protected httpClient!: HttpClient;

    constructor(protected injector: Injector) {
        this.httpClient = injector.get(HttpClient);
    }

    createAndRetrieveFile(request: TableExportRequest, url: string) {
        const fileName = `${request.fileName}.${request.format}`;
        return this.createFile(request, url).pipe(
            map((res: any) => {
                // console.log('response', res);
                const blob = new Blob([res], { type: 'application/octet-stream' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.download = fileName ?? '';
                link.click();
                link.remove();
            }),
            take(1)
        );
    }

    private createFile(request: TableExportRequest, url: string) {
        const body = { gridExportRequest: request, params: request.parameters };
        const options: Options = { responseType: 'blob' };
        request.parameters = undefined;
        return this.httpClient.post(url, body, options);
    }
}
