<mat-icon
    aria-hidden="false"
    [style.color]="color"
    [style.fontSize]="size"
    [style.width]="size"
    [style.height]="size"
    [fontIcon]="name"
    [attr.aria-label]="ariaLabel"
    [matTooltip]="tooltip"
    [matTooltipPosition]="tooltipPosition">
</mat-icon>
