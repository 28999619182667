<div class="vc-accordion-header">
    <div
        class="vc-accordion-header-wrapper"
        [class.vc-accordion-header-expanded]="expanded"
        (click)="$event.stopPropagation(); toggleExpand()">
        <div class="vc-accordion-title-and-content" (click)="$event.stopPropagation(); toggleExpand()">
            <ng-content select="[headerContentBefore]"></ng-content>
            <div matBadge="{{ badge }}" matBadgeOverlap="false" matBadgeSize="small">{{ title }}</div>
            <ng-content select="[headerContentAfter]"></ng-content>
        </div>
        <button
            mat-icon-button
            color="primary"
            class="vc-accordion-expand-collapse-button"
            type="button"
            [id]="buttonId"
            [attr.aria-expanded]="expanded"
            [attr.aria-controls]="controlId"
            [attr.aria-label]="title"
            (click)="$event.stopPropagation(); toggleExpand()">
            <mat-icon>{{ expanded ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
        </button>
    </div>
</div>
<div class="vc-accordion-panel" role="region" [hidden]="!expanded" [id]="controlId" [attr.aria-labelledby]="buttonId">
    <ng-content></ng-content>
</div>
