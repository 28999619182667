<mat-stepper
    #stepper
    [linear]="linear"
    [selectedIndex]="selectedIndex"
    [labelPosition]="labelPosition"
    (selectionChange)="selectedIndexChanged($event)">
    <mat-step
        *ngFor="let step of steps; let index = index"
        [editable]="step.editable"
        [completed]="step.completed"
        [hasError]="step.hasError"
        [errorMessage]="step.errorMessage"
        [optional]="step.optional"
        [label]="step.label">
        <ng-template *ngIf="step.iconClass !== ''" matStepperIcon="edit">
            <mat-icon>{{ step.iconClass }}</mat-icon>
        </ng-template>
        <div class="vc-stepper-main-content">
            <ng-template
                *ngIf="visitedIndexes.has(index) || index === selectedIndex"
                [ngTemplateOutlet]="step.stepTemplate"></ng-template>
            <div class="vc-stepper-action-buttons">
                <vc-button
                    *ngIf="backVisible"
                    mode="basic"
                    [label]="backLabel"
                    [disabled]="disableBack"
                    (trigger)="backTrigger()"></vc-button>
                <vc-button
                    *ngIf="nextVisible"
                    mode="primary"
                    [label]="nextLabel"
                    [disabled]="nextDisabled"
                    (trigger)="nextTrigger()"></vc-button>
                <vc-button
                    *ngIf="resetVisible"
                    mode="basic"
                    [label]="resetLabel"
                    [disabled]="disableReset"
                    (trigger)="resetTrigger()"></vc-button>
                <ng-content select="[stepperAction]"></ng-content>
            </div>
        </div>
    </mat-step>
</mat-stepper>
