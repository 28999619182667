import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ColumnDirective } from '../column/column.directive';
import { FormComponent } from '../../form/form.component';
import { TableExportService } from '../../../services/table-export-service/table-export.service';
import { TableExportRequest } from '../../../models/exports/table-export';
import { ExportStrategy } from '../../../models/exports/export-strategy';
import { take } from 'rxjs/operators';
import { ListComponent } from '../../list/list.component';

@Component({
    selector: 'vc-table-export',
    templateUrl: './table-export.component.html',
    styleUrls: ['./table-export.component.scss'],
})
export class TableExportComponent implements OnInit {
    selectedColumns: ColumnDirective[] = [];
    includeHeaderRow: boolean = true;
    isSelectedColumn: boolean = true;
    fileName!: string;
    searchValue!: string;

    get exportButtonLabel() {
        return 'Export ' + this.selectedFormat;
    }

    @Input()
    set visible(value: boolean) {
        if (this._visible != value) {
            this._visible = value;
            this._visible && this._initFields();
            this.visibleChange.emit(value);
        }
    }

    get visible(): boolean {
        return this._visible;
    }

    private _visible: boolean = false;

    @Input()
    exportHeader: string = 'Export';

    @Input()
    exportServiceUrl: string = '';

    @Input()
    set columns(value: ColumnDirective[]) {
        this._columns = value;
        this.selectedColumns = this._columns.filter((column: ColumnDirective) => column.visible);
    }

    get columns(): ColumnDirective[] {
        return this._columns;
    }

    private _columns: ColumnDirective[] = [];

    @Input()
    set supportedFormats(formats: ExportFormat[]) {
        this._supportedFormats = formats ?? Object.values(ExportFormat);
    }

    get supportedFormats(): ExportFormat[] {
        return this._supportedFormats;
    }

    private _supportedFormats!: ExportFormat[];

    @Input()
    selectedFormat!: ExportFormat | null;

    @Input()
    set exportStrategy(value: ExportStrategy) {
        this._exportStrategy = value;
        this.fileName = this._exportStrategy?.defaultFileName ?? 'data-export';
    }

    get exportStrategy(): ExportStrategy {
        return this._exportStrategy;
    }

    private _exportStrategy!: ExportStrategy;

    @Input()
    tableValues!: any[];

    @Input()
    exportFunction?: ((row: any, columnField: string, columnValue: string) => string) | undefined;

    @Output()
    visibleChange = new EventEmitter<boolean>();

    @ViewChild('exportForm')
    exportForm!: FormComponent;

    @ViewChild('listComponent')
    listComponent!: ListComponent<ColumnDirective>;

    get disableSave(): boolean {
        return (
            this.fileName == null ||
            this.fileName == '' ||
            this.selectedColumns?.length == 0 ||
            this.selectedFormat == null
        );
    }

    constructor(private exportService: TableExportService) {}

    ngOnInit() {
        this.selectedFormat = this.selectedFormat ?? this.supportedFormats[0];
    }

    handleSelectionChange() {
        this.isSelectedColumn = this.selectedColumns?.length > 0;
    }

    columnItemRenderer = (column: ColumnDirective) => column?.header ?? '';

    onExport() {
        if (!this.exportForm.valid || this.selectedColumns?.length == 0) {
            this.isSelectedColumn = this.selectedColumns?.length > 0;
            return;
        }

        this.createAndRetrieveFile(this._populateGridExportRequest());
        this.visible = false;
    }

    createAndRetrieveFile(request: TableExportRequest) {
        this.exportService
            .createAndRetrieveFile(request, this.exportStrategy?.serviceUrl ?? this.exportServiceUrl)
            .pipe(take(1))
            .subscribe({
                next: () => console.log('Success downloading the file'),
                error: (error) => {
                    console.error('Unable to export file:');
                    console.error(error);
                },
            });
    }

    private _populateGridExportRequest(): TableExportRequest {
        const exportRequest = new TableExportRequest(getExportFormatExtension(this.selectedFormat), this.fileName);

        exportRequest.includeHeaders = this.includeHeaderRow;
        this.selectedColumns.map((column: ColumnDirective) => {
            exportRequest.dataFields?.push(column.field);
            exportRequest.columnNames?.push(column.header);
        });

        if (this.exportStrategy?.parametersFunc) {
            exportRequest.parameters = this.exportStrategy.parametersFunc();
        } else {
            // generate the data as CSV format, looking at the selectedColumns and includeHeaderRow
            // AND at the data shown on TableComponent this export component is used.
            exportRequest.data = this._getDataAsCsv(this.selectedColumns, this.includeHeaderRow);
        }

        return exportRequest;
    }

    private _initFields() {
        this.exportStrategy?.defaultFileName && (this.fileName = this.exportStrategy.defaultFileName);
        this.listComponent?.clearSearch();
        this.selectedColumns = this._columns.filter((column: ColumnDirective) => column.visible);
        this.selectedFormat = this.supportedFormats[0];
        this.fileName = this._exportStrategy?.defaultFileName ?? 'data-export';
        this.includeHeaderRow = true;
        this.isSelectedColumn = true;
    }

    private _getDataAsCsv(columns: ColumnDirective[], includeHeaders: boolean): string {
        const csv = [];

        if (includeHeaders) {
            csv.push(columns.map((col) => `"${col.header}"`).join(','));
        }

        this.tableValues.forEach((row) => {
            csv.push(
                columns
                    .map(
                        (col) =>
                            (this.exportFunction && this.exportFunction(row, col.field, row[col.field])) ??
                            row[col.field] ??
                            ''
                    )
                    .map((d) => `"${d}"`)
                    .join(',')
            );
        });

        return csv.join('\n');
    }
}

export enum ExportFormat {
    EXCEL = 'Excel',
    PDF = 'PDF',
    CSV = 'CSV',
    HTML = 'HTML',
    XML = 'XML',
}

export function getExportFormatExtension(format: string | null) {
    switch (format) {
        case ExportFormat.EXCEL:
            return 'xlsx';
        case ExportFormat.PDF:
            return 'pdf';
        case ExportFormat.HTML:
            return 'html';
        case ExportFormat.XML:
            return 'xml';
        case ExportFormat.CSV:
        default:
            return 'csv';
    }
}
