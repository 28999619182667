import { StringUtil } from '../../util/string.util';
import { IUserKeyModel } from '../user-key.model';
import { Authenticator } from './authenticator.enum';
import { Language } from './language.enum';

export class WhoAmI {
    constructor(
        public username?: string,
        public authorities?: string[],
        public authenticator?: Authenticator,
        public orgId?: string,
        public usingMfa?: boolean,
        public credentialsExpired?: boolean,
        public lastName?: string,
        public firstName?: string,
        public email?: string,
        public isSsoUser?: boolean,
        public forcePasswordChange?: boolean,
        public timeZone?: string,
        public locale?: Language
    ) {}

    public isActive() {
        return true;
    }

    public getUsername(): string {
        return this.username ?? '';
    }

    public getAuthenticator(): string {
        return this.authenticator ?? '';
    }

    public getOrgId(): string {
        return this.orgId ?? '';
    }

    public hasAuthority(authority: string): boolean {
        return this.authorities != null && this.authorities.indexOf(authority) > -1;
    }

    public getUserKey(): IUserKeyModel {
        return {
            username: this.username ?? '',
            authenticator: this.authenticator ?? Authenticator.INTERNAL,
        };
    }

    public getFirstname(): string {
        return this.firstName && StringUtil.isNotBlankWithTrim(this.firstName) && this.firstName !== 'null'
            ? this.firstName
            : '';
    }

    public getLastname(): string {
        return this.lastName && StringUtil.isNotBlankWithTrim(this.lastName) && this.lastName !== 'null'
            ? this.lastName
            : '';
    }

    public getUserFullName(): string {
        const isFirstNameAvailable: boolean = StringUtil.isNotBlankWithTrim(this.getFirstname());
        const isLastNameAvailable: boolean = StringUtil.isNotBlankWithTrim(this.getLastname());
        if (isFirstNameAvailable && isLastNameAvailable) {
            return `${this.firstName} ${this.lastName}`;
        } else if (isFirstNameAvailable || isLastNameAvailable) {
            return isFirstNameAvailable ? `${this.firstName}` : `${this.lastName}`;
        } else {
            return ``;
        }
    }
}
