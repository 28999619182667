import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { includes, remove } from 'lodash-es';
import { MatListOptionCheckboxPosition } from '@angular/material/list';

@Component({
    selector: 'vc-list',
    templateUrl: './list.component.html',
    styleUrls: ['./list.component.scss'],
})
export class ListComponent<T> {
    searchValue: string = '';

    @Input()
    set values(val: T[]) {
        if (val != this._values) {
            this._values = val;
            this.valuesChange.emit(this._values);
        }
    }

    get values(): T[] {
        return this._values;
    }

    private _values: T[] = [];

    /** List of options for select dropdown */
    @Input()
    options!: T[];

    /** Allows to make list elements focusable */
    @Input()
    tabIndex: string = '0';

    /** Search label. */
    @Input()
    searchLabel: string = 'Search';

    /** Search key value. */
    @Input()
    searchKey: string = '';

    /** Identifies the element (or elements) that labels the element it is applied to. */
    @Input()
    ariaLabeledBy: string | null = '';

    /** Identifies the element (or elements) that describes the element on which the attribute is set. */
    @Input()
    ariaDescribedby: string | null = '';

    /** Specifies checkbox position for multiselect list (default 'after') */
    @Input()
    checkboxPosition: MatListOptionCheckboxPosition = 'after';

    /** Whether selection is limited to one or multiple items (default one). */
    @Input()
    multiple: boolean = false;

    /** Whether to show search. */
    @Input()
    showSearch: boolean = true;

    /** Function for rendering options list and selected values */
    @Input()
    itemRenderer: (data: T) => string = (value: T) => `${value}`;

    /** Options template renderer. */
    @Input()
    optionTemplate!: TemplateRef<T>;

    /** Two ways data binding for values */
    @Output()
    valuesChange = new EventEmitter<T[]>();

    selectionChange(selected: boolean, value: T) {
        if (selected) {
            this.values?.indexOf(value) == -1 && this.values.push(value);
        } else {
            remove(this.values, (val: T) => val == value);
        }

        this.valuesChange.emit(this.values);
    }

    checkIfSelected(value: T) {
        return includes(this.values, value);
    }

    clearSearch() {
        this.searchValue = '';
    }
}
