import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { MatTooltip, TooltipPosition } from '@angular/material/tooltip';

@Component({
    selector: 'vc-tooltip-text',
    templateUrl: './tooltip-text.component.html',
    styleUrls: ['./tooltip-text.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipTextComponent {
    @ViewChild('tooltip', { read: MatTooltip, static: false })
    public tooltip!: MatTooltip;

    /** Specifies tooltip position */
    @Input()
    position: TooltipPosition = 'above';

    /** Specifies tooltip message */
    @Input()
    tooltipMessage?: string;

    /** Specifies text */
    @Input()
    text!: string;

    /** Specifies css class for text element */
    @Input()
    textClass: string = '';

    /** Show tooltip for plain and truncated text  */
    @Input()
    showTooltip: boolean = false;

    public checkIfShowTooltip(e: MouseEvent): void {
        if (this.showTooltip) {
            this.tooltip.disabled = !this.showTooltip;
        } else {
            const element = e.target as HTMLElement;
            const offsetIsSmaller = element.offsetWidth < element.scrollWidth;

            this.tooltip.disabled = !offsetIsSmaller;
        }
    }
}
