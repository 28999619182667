import { Pipe, PipeTransform } from '@angular/core';
import { PageResponse } from '../model/page-response.model';
import { PageUtil } from '../util/page.util';

interface Page<T> {
    pageItems: T[];
    firstRowIndex: number;
    lastRowIndex: number;
    lastPageNumber: number;
    totalItems: number;
    advancedFilterMap?: Map<string, string[]>;
    filterFields?: string[];
    filterValue?: string;
    filterCaseInsensitive?: boolean;
    pageNumber: number;
    pageSize: number;
    previous?: boolean;
    previousPageFirstItem?: any;
    previousPageLastItem?: any;
    sortCaseInsensitive?: boolean;
    sortDescending?: boolean;
    sortField?: string;
}

/**
 * @description
 *
 * The `convertToPage` pipe converts a PageResponse into a Page
 *
 * @usageNotes
 *
 * ```
 * {{ usersPage | convertToPage }}
 * <!--will convert usersPage of type PageResponse<IUserModel> or null into Page<IUserModel>-->
 * ```
 *
 */
@Pipe({
    name: 'convertToPage',
    standalone: true,
})
export class ConvertToPagePipe implements PipeTransform {
    transform<T>(pageResponse: PageResponse<T> | null): Page<T> | null {
        if (pageResponse) {
            return PageUtil.convertToPage(pageResponse);
        }
        return null;
    }
}
