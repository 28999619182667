<mat-form-field
    class="vc-chips-autocomplete-container vc-mat-form-field"
    appearance="fill"
    [class.mat-form-field-disabled]="readonly || disabled"
    [class.mat-form-field-invalid]="!valid && touched"
    [class.vc-filter-minimal-styles]="minimalStyles">
    <mat-label>{{ label }}</mat-label>
    <mat-chip-list #chipList [attr.aria-label]="label">
        <mat-chip
            *ngFor="let val of values"
            color="accent"
            (removed)="remove(val)"
            class="vc-chips-autocomplete-container-chip">
            <vc-tooltip-text
                class="vc-chips-autocomplete-container-tooltip"
                [text]="itemRenderer(val)"
                [tooltipMessage]="itemRenderer(val)"></vc-tooltip-text>
            <button matChipRemove>
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip>
        <input
            #autocompleteInput
            [(ngModel)]="filterValue"
            [placeholder]="placeholder"
            mat-chip-trailing-icon
            [matAutocomplete]="auto"
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            [attr.aria-invalid]="!valid"
            [attr.aria-describedby]="ariaDescribedBy"
            [readonly]="readonly"
            [disabled]="disabled"
            [required]="required"
            (blur)="touched = true"
            (ngModelChange)="inputValueChanges()" />
    </mat-chip-list>
    <mat-icon matSuffix *ngIf="suffixIcon && values?.length === 0">{{ suffixIcon }}</mat-icon>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectValue($event)">
        <mat-option *ngFor="let value of filteredOptions" [value]="value">
            <vc-tooltip-text
                class="vc-chips-autocomplete-container-tooltip"
                [text]="itemRenderer(value)"
                [tooltipMessage]="itemRenderer(value)"></vc-tooltip-text>
        </mat-option>
    </mat-autocomplete>
    <mat-hint *ngIf="!valid && touched" class="mat-error vc-error-message">{{ errorMessage }}</mat-hint>
</mat-form-field>
