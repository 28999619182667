import { Component, ElementRef, EventEmitter, HostBinding, Input, Optional, Output, ViewChild } from '@angular/core';
import { BaseFieldComponent } from '../base-field/base-field';
import { FormComponent } from '../form/form.component';
import { DateFormats } from '../../common/date-formats';
import { TimeFormat, TimeModel } from '../../interfaces/time-picker';
import { cloneDeep } from 'lodash-es';

/** Example of date picker usage
 * HTML
 *   <vc-date-picker label="Date"
 *                   [required]="true"
 *                   [minDate]="now"
 *                   [(value)]="date"
 *                   (valueChange)="changeDate($event)"></vc-date-picker>
 *
 * TS
 * date!: Date | null;
 * now = new Date();
 *
 * changeDate(date: Date) {
 *   ......
 * }
 * */

@Component({
    selector: 'vc-date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent extends BaseFieldComponent<Date | null> {
    dateFormats = DateFormats;
    time!: TimeModel;

    @Input()
    set value(val: Date | null) {
        if (val != this._value) {
            this._value = val;
            this._setTime();
            this.validate();
            this.valueChange.emit(this._value);
        }
    }

    get value(): Date | null {
        return this._value;
    }

    private _value!: Date | null;

    /** Specifies minimum date value what can be set. */
    @Input()
    minDate!: Date;

    /** Specifies maxim date value what can be set. */
    @Input()
    maxDate!: Date;

    /** Date placeholder value */
    @Input()
    placeholder: string = '';

    /** Whether to show clear button  */
    @Input()
    showClear: boolean = true;

    /** Whether time is required */
    @Input()
    timeRequired: boolean = false;

    /** Specifies date time format */
    @Input()
    timeFormat!: TimeFormat;

    /** Whether to validate field on value changes. */
    @Input()
    validateOnValueChange: boolean = false;

    /** Whether to show Time Picker component */
    @Input()
    showTimePicker: boolean = false;

    /** Two ways data binding for date value */
    @Output()
    valueChange = new EventEmitter<Date | null>();

    @ViewChild('dateInput')
    dateInput!: ElementRef<HTMLInputElement>;

    @HostBinding('class.vc-date-time-picker')
    get className() {
        return this.showTimePicker;
    }

    constructor(@Optional() _form: FormComponent) {
        super(_form);
    }

    onBlur() {
        this.touched = true;
        this._checkDate();
        this.validate();
    }

    onKeyDown() {
        if (this.validateOnValueChange) {
            this.touched = true;
        }
    }

    onKeyUp() {
        if (this.validateOnValueChange) {
            this.touched = true;
        }
    }

    /** Validation function */
    validate(): boolean {
        this.valid = true;
        if (this.required && this.value == null) {
            this.errorMessage = `${this.label ?? this.fieldText} ${this.requiredText}`;
            this.valid = false;
        }

        if (this.validationFunction != null && this.valid) {
            this.errorMessage = this.validationFunction(this.value);
            this.valid = this.errorMessage == '';
        }

        this.errorMessage = this.valid ? '' : this.errorMessage;

        return this.valid;
    }

    clear() {
        if (!this.readonly && !this.disabled) {
            this.value = null;
            this.time = {
                hour: 0,
                minute: 0,
            };
            this.dateInput?.nativeElement && (this.dateInput.nativeElement.value = '');
        }
        this.errorMessage = '';
        this.valid = true;
        this.touched = false;
    }

    changeTime(timeModel: TimeModel) {
        let date = cloneDeep(this.value);
        date?.setHours(timeModel?.hour ?? 0);
        date?.setMinutes(timeModel?.minute ?? 0);
        this.value = date;
    }

    private _setTime() {
        if (
            this.showTimePicker &&
            (this.time?.hour != this.value?.getHours() || this.time?.minute != this.value?.getMinutes())
        ) {
            this.time = {
                hour: this.value?.getHours() ?? 0,
                minute: this.value?.getMinutes() ?? 0,
            };
        }
    }

    private _checkDate() {
        if (this.minDate && this.value && this.value?.getTime() < this.minDate?.getTime()) {
            this.value = this.minDate;
        }

        if (this.maxDate && this.value && this.value?.getTime() > this.maxDate?.getTime()) {
            this.value = this.maxDate;
        }
    }
}
