import { isEmpty } from 'lodash-es';
import { ITagModel } from '../model/tag.model';
import { TagModel } from '../model/tag.model.impl';
import { ListUtil } from './list.util';
import { StringUtil } from './string.util';

export abstract class TagUtil {
    private static DELIMITER = '/';

    public static EXT_TAG = '/ext/';
    public static PVT_TAG = '/pvt/';
    public static INT_TAG = '/vc/';
    public static TAG_VALUE_SEP = TagUtil.DELIMITER + '=';
    public static INT_ASSESSMENT_TAG_FORMAT = '/vc/assessment-id/=';
    public static INT_SCAN_TAG_FORMAT = '/vc/scan-id/=';
    public static KEY_TAG = '/vc/key/=';

    public static PVT_FRAMEWORKCOMPONENT_TAG_FORMAT = '/pvt/framework-component/=';

    public static format(tags: Array<ITagModel>): Array<ITagModel> {
        tags.forEach((tag) => {
            tag.tagValue = tag.prefix + TagUtil.DELIMITER + tag.name;
        });
        return tags;
    }

    public static prependExtTag(tags: Array<string>): Array<string> {
        return tags.map((tag) => TagUtil.EXT_TAG + tag);
    }

    public static removePrependExtTag(tags: Array<string>): Array<string> {
        return tags.map((tag) => tag.replace(TagUtil.EXT_TAG, ''));
    }

    public static formatExtTagWithFlag(tags: Array<ITagModel>): Array<ITagModel> {
        tags.forEach((tag) => {
            tag.tagValue = tag.prefix + TagUtil.DELIMITER + tag.name;
            tag.isNew = false;
        });
        return tags;
    }

    public static valueNormalizerAsTag(newTag: string, tags: Array<ITagModel>) {
        // change data type from TagModel to (TagModel | undefined)
        const matchingItem: ITagModel | undefined = tags.find((item: ITagModel) => {
            return item.name.toLowerCase() === newTag.toLowerCase();
        });
        if (matchingItem) {
            return matchingItem;
        } else {
            const tag = {} as ITagModel;
            tag.isNew = true;
            tag.name = newTag;
            tag.tagValue = TagUtil.EXT_TAG + newTag;
            tag.prefix = TagUtil.EXT_TAG;
            tag.displayPrefix = TagUtil.EXT_TAG;
            tag.displayValue = newTag;
            return tag;
        }
    }

    public static manageUpsertTagModels(tags: Array<ITagModel>): Array<string> {
        const newTags: Array<string> = [];
        tags.forEach((tag) => {
            if (tag.isNew) {
                newTags.push(tag.name);
            }
        });
        return newTags;
    }

    public static getTagsAsStrings(tags: Array<ITagModel>): Array<string> {
        return tags.map((tag) => tag.tagValue);
    }

    public static convertArrayStringToTags(tags: Array<string>): Array<ITagModel> {
        const newTags: Array<ITagModel> = [];
        if (!isEmpty(tags)) {
            tags.forEach((tagString) => {
                if (StringUtil.isNotBlank(tagString)) {
                    const tag = new TagModel();
                    let prefix = TagUtil.INT_TAG;
                    let name;
                    let value = null;

                    if (tagString.startsWith(TagUtil.EXT_TAG)) {
                        prefix = TagUtil.EXT_TAG;
                        name = tagString.substring(TagUtil.EXT_TAG.length, tagString.length);
                    } else if (tagString.startsWith(TagUtil.PVT_TAG)) {
                        prefix = TagUtil.PVT_TAG;
                        name = tagString.substring(TagUtil.PVT_TAG.length, tagString.length);
                    } else if (tagString.startsWith(TagUtil.INT_TAG)) {
                        name = tagString.substring(TagUtil.INT_TAG.length, tagString.length);
                    } else {
                        name = tagString;
                    }

                    if (name) {
                        const values = name.split(TagUtil.TAG_VALUE_SEP);
                        name = values[0];
                        if (values.length > 1) {
                            value = values[1];
                        }
                    }

                    tag.prefix = prefix;
                    tag.name = name;
                    if (value) {
                        tag.value = value;
                    }
                    tag.tagValue = tagString;
                    tag.isNew = false;
                    newTags.push(tag);
                }
            });
        }
        return newTags;
    }

    public static convertObjectArrayToTags(tags: Array<any>): Array<ITagModel> {
        const newTags: Array<ITagModel> = [];
        if (ListUtil.isNotEmpty(tags)) {
            tags.forEach((tagObj) => {
                const tag = new TagModel();
                tag.prefix = tagObj.prefix;
                tag.name = tagObj.name;
                tag.value = tagObj.value;
                tag.tagValue = tagObj.tagValue;
                tag.isNew = false;
                newTags.push(tag);
            });
        }
        return newTags;
    }

    public static createInternalTagWithValue(internalTagFormat: string, value: string): string {
        return internalTagFormat + value;
    }

    public static getBillingIDString(tags: string[]): string {
        const billingTags: string[] | undefined = tags.filter((value: string) => value.includes(TagUtil.KEY_TAG));
        const ids = billingTags?.map((value: string) => {
            const values = value.split(TagUtil.KEY_TAG);
            return values.length > 1 ? values[1].trim() : '';
        });

        return ids?.join(', ') ?? '';
    }
}
