import { Injectable, Injector } from '@angular/core';
import { IReferenceCountryModel } from '../model/reference-country.model';
import { IReferenceTimeZoneModel } from '../model/reference-time-zone.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EnvironmentConfig, ENV_CONFIG } from '@libs/vc-core-lib';

@Injectable()
export class ReferenceService {
    private _httpClient: HttpClient;
    private _environment: EnvironmentConfig;

    constructor(protected injector: Injector) {
        this._httpClient = injector.get(HttpClient);
        this._environment = injector.get(ENV_CONFIG);
    }

    public getAllTimeZones(): Observable<IReferenceTimeZoneModel[]> {
        return this._httpClient.get<IReferenceTimeZoneModel[]>(
            `${this._environment.APP.VC_REFERENCE_BASE_URL}/timezones/all`
        );
    }

    public getAllCountries(): Observable<IReferenceCountryModel[]> {
        return this._httpClient.get<IReferenceCountryModel[]>(
            `${this._environment.APP.VC_REFERENCE_BASE_URL}/countries/all`
        );
    }
}
