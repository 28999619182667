export enum Day {
    SUNDAY = 'SUNDAY',
    MONDAY = 'MONDAY',
    TUESDAY = 'TUESDAY',
    WEDNESDAY = 'WEDNESDAY',
    THURSDAY = 'THURSDAY',
    FRIDAY = 'FRIDAY',
    SATURDAY = 'SATURDAY',
}

export class DayHelper {
    static getDayLocale(day: Day): string {
        switch (day) {
            case Day.SUNDAY:
                return $localize`:@@CORE.DAY.SUNDAY:Sunday`;
            case Day.MONDAY:
                return $localize`:@@CORE.DAY.MONDAY:Monday`;
            case Day.TUESDAY:
                return $localize`:@@CORE.DAY.TUESDAY:Tuesday`;
            case Day.WEDNESDAY:
                return $localize`:@@CORE.DAY.WEDNESDAY:Wednesday`;
            case Day.THURSDAY:
                return $localize`:@@CORE.DAY.THURSDAY:Thursday`;
            case Day.FRIDAY:
                return $localize`:@@CORE.DAY.FRIDAY:Friday`;
            case Day.SATURDAY:
                return $localize`:@@CORE.DAY.SATURDAY:Saturday`;
            default:
                return '';
        }
    }
}
