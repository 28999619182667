<mat-form-field
    class="vc-date-picker-form-field vc-mat-form-field"
    appearance="fill"
    [class.mat-form-field-disabled]="readonly || disabled"
    [class.mat-form-field-readonly]="readonly"
    [class.mat-form-field-invalid]="!valid && touched"
    [class.vc-filter-minimal-styles]="minimalStyles">
    <mat-label>{{ label }}</mat-label>
    <input
        #dateInput
        class="vc-date-picker-input"
        matInput
        [disabled]="disabled || readonly"
        [min]="minDate"
        [max]="maxDate"
        [required]="required"
        [matDatepicker]="picker"
        (blur)="onBlur()"
        (keydown)="onKeyDown()"
        (keyup)="onKeyUp()"
        [(ngModel)]="value" />
    <mat-datepicker-toggle *ngIf="value && showClear && !readonly && !disabled" matSuffix (click)="clear()">
        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker-toggle matSuffix [disabled]="readonly || disabled" [for]="picker"> </mat-datepicker-toggle>
    <mat-datepicker #picker></mat-datepicker>
    <mat-hint *ngIf="!valid && touched" class="mat-error vc-error-message">{{ errorMessage }}</mat-hint>
</mat-form-field>

<vc-time-picker
    *ngIf="showTimePicker"
    class="vc-date-picker-time-picker"
    [disabled]="!value"
    [(value)]="time"
    [minimalStyles]="minimalStyles"
    [required]="timeRequired"
    [timeFormat]="timeFormat"
    (valueChange)="changeTime($event)">
</vc-time-picker>
