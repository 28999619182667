<div *ngIf="visible" class="vc-notification-box" role="alert" [ngClass]="type">
    <div *ngIf="title && title !== ''" class="vc-notification-box-title">
        <div>{{ title }}</div>
        <button
            *ngIf="showClose"
            mat-icon-button
            class="vc-notification-box-close-button"
            aria-label="Close the notification"
            type="button"
            (click)="close()">
            <mat-icon>cancel</mat-icon>
        </button>
    </div>
    <div class="vc-notification-box-wrapper">
        <div class="vc-notification-box-icon">
            <mat-icon aria-hidden="false" [fontIcon]="type | notificationIcon : icon"></mat-icon>
        </div>
        <div class="vc-notification-box-message-wrapper">
            <div *ngIf="message" class="vc-notification-box-message">{{ message }}</div>
            <ng-content select="[notificationTemplate]"></ng-content>
            <button
                *ngIf="(!title || title === '') && showClose"
                mat-icon-button
                class="vc-notification-box-close-button"
                aria-label="Close the notification"
                type="button"
                (click)="close()">
                <mat-icon>cancel</mat-icon>
            </button>
        </div>
    </div>
</div>
