import { Component, Input } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
    selector: 'vc-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss'],
})
export class IconComponent {
    /** Specifies icon name */
    @Input()
    name!: string;

    /** Specifies icon aria label */
    @Input()
    ariaLabel!: string;

    /** Specifies icon tooltip */
    @Input()
    tooltip!: string;

    /** Specifies icon tooltip position */
    @Input()
    tooltipPosition: TooltipPosition = 'below';

    /** Specifies icon color */
    @Input()
    color!: string;

    /** Specifies icon size */
    @Input()
    size: string = '24px';
}
