import { Injectable, Injector } from '@angular/core';
import { BaseService } from '../../base/base.service';
import { IUserPreferenceModel, UserPreferenceHelper } from '../../model/user/user-preference.model';
import { BehaviorSubject, Observable, of, take, tap } from 'rxjs';
import { UserContextService } from '../../context/user.context.service';
import { WhoAmI } from '../../model/user/whoAmI.model';
import { Theme } from '../../model/user/theme.enum';
import { HttpParams } from '@angular/common/http';
import { IUserPreferenceRequest } from '../../model/user/user-preference-request.model';
import { CoreConstant, UserPreferenceConstant } from '../../constant/base.constant';
import { CommonUtil } from '../../util/common.util';
import { isEqual } from 'lodash-es';
import { IUserKeyModel } from '../../model/user-key.model';

@Injectable({
    providedIn: 'root',
})
export class UserPreferenceService extends BaseService<IUserPreferenceModel> {
    private _userInSession!: WhoAmI;
    private _userTheme$ = new BehaviorSubject<Theme>(Theme.light);
    private _userPreferences = new Map<string, Map<string, Map<string, string>>>();

    constructor(private _userContextService: UserContextService, protected injector: Injector) {
        super(injector, 'userpreference');

        // Sync theme with what's stored in localStorage
        this._userTheme$.next((localStorage.getItem(CoreConstant.VC_THEME) as Theme) ?? Theme.light);

        this._userContextService
            .getContext()
            .pipe(take(1))
            .subscribe((userContext: WhoAmI | null) => {
                if (userContext) {
                    this._userInSession = userContext;
                }
            });
    }

    public getUserTheme(): Observable<Theme | null> {
        return this._userTheme$.asObservable();
    }

    public saveUserTheme(theme: Theme): Observable<IUserPreferenceModel> {
        this._userTheme$.next(theme);

        const userPreference: IUserPreferenceModel = {
            userKey: this._userInSession?.getUserKey(),
            category: UserPreferenceConstant.CATEGORY_UI,
            subCategory: UserPreferenceConstant.SUBCATEGORY_LAYOUT,
            attributeKey: UserPreferenceConstant.ATTRIBUTEKEY_THEME,
            attributeValue: theme,
        } as IUserPreferenceModel;

        return this._saveUserPreference(userPreference).pipe(
            take(1),
            tap(() => {
                CommonUtil.switchTheme(theme, true);
            })
        );
    }

    public getUserPreference(request: IUserPreferenceRequest): string | null {
        const categoryMap: Map<string, Map<string, string>> | undefined = this._userPreferences.get(request.category);

        if (categoryMap && request.subcategory) {
            const subcategoryMap: Map<string, string> | undefined = categoryMap.get(request.subcategory);

            if (subcategoryMap && request.attributeKey) {
                const attribute = subcategoryMap.get(request.attributeKey);

                if (attribute) {
                    return attribute;
                }
            }
        }

        return null;
    }

    public getUserPreferenceForCategory(request: IUserPreferenceRequest): Map<string, string> | null {
        const categoryMap: Map<string, Map<string, string>> | undefined = this._userPreferences.get(request.category);

        if (categoryMap && request.subcategory) {
            const subcategoryMap: Map<string, string> | undefined = categoryMap.get(request.subcategory);
            if (subcategoryMap) {
                return subcategoryMap;
            }
        }

        return null;
    }

    public setUserPreference(model: IUserPreferenceModel): Observable<IUserPreferenceModel> {
        const request = this.getUserPreference(UserPreferenceHelper.getRequestFromModel(model));
        if (isEqual(model.attributeValue, request)) {
            return of(model);
        }

        this._storePreference(model);
        return this._saveUserPreference(model);
    }

    public initializeUserPreferences(userKey: IUserKeyModel): Observable<IUserPreferenceModel[]> {
        return this._getUserPreferences(userKey).pipe(
            take(1),
            tap((preferences: IUserPreferenceModel[]) => {
                this._initializeUserTheme(preferences);
                this._initializeUserPreferences(preferences);
            })
        );
    }

    private _initializeUserTheme(preferences: IUserPreferenceModel[]) {
        let parsedTheme: Theme = Theme.light;
        const theme: Theme | string =
            (preferences.find((pref) => pref.attributeKey === UserPreferenceConstant.ATTRIBUTEKEY_THEME)
                ?.attributeValue as Theme) ?? Theme.light;

        // Clean-up all the ways UI theme has been stored in localStorage in the past
        try {
            let themeValue: any = JSON.parse(theme);
            if (themeValue.theme) {
                parsedTheme = themeValue.theme as Theme;
            }

            if (themeValue.isDefaultTheme) {
                parsedTheme = themeValue.isDefaultTheme ? Theme.light : Theme.dark;
            }
        } catch (e) {
            parsedTheme = theme as Theme;
        }

        // Reload only if user preference theme is different from localStorage theme
        // User Preference takes precedence over localStorage
        if (parsedTheme !== this._userTheme$.value) {
            this._userTheme$.next(parsedTheme);

            CommonUtil.switchTheme(this._userTheme$.value, true);
        }
    }

    private _initializeUserPreferences(preferences: IUserPreferenceModel[]) {
        this._userPreferences.clear();
        const value = new Map<string, Map<string, Map<string, string>>>();
        preferences = preferences.filter(
            (preference: IUserPreferenceModel) => preference.attributeKey !== UserPreferenceConstant.ATTRIBUTEKEY_THEME
        );

        preferences.forEach((preference) => {
            if (!value.has(preference.category)) {
                value.set(preference.category, new Map());
            }

            if (!value.get(preference.category)?.has(preference.subCategory)) {
                value.get(preference.category)?.set(preference.subCategory, new Map());
            }

            value
                .get(preference.category)
                ?.get(preference.subCategory)
                ?.set(preference.attributeKey, preference.attributeValue);
        });

        this._userPreferences = value;
    }

    private _getUserPreferences(userKey: IUserKeyModel): Observable<IUserPreferenceModel[]> {
        const params = new HttpParams({ fromObject: { ...userKey } });
        return this.httpClient.get<IUserPreferenceModel[]>(
            `${this.environment.APP.VC_USER_PREFERENCE_BASE_URL}/${this.serviceBaseUrl}/list`,
            {
                params: params,
            }
        );
    }

    private _saveUserPreference(userPreference: IUserPreferenceModel): Observable<IUserPreferenceModel> {
        return this.httpClient.post<IUserPreferenceModel>(
            `${this.environment.APP.VC_USER_PREFERENCE_BASE_URL}/${this.serviceBaseUrl}/save`,
            userPreference
        );
    }

    private _storePreference(model: IUserPreferenceModel) {
        this._userPreferences
            .get(model.category)
            ?.get(model.subCategory)
            ?.set(model.attributeKey, model.attributeValue);
    }

    public clear() {
        this._userTheme$.next(Theme.light);
        this._userPreferences.clear();
    }

    /**
     * @deprecated use saveUserTableState instead
     */
    saveUserPreference(userPreference: IUserPreferenceModel): Observable<IUserPreferenceModel[]> {
        return this.httpClient.post<IUserPreferenceModel[]>(
            `${this.environment.APP.VC_USER_PREFERENCE_BASE_URL}/${this.serviceBaseUrl}/save`,
            userPreference
        );
    }
}
