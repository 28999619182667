<ng-container *ngIf="!mfaEnabled; else mfaDescription">
    <h1 class="vc-reset-greeting headline-l">{{ isWelcome ? welcomeText : resetPasswordText }}</h1>
    <span id="vc-reset-form-description" style="display: none" i18n="@@LOGIN.RESET_PASSWORD_FORM"
        >Reset password form</span
    >

    <span *ngIf="isWelcome" class="vc-reset-token-description" i18n="@@LOGIN.TOKEN_DESCRIPTION">
        A token was just sent to your email on file. Please copy and paste the token below and then create your
        password.
    </span>
</ng-container>
<ng-template #mfaDescription>
    <div class="vc-login-main-greeting-wrapper">
        <h1 class="vc-login-main-greeting title-m" i18n="@@LOGIN.MFA">Multifactor authentication</h1>
        <div i18n="@@LOGIN.MFA_DESCRIPTION" class="body-m-1">
            Please open your authenticator app and enter the latest generated 6-digit code.
        </div>
    </div>
</ng-template>

<vc-common-form
    #resetPasswordForm
    class="vc-reset-form"
    [ariaDescribedBy]="'vc-reset-form-description'"
    [showPrimaryButton]="false"
    [showSecondaryButton]="false">
    <vc-input
        [hidden]="mfaEnabled"
        label="Username"
        i18n-label="@@LOGIN.FORM.USERNAME"
        class="vc-reset-username"
        [required]="true"
        [autocomplete]="'username'"
        [disabled]="isWelcome"
        [(value)]="username"></vc-input>
    <vc-input
        [hidden]="mfaEnabled"
        label="Token"
        i18n-label="@@LOGIN.TOKEN"
        class="vc-reset-token"
        [required]="true"
        [(value)]="token"></vc-input>
    <vc-password
        [hidden]="mfaEnabled"
        label="New password"
        i18n-label="@@LOGIN.NEW_PASSWORD"
        class="vc-reset-new-password"
        i18n-description="@@LOGIN.PASSWORD_REQUIREMENTS_TEXT"
        description="Password must be 8 to 32 characters, contain at least 1 upper case, 1 lower case, 1 number and 1 special character."
        [required]="true"
        [autocomplete]="'new-password'"
        [(value)]="newPassword"
        [maxLength]="32"
        [minLength]="8"
        [minNumbers]="1"
        [minUpper]="1"
        [minSpecial]="1"
        [showClear]="true"></vc-password>
    <vc-password
        [hidden]="mfaEnabled"
        label="Repeat password"
        i18n-label="@@LOGIN.REPEAT_PASSWORD"
        class="vc-reset-confirm-password"
        [required]="true"
        [mustMatch]="newPassword"
        [autocomplete]="'new-password'"
        [(value)]="confirmPassword"
        [showClear]="true"></vc-password>
    <vc-input
        #mfaInput
        *ngIf="mfaEnabled"
        label="Authentication Code"
        i18n-label="@@LOGIN.FORM.AUTHENTICATION_CODE"
        [required]="true"
        [(value)]="mfaToken"
        [showClear]="true"></vc-input>
    <div buttons class="vc-reset-form-buttons">
        <vc-button
            type="submit"
            mode="primary"
            label="Submit"
            i18n-label="@@LOGIN.BUTTON.SUBMIT"
            [width]="'160px'"
            [loading]="submitLoading"
            (trigger)="onResetPassword()"></vc-button>
        <vc-button
            mode="basic"
            label="Need token?"
            i18n-label="@@LOGIN.NEED_TOKEN"
            [width]="'160px'"
            (trigger)="navigateToForgotPassword()"></vc-button>
    </div>
</vc-common-form>

<div class="vc-reset-form-links">
    <vc-link
        label="Return to login"
        i18n-label="@@LOGIN.RETURN_TO_LOGIN"
        [routerLink]="'/login'"
        [color]="'var(--primary-700)'"
        [target]="'_self'"
        [underlineText]="true"></vc-link>
    <vc-link
        label="Password Requirements"
        i18n-label="@@LOGIN.PASSWORD_REQUIREMENTS"
        [openViaTarget]="false"
        [underlineText]="true"
        [color]="'var(--primary-700)'"
        (trigger)="showPasswordRequirements = true"></vc-link>
</div>

<vc-dialog
    [(visible)]="showPasswordRequirements"
    title="Password requirements"
    i18n-title="@@LOGIN.PASSWORD_REQUIREMENTS"
    [width]="'430px'"
    [showActions]="false"
    [showPrimary]="false"
    [showSecondary]="false">
    <p i18n="@@LOGIN.PASSWORD_REQUIREMENTS_TEXT" class="body-m-1">
        Password must be 8 to 32 characters, contain at least 1 upper case, 1 lower case, 1 number and 1 special
        character.
    </p>
</vc-dialog>
