<mat-form-field
    class="vc-date-picker-range-container vc-mat-form-field"
    appearance="fill"
    [class.mat-form-field-disabled]="readonly || disabled"
    [class.mat-form-field-readonly]="readonly"
    [class.mat-form-field-invalid]="!valid && touched"
    [class.vc-filter-minimal-styles]="minimalStyles">
    <mat-label>{{ label }}</mat-label>
    <mat-date-range-input
        [rangePicker]="rangePicker"
        [min]="minDate"
        [max]="maxDate"
        [matTooltip]="tooltip"
        [matTooltipPosition]="tooltipPosition"
        [matTooltipDisabled]="tooltipDisabled"
        [disabled]="disabled"
        [required]="required">
        <input
            #startDateInput
            matStartDate
            class="vc-date-picker-range-input"
            [readonly]="readonly"
            [disabled]="disabled"
            [placeholder]="startDatePlaceholder"
            (blur)="onBlur()"
            (keydown)="onKeyDown()"
            (keyup)="onKeyUp()"
            [(ngModel)]="startDate" />
        <input
            #endDateInput
            matEndDate
            class="vc-date-picker-range-input"
            [readonly]="readonly"
            [disabled]="disabled"
            [placeholder]="endDatePlaceholder"
            (blur)="onBlur()"
            (keydown)="onKeyDown()"
            (keyup)="onKeyUp()"
            [(ngModel)]="endDate" />
    </mat-date-range-input>
    <mat-datepicker-toggle *ngIf="(startDate || endDate) && showClear && !readonly && !disabled" matSuffix (click)="clear()">
        <mat-icon matDatepickerToggleIcon>clear</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker-toggle matSuffix [for]="rangePicker"
                           [disabled]="readonly || disabled"></mat-datepicker-toggle>
    <mat-date-range-picker #rangePicker (closed)="valueChanged()"></mat-date-range-picker>
    <mat-hint *ngIf="!valid && touched" class="mat-error vc-error-message">{{ errorMessage }}</mat-hint>
</mat-form-field>
