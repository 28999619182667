import { Component, ContentChildren, QueryList } from '@angular/core';
import { AccordionPanelComponent } from '../accordion-panel/accordion-panel.component';

/**
 * Example of usage
 * <vc-button label="Expand All" (trigger)="accordion.expandAll()"></vc-button>
 * <vc-button label="Collapse All" (trigger)="accordion.collapseAll()"></vc-button>
 * <vc-accordion #accordion>
 *   <vc-accordion-panel title="Panel 1">
 *      Content Panel 1
 *   </vc-accordion-panel>
 *   <vc-accordion-panel title="Panel 2">
 *      Content Panel 2
 *   </vc-accordion-panel>
 *   <vc-accordion-panel title="Panel 3">
 *      Content Panel 3
 *   </vc-accordion-panel>
 *   <vc-accordion-panel title="Panel 4">
 *      Content Panel 4
 *   </vc-accordion-panel>
 * </vc-accordion>
 * */

@Component({
    selector: 'vc-accordion',
    templateUrl: './accordion.component.html',
    styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent {
    @ContentChildren(AccordionPanelComponent)
    set panels(values: QueryList<AccordionPanelComponent>) {
        this._panels = values;
    }

    get panels(): QueryList<AccordionPanelComponent> {
        return this._panels;
    }

    private _panels!: QueryList<AccordionPanelComponent>;

    expandAll() {
        this._panels.forEach((pan) => (pan.expanded = true));
    }

    collapseAll() {
        this._panels.forEach((pan) => (pan.expanded = false));
    }
}
