import { Component, Input, OnDestroy, Optional } from '@angular/core';
import { FormComponent } from '../form/form.component';
import { IDGenerator } from '../../common/id-generator';

@Component({
    template: '',
})
export class BaseFieldComponent<T> implements OnDestroy {
    public requiredText = $localize`:@@COMMON_UI.VALIDATION.IS_REQUIRED:is required.`;
    public fieldText = $localize`:@@COMMON_UI.VALIDATION.THIS_FIELD:This field.`;
    valid: boolean = true;
    id: string = IDGenerator.generateID();
    touched = false;

    /** Field label value */
    @Input()
    label!: string;

    /** If enabled, field can't be modified */
    @Input()
    readonly: boolean = false;

    /** Whether the field is disabled. */
    @Input()
    disabled: boolean = false;

    /** Whether the field is required. */
    @Input()
    required: boolean = false;

    /** Whether to apply minimal styles(no border, background, underline etc). Applies only to
     * input/textarea/select/autocomplete/multiselect/datepicker etc.  */
    @Input()
    minimalStyles: boolean = false;

    /** Function to validate field, should return error message */
    @Input()
    validationFunction!: ValidationFunction<T> | null;

    errorMessage!: string;

    constructor(@Optional() private _form: FormComponent) {
        _form?.addFormField(this);
    }

    validate(): boolean {
        return true;
    }

    clear() {
        this.valid = true;
    }

    setErrorState(message?: string) {
        this.valid = false;
        this.touched = true;
        message && (this.errorMessage = message);
    }

    ngOnDestroy(): void {
        this._form?.removeFormField(this);
    }
}

export type ValidationFunction<T> = (value: any) => string;
