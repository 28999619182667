import { Component, ContentChildren, EventEmitter, Input, OnDestroy, Output, QueryList } from '@angular/core';
import { TabDirective } from '../tab.directive';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 *
 * HTML
 * <vc-tab-group [selectedIndex]="selectedIndex"
 *               (selectedIndexChange)="selectedIndexChange($event)">
 *     <ng-template vcTab
 *                  label="Tab 1">
 *        My Content for Tab 1
 *     </ng-template>
 *     <ng-template vcTab
 *                  label="Tab 2">
 *         My Content for Tab 2
 *     </ng-template>
 * </vc-tab-group>
 *
 * TS
 * selectedIndex: number = 0;
 * selectedIndexChange(index: number) {
 *   ......
 * }
 * */

@Component({
    selector: 'vc-tab-group',
    templateUrl: './tab-group.component.html',
    styleUrls: ['./tab-group.component.scss'],
})
export class TabGroupComponent implements OnDestroy {
    visitedIndexes: Set<number> = new Set([0]);
    private _navigationUrl!: string;
    private _destroy$: Subject<void> = new Subject();

    /** The index of the active tab. */
    @Input()
    set selectedIndex(index: number) {
        if (this._selectedIndex !== index) {
            this._selectedIndex = index;
        }
    }

    get selectedIndex(): number {
        return this._selectedIndex;
    }
    private _selectedIndex: number = 0;

    /** Duration for the tab animation. Will be normalized to milliseconds if no units are set. */
    @Input()
    animationDuration: string = '0ms';

    /** Event emitted when the selected tab has changed. */
    @Output()
    selectedIndexChange = new EventEmitter<number>();

    @ContentChildren(TabDirective)
    set tabs(value: QueryList<TabDirective>) {
        this._tabs = value;
        this._setActiveTab();
    }

    get tabs(): QueryList<TabDirective> {
        return this._tabs;
    }

    private _tabs!: QueryList<TabDirective>;

    constructor(private _route: Router) {
        this._navigationUrl = this._route.url;
        this._route.events.pipe(takeUntil(this._destroy$)).subscribe((e) => {
            if (e instanceof NavigationEnd && this._navigationUrl != e.url) {
                this._navigationUrl = e.url;
                this._setActiveTab();
            }
        });
    }

    changeIndex(ind: number) {
        this.selectedIndexChange.emit(ind);
        this.visitedIndexes.add(ind);
        const tab = this._tabs?.get(ind);
        if (tab?.link) {
            this._route.navigate([tab.link], tab.queryParams ?? undefined);
        }
    }

    private _setActiveTab() {
        const index = this.tabs?.toArray()?.findIndex((tab) => tab.link && this._navigationUrl?.includes(tab.link));
        this.selectedIndex = index > -1 ? index : 0;
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
