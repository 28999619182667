import { HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { map, Observable } from 'rxjs';
import { WhoAmI } from '../../model/user/whoAmI.model';
import { ILoginModel } from '../../model/user/login.model';
import { UserContextService } from '../../context/user.context.service';
import { CoreConstant } from '../../constant/base.constant';
import { BaseService } from '../../base/base.service';

@Injectable({ providedIn: 'root' })
export class LoginService extends BaseService<ILoginModel> {
    redirectUrl: string | null = null;
    constructor(protected injector: Injector, private _userContextService: UserContextService) {
        super(injector, '');
    }

    public login(model: ILoginModel): Observable<WhoAmI> {
        const loginForm = new FormData();

        for (let [key, value] of Object.entries(model)) {
            loginForm.append(key, value);
        }

        return this.httpClient
            .post<WhoAmI>(`${this.environment.APP.VC_USER_BASE_URL}/login`, loginForm, {
                observe: 'response',
            })
            .pipe(
                map((response) => {
                    this._setSession(response);
                    return response.body as WhoAmI;
                })
            );
    }

    public logout(): Observable<void> {
        this.redirectUrl = null;
        return this.httpClient.post<void>(`${this.environment.APP.VC_USER_BASE_URL}/logout`, {});
    }

    private _setSession(response: HttpResponse<WhoAmI>): void {
        const userToken = response.headers.get(CoreConstant.X_AUTH_TOKEN);
        this._userContextService.setToken(userToken);
    }
}
