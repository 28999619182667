import { Pipe, PipeTransform } from '@angular/core';
import { Tag } from '../../models/tag';
import { TagPrefix } from '../../enums/tag-prefix';

@Pipe({
    name: 'tagGroup',
})
export class TagGroupPipe implements PipeTransform {
    transform(value: Tag): string {
        const prefix = value.prefix.replace(/[/]/g, '');
        switch (prefix) {
            case TagPrefix.EXTERNAL:
                return 'External';
            case TagPrefix.INTERNAL:
                return 'Internal';
            case TagPrefix.PRIVATE:
                return 'Private';
            default:
                return '';
        }
    }
}
