import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
    selector: '[vcStep]',
})
export class StepDirective {
    /** Step label. */
    @Input()
    label: string = '';

    /** Step error message. */
    @Input()
    errorMessage: string = '';

    /** Step icon. */
    @Input()
    iconClass: string = '';

    /** Whether step is marked as completed. */
    @Input()
    completed: boolean = true;

    /** Whether the completion of step is optional. */
    @Input()
    optional: boolean = false;

    /** Whether the step can be edited. */
    @Input()
    editable: boolean = true;

    get hasError(): boolean {
        return this.errorMessage != null && this.errorMessage != '';
    }

    constructor(public stepTemplate: TemplateRef<any>) {}
}
