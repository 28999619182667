import { AfterViewInit, ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormComponent, NotificationService } from '@libs/vc-common-ui-lib';
import { CommonUtil, UserService } from '@libs/vc-core-lib';
import { finalize, take } from 'rxjs';

@Component({
    selector: 'vc-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordComponent implements AfterViewInit {
    username!: string;
    submitLoading = false;

    @ViewChild('forgotPasswordForm')
    forgotPasswordForm!: FormComponent;

    constructor(
        private _userService: UserService,
        private _notificationService: NotificationService,
        private router: Router
    ) {}

    ngAfterViewInit(): void {
        CommonUtil.hideAppLoader();
    }

    getToken() {
        if (!this.forgotPasswordForm?.submit()) return;
        this.submitLoading = true;

        this._userService
            .sendResetLink(this.username)
            .pipe(
                take(1),
                finalize(() => (this.submitLoading = false))
            )
            .subscribe({
                next: () => {
                    this.router.navigate([`/reset-password/${this.username}`]);
                    this._notificationService.info(
                        '',
                        $localize`:@@LOGIN.IF_USERNAME_EXISTS:If username exists, password reset token will be sent to associated email.`
                    );
                },
                error: () => {
                    this._notificationService.error(
                        '',
                        $localize`:@@LOGIN.RESET_TOO_SOON:Reset too soon.  Try again in 1 hour.`
                    );
                },
            });
    }

    navigateToResetPassword() {
        this.router.navigate(['/reset-password']);
    }
}
