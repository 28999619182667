import { Pipe, PipeTransform } from '@angular/core';
import { EventKey, EventKeyHelper } from '@libs/vc-core-lib';

@Pipe({
    standalone: true,
    name: 'notificationIcon',
})
export class NotificationIconPipe implements PipeTransform {
    transform(value: EventKey): string {
        return EventKeyHelper.getEventKeyIcon(value);
    }
}
