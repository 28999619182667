import { EnvironmentConfig, ServiceUrls } from '@libs/vc-core-lib';
export abstract class APP {
    public static VC_BASE_API_URL: string = 'https://vcapi.zippingo.in';
    public static VC_USER_BASE_URL: string = `${APP.VC_BASE_API_URL}/user-service/v1`;
    public static VC_USER_PREFERENCE_BASE_URL: string = `${APP.VC_BASE_API_URL}/user-preference-service/v1`;
    public static VC_USER_REGISTRATION_BASE_URL: string = `${APP.VC_BASE_API_URL}/user-registration-service/v1`;
    public static VC_NOTIFICATION_BASE_URL: string = `${APP.VC_BASE_API_URL}/notification-service/v1`;
    public static VC_ORG_BASE_URL: string = `${APP.VC_BASE_API_URL}/org-service/v1`;
    public static VC_ASSET_BASE_URL: string = `${APP.VC_BASE_API_URL}/asset-service/v1`;
    public static VC_FINDING_BASE_URL: string = `${APP.VC_BASE_API_URL}/finding-service/v1`;
    public static VC_EVENT_BASE_URL: string = `${APP.VC_BASE_API_URL}/event-service/v1`;
    public static VC_REFERENCE_BASE_URL: string = `${APP.VC_BASE_API_URL}/reference-data-service/v1`;
    public static VC_ASSESSMENT_BASE_URL: string = `${APP.VC_BASE_API_URL}/assessment-service/v1`;
    public static VC_IMPORTS_BASE_URL: string = `${APP.VC_BASE_API_URL}/imports-service/v1`;
    public static VC_FILE_LOCKER_BASE_URL: string = `${APP.VC_BASE_API_URL}/file-locker-service/v1`;
    public static VC_SCAN_BASE_URL: string = `${APP.VC_BASE_API_URL}/scan-service/v1`;
    public static VC_UTM_BASE_URL: string = `${APP.VC_BASE_API_URL}/utm-service/v1`;
    public static VC_ENDPOINT_CONTROL_BASE_URL: string = `${APP.VC_BASE_API_URL}/endpoint-control-service/v1`;
    public static VC_ENDPOINT_INSTALLER_BASE_URL: string = `${APP.VC_BASE_API_URL}/endpoint-activation-service/v1`;
    public static VC_DISPUTE_BASE_URL: string = `${APP.VC_BASE_API_URL}/dispute-service/v1`;
    public static VC_WALLET_BASE_URL: string = `${APP.VC_BASE_API_URL}/wallet-service/v1`;
    public static VC_STORE_BASE_URL: string = `${APP.VC_BASE_API_URL}/store-service/v1`;
    public static VC_PAYMENT_BASE_URL: string = `${APP.VC_BASE_API_URL}/payment-service/v1`;
    public static VC_QUESTIONNAIRE_BASE_URL: string = `${APP.VC_BASE_API_URL}/questionnaire-service/v1`;
    public static VC_VULNDB_BASE_URL: string = `${APP.VC_BASE_API_URL}/vulndb-service/v1`;
    public static VC_COMMENT_BASE_URL: string = `${APP.VC_BASE_API_URL}/comments-service/v1`;
    public static VC_WRM_BASE_URL: string = `${APP.VC_BASE_API_URL}/wrm-ui-service/v0`;
    public static VC_CCS_BASE_URL: string = `${APP.VC_BASE_API_URL}/ccs-acquirer-core-service/v1`;
    public static VC_CCS_MERCHANT_BASE_URL: string = `${APP.VC_BASE_API_URL}/ccs-merchant-core-service/v1`;
    public static VC_CCS_SAQ_BASE_URL: string = `${APP.VC_BASE_API_URL}/ccs-saq-service/v1`;
    public static VC_CCS_COMPLIENCE_INFORMATION_BASE_URL: string = `${APP.VC_BASE_API_URL}/ccs-compliance-information-service/v1`;
    public static VC_MSS_COMMON_SERVICE_BASE_URL: string = `${APP.VC_BASE_API_URL}/mss-common-service/v1`;
    public static VC_CCS_PROFILE_BASE_URL: string = `${APP.VC_BASE_API_URL}/ccs-profile-service/v1`;
    public static VC_TICKETS_BASE_URL: string = `${APP.VC_BASE_API_URL}/ticket-service/v1`;
    public static VC_TICKET_FACADE_BASE_URL: string = `${APP.VC_BASE_API_URL}/mss-ticket-facade-service/v1`;
}

export const environment = {
    production: false,
    APP: APP,
    adyen: {
        clientKey: 'test_QOQMUQA275AV3B57S2TXCMFLSYDCI653',
        env: 'test',
    },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
