import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';

@Component({
    selector: 'vc-link',
    templateUrl: './link.component.html',
    styleUrls: ['./link.component.scss'],
})
export class LinkComponent {
    /** Specifies the label of the link, if not set, url shows as a label. */
    @Input()
    label!: string;

    /** Specifies the URL of the page the link goes to. */
    @Input()
    url!: string;

    /** Specifies the routerLink the link goes to. */
    @Input()
    routerLink!: string;

    /** The target attribute specifies where to open the linked document.
     *
     *  Values:  _blank | _self | _parent | _top | *framename* */
    @Input()
    target: string = '_blank';

    /** Whether to open the linked document via the target. */
    @Input()
    openViaTarget: boolean = true;

    /** Specifies tooltip text for tooltip upon hovering.  Defaults to url if set. */
    @Input()
    tooltip!: string;

    /** Specifies text color */
    @Input()
    color: string = 'var(--primary-800)';

    @Input()
    padding: string = '5px 10px';

    /** Specifies text-decoration as underline */
    @Input()
    underlineText = false;

    /** Specifies text-decoration as underline */
    @Input()
    showOpenInNewWindow = false;

    /** Specifies tooltip position.  Values: 'left' | 'right' | 'above' | 'below' | 'before' | 'after' */
    @Input()
    tooltipPosition: TooltipPosition = 'below';

    /** Specify the tabindex of anchor tag.  Defaults as '0' */
    @Input()
    tabindex: string = '0';

    /** Event is fired when link click, enter press or space press */
    @Output()
    trigger = new EventEmitter<Event>();

    linkTrigger(e: Event) {
        e.stopPropagation();
        e.preventDefault();

        this.trigger.emit(e);

        if (this.openViaTarget) {
            window.open(this.url, this.target);
        }
    }
}
