<mat-form-field
    class="vc-multi-select vc-mat-form-field"
    appearance="fill"
    [class.mat-form-field-disabled]="readonly || disabled"
    [class.mat-form-field-readonly]="readonly"
    [class.mat-form-field-invalid]="!valid"
    [class.vc-filter-minimal-styles]="minimalStyles">
    <mat-label>{{ label }}</mat-label>
    <mat-select
        #selectInput
        disableOptionCentering
        multiple
        [required]="required"
        [disabled]="disabled || readonly"
        [(ngModel)]="values"
        (openedChange)="openedChange($event)">
        <mat-select-trigger>
            {{ displaySelected() }}
        </mat-select-trigger>
        <vc-input
            *ngIf="showFilter"
            prefixIcon="search"
            [label]="filterLabel"
            [placeholder]="filterPlaceholder"
            [(value)]="filter"></vc-input>
        <mat-option
            *ngFor="let option of options | search : filterKey : filter"
            [value]="option"
            (onSelectionChange)="selectionChange($event)">
            <vc-tooltip-text *ngIf="!optionTemplate"
                             [text]=" itemRenderer ? itemRenderer(option) : option "></vc-tooltip-text>
            <ng-container
                [ngTemplateOutlet]="optionTemplate"
                [ngTemplateOutletContext]="{ $implicit: option }"></ng-container>
        </mat-option>
    </mat-select>
    <div matSuffix class="vc-field-suffix">
        <mat-icon *ngIf="suffixIcon && values?.length === 0">{{ suffixIcon }}</mat-icon>
        <vc-button
            *ngIf="values && values.length > 0 && showClear && !disabled && !readonly"
            iconColor="var(--text-high-emphasis)"
            ariaLabel="Clear input"
            iconName="close"
            mode="icon"
            (trigger)="$event.stopPropagation(); clear()"></vc-button>
    </div>
    <mat-hint *ngIf="!valid" class="mat-error vc-error-message">{{ errorMessage }}</mat-hint>
</mat-form-field>
