import { Directive, Input, TemplateRef } from '@angular/core';
import { Params } from '@angular/router';

@Directive({
    selector: '[vcTab]',
})
export class TabDirective {
    /** Tab label. */
    @Input()
    label!: string;

    /** Aria label for the tab. */
    @Input()
    ariaLabel!: string;

    /** Reference to the element that the tab is labelled by. Will be cleared if aria-label is set at the same time. */
    @Input()
    ariaLabelledby!: string;

    /** Icon name for tab header, if set, icon will show up before tab label. */
    @Input()
    icon!: string;

    /** Text for badge shown after tab label as superscript */
    @Input()
    badge?: number;

    /** Whether the tab is disabled. */
    @Input()
    disabled: boolean = false;

    /** Enables the tab label to be a routerLink */
    @Input()
    link!: string;

    /** Query parameters for routerLink */
    @Input()
    queryParams!: Params | null;

    constructor(public tabTemplate: TemplateRef<any>) {}
}
