import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonUtil } from '@libs/vc-core-lib';

@Component({
    selector: 'vc-secure-edge-public-website',
    templateUrl: './secure-edge-public-website.component.html',
    styleUrls: ['./secure-edge-public-website.component.scss'],
})
export class SecureEdgePublicWebsiteComponent implements OnInit {
    domain: string | undefined;

    constructor(private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            this.domain = params['domain'] || '';
            CommonUtil.hideAppLoader();
        });
    }
}
