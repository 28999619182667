import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormComponent, NotificationService, Phone, Validation } from '@libs/vc-common-ui-lib';
import { UserRegistrationService, ActivationCodeDetails, RegistrationInfo, CommonUtil } from '@libs/vc-core-lib';
import { finalize, take } from 'rxjs';

@Component({
    selector: 'vc-user-registration',
    templateUrl: './user-registration.component.html',
    styleUrls: ['./user-registration.component.scss'],
    providers: [UserRegistrationService],
})
export class UserRegistrationComponent implements OnInit {
    passwordDescription = $localize`:@@LOGIN.PASSWORD_REQUIREMENTS_TEXT:Password must be 8 to 32 characters, contain at least 1 upper case, 1 lower case, 1 number and 1 special character.`;
    loading = true;

    code!: string;
    email!: string;
    companyName!: string;
    programName!: string;
    phone!: Phone;
    username!: string;
    newPassword!: string;
    currentYear = 2023;

    @ViewChild('userRegistrationForm')
    userRegistrationForm!: FormComponent;

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _userRegistrationService: UserRegistrationService,
        private _notificationService: NotificationService
    ) {
        this.currentYear = new Date().getFullYear();
    }

    ngOnInit(): void {
        // Retrieve URL params
        const params = this._route.snapshot.queryParams;
        this.code = params['code'];

        if (!this.code) {
            CommonUtil.hideAppLoader();
            this._notificationService.error(
                '',
                $localize`:@@LOGIN.USER_REGISTRATION.ERROR_LOADING_REGISTRATION:There was an issue loading registration.  Please try again.`
            );
            this.loading = false;
            return;
        }

        this._userRegistrationService
            .registrationLookup(this.code)
            .pipe(
                take(1),
                finalize(() => {
                    CommonUtil.hideAppLoader();
                    this.loading = false;
                })
            )
            .subscribe({
                next: (activation: ActivationCodeDetails) => {
                    this.companyName = activation.companyName;
                    this.programName = activation.programmeName;
                },
                error: () => {
                    this._notificationService.error(
                        '',
                        $localize`:@@LOGIN.USER_REGISTRATION.ERROR_LOADING_REGISTRATION:There was an issue loading registration.  Please try again.`
                    );
                },
            });
    }

    registerUser() {
        if (!this.userRegistrationForm.submit()) return;

        const user = {
            code: this.code,
            phone: this.phone?.number ?? '',
            phoneCountryCode: this.phone?.country?.phoneCode ?? '',
            email: this.email,
            username: this.username,
            password: this.newPassword,
        } as RegistrationInfo;

        this._userRegistrationService
            .register(user)
            .pipe(take(1))
            .subscribe({
                next: () => {
                    this._notificationService.success(
                        '',
                        $localize`:@@LOGIN.USER_REGISTRATION.SUCCESS_REGISTRATION:Registration successfully completed`
                    );
                    this._router.navigate(['/login']);
                },
                error: () => {
                    this._notificationService.error(
                        '',
                        $localize`:@@LOGIN.USER_REGISTRATION.ERROR_REGISTRATION:There was an error registering.  Please try again later.`
                    );
                },
            });
    }

    validateEmail = (value: string): string => {
        return value != '' && !Validation.validateEmail(value)
            ? $localize`:@@EMAIL_VALIDATOR.EMAIL_IS_NOT_VALID:Email is not valid`
            : '';
    };
}
