import { Component } from '@angular/core';

@Component({
    selector: 'vc-login-view',
    templateUrl: './login-view.component.html',
    styleUrls: ['./login-view.component.scss'],
})
export class LoginViewComponent {
    currentYear = 2023;

    constructor() {
        this.currentYear = new Date().getFullYear();
    }
}
