import { Component, Input } from '@angular/core';

@Component({
    selector: 'vc-text-line',
    templateUrl: './text-line.component.html',
    styleUrls: ['./text-line.component.scss'],
})
export class TextLineComponent {
    @Input()
    text!: string;

    @Input()
    id!: string;

    @Input()
    color!: string;
}
