import { Directive, Input, TemplateRef } from '@angular/core';

@Directive({
    selector: '[vcColumn]',
})
export class ColumnDirective {
    /** Column header name  */
    @Input()
    header = '';

    /** Column field, using for column selector whether to show or hide column, also using in api for sorting  */
    @Input()
    field = '';

    /** Column width, can be set in px, %, em etc. */
    @Input()
    width!: string;

    /** Column minimum width in px */
    @Input()
    minWidth: number = 100;

    /** Whether to display column in table */
    @Input()
    visible = true;

    /** When enabled, columns can be resized using drag and drop */
    @Input()
    resizable = true;

    /** When enabled, columns can be sorted */
    @Input()
    sortable = false;

    /** Filter template. */
    @Input()
    filterTemplate!: TemplateRef<any>;

    get filterField(): string {
        return `filter_${this.field}`;
    }

    constructor(public columnTemplate: TemplateRef<any>) {}
}
