import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin, Observable, of, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { ENV_CONFIG, EnvironmentConfig, TagModel } from '@libs/vc-core-lib';

@Injectable()
export class ReferenceTagService {
    private _httpClient: HttpClient;
    private readonly _serviceBaseUrl: string;
    private _environment: EnvironmentConfig;

    private _externalOrg!: string;
    private _externalTags!: TagModel[];
    private _internalTags!: TagModel[];
    private _privateTags!: TagModel[];

    constructor(protected injector: Injector) {
        this._httpClient = injector.get(HttpClient);
        this._environment = injector.get(ENV_CONFIG);
        this._serviceBaseUrl = 'tag';
    }

    public getInternalTags(): Observable<TagModel[]>  {
        return this._internalTags ? of(this._internalTags) : this._httpClient
            .get<TagModel[]>(`${this._environment.APP.VC_REFERENCE_BASE_URL}/${this._serviceBaseUrl}/internal`).pipe(tap((tags: TagModel[]) => this._internalTags = tags))
    }

    public getExternalTags(org: string): Observable<TagModel[]>  {
        if (!this._externalTags || this._externalOrg !== org) {
            let params = new HttpParams();
            params = params.set('org', org);
            this._externalOrg = org;
            return this._externalTags ? of(this._externalTags) : this._httpClient
                .get<TagModel[]>(`${this._environment.APP.VC_REFERENCE_BASE_URL}/${this._serviceBaseUrl}/external`, { params }).pipe(tap((tags: TagModel[]) => this._externalTags = tags))
        }

        return of(this._externalTags);
    }

    public getPrivateTags(): Observable<TagModel[]> {
        return this._privateTags ? of(this._privateTags) : this._httpClient.get<TagModel[]>(
            `${this._environment.APP.VC_REFERENCE_BASE_URL}/${this._serviceBaseUrl}/private`
        ).pipe(tap((tags: TagModel[]) => this._privateTags = tags));
    }

    public deletePrivateTag(name: string): Observable<null> {
        return this._httpClient.delete<null>(
            `${this._environment.APP.VC_REFERENCE_BASE_URL}/${this._serviceBaseUrl}/private/${name}`
        );
    }

    public upsertTags(tags: Array<string>, org: string): Observable<void> {
        let params = new HttpParams();
        params = params.set('org', org);
        return this._httpClient.put<void>(
            `${this._environment.APP.VC_REFERENCE_BASE_URL}/${this._serviceBaseUrl}/external/bulk`,
            tags,
            {
                params: params,
            }
        );
    }

    public createPrivateTag(name: string): Observable<null> {
        return this._httpClient.put<null>(
            `${this._environment.APP.VC_REFERENCE_BASE_URL}/${this._serviceBaseUrl}/private/${name}`,
            null
        );
    }

    public getTags(
        org: string,
        callExternal: boolean,
        callInternal: boolean,
        callPrivate: boolean
    ): Observable<TagModel[]> {
        const calls: Observable<TagModel[]>[] = [];
        callExternal && calls.push(this.getExternalTags(org));
        callInternal && calls.push(this.getInternalTags());
        callPrivate && calls.push(this.getPrivateTags());

        return forkJoin(calls).pipe(
            // You need this flattening operation!!
            map((data) => data.reduce((acc, cur) => [...acc, ...cur], []))
        );
    }
}
