import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionItem } from '../../interfaces/action-item';

/** Example of action menu usage
 * HTML
 * <vc-action-menu [actions]="actions" (trigger)="actionClick($event)"></vc-action-menu> *
 * TS
 * enum Action {
 *     DELETE,
 *     EDIT,
 * }
 *         actions: ActionItem<Action>[] = [
 *         {
 *             action: Action.EDIT,
 *             label: 'Edit',
 *         },
 *         {
 *             action: Action.DELETE,
 *             label: 'Delete',
 *         },
 *     ];
 *
 *    async actionClick(action: ActionItem<Action>) {
 *         switch (action.action) {
 *             case Action.EDIT:
 *                 Edit logic here
 *                 break;
 *             case Action.DELETE:
 *                 Delete logic here
 *                 break;
 *             default:
 *                 break;
 *         }
 *     }
 *
 */
@Component({
    selector: 'vc-action-menu',
    templateUrl: './action-menu.component.html',
    styleUrls: ['./action-menu.component.scss'],
})
export class ActionMenuComponent<T> {
    @Input()
    actions: ActionItem<T>[] = [];

    @Input()
    ariaLabel: string = 'Actions';

    @Output()
    trigger = new EventEmitter<ActionItem<T>>();

    @Output()
    open = new EventEmitter();

    @Input()
    disabled: boolean = false;
}
