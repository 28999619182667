import { Injectable, Injector } from '@angular/core';
import { IEventModel } from '../model/event.model';
import { BaseService, IExporterPayloadDTO, PageResponse } from '@libs/vc-core-lib';
import { IEventCriteriaDTO } from '../dto/event.criteria.dto';
import { Observable } from 'rxjs';
import { IEventSearchAggregationBySeverityDTO } from '../dto/event.search-aggregation-by-severity.dto';
import { IDashboardModel } from '../model/dashboard.model';
import { HttpParams } from '@angular/common/http';
import { IEventSearchDTO } from '../dto/event.search.dto';
import { ISecureEdgeSearchDTO } from '../model/secure-edge.model';

@Injectable()
export class EventService extends BaseService<IEventModel> {
    constructor(protected injector: Injector) {
        super(injector, 'event');
    }

    private static _selectedEventDetail: IEventModel;

    public getSelectedEventDetail(): IEventModel {
        return EventService._selectedEventDetail;
    }

    public setSelectedEventDetail(selectedEventDetail: IEventModel): void {
        EventService._selectedEventDetail = selectedEventDetail;
    }

    public searchEventAggregationBySeverity(
        criteria: IEventCriteriaDTO
    ): Observable<IEventSearchAggregationBySeverityDTO> {
        return this.httpClient.post<any>(
            `${this.environment.APP.VC_EVENT_BASE_URL}/${this.serviceBaseUrl}/severity/aggregation`,
            criteria
        );
    }

    public eventSearch(searchDTO: IEventSearchDTO): Observable<PageResponse<IEventModel>> {
        return this.httpClient.post<PageResponse<IEventModel>>(
            `${this.environment.APP.VC_EVENT_BASE_URL}/${this.serviceBaseUrl}/pageSearch`,
            searchDTO
        );
    }

    public secureEdgeSearch(secureEdgeDTO: ISecureEdgeSearchDTO): Observable<PageResponse<IEventModel>> {
        return this.httpClient.post<PageResponse<IEventModel>>(
            `${this.environment.APP.VC_EVENT_BASE_URL}/${this.serviceBaseUrl}/pageSearch`,
            secureEdgeDTO
        );
    }

    public getDashboardResult(from: string, to: string, dateInterval: string): Observable<IDashboardModel> {
        return this.httpClient.get<IDashboardModel>(
            `${this.environment.APP.VC_EVENT_BASE_URL}/${this.serviceBaseUrl}/dashboard`,
            { params: new HttpParams().set('from', from).set('to', to).set('dateInterval', dateInterval) }
        );
    }

    public exportEvent(payload: IExporterPayloadDTO<IEventCriteriaDTO>): Observable<Blob> {
        const url: string = `${this.environment.APP.VC_EVENT_BASE_URL}/${this.serviceBaseUrl}/export`;
        return this.httpClient.post<Blob>(url, payload, { observe: 'body', responseType: 'blob' as 'json' });
    }

    public get(id: string): Observable<IEventModel> {
        return this.httpClient.get<IEventModel>(
            `${this.environment.APP.VC_EVENT_BASE_URL}/${this.serviceBaseUrl}/${id}`
        );
    }
}
