<vc-common-autocomplete
    [label]="label"
    [placeholder]="placeholder"
    [(value)]="value"
    [disabled]="disabled"
    [required]="required"
    [readonly]="readonly"
    [options]="countries"
    [minimalStyles]="minimalStyles"
    [suffixIcon]="suffixIcon"
    [showClear]="showClear"
    [validationFunction]="validationFunction"
    [optionTemplate]="optionRendererTemplate"
    [prefixTemplate]="flagTemplate"
    [itemRenderer]="itemRenderer">
</vc-common-autocomplete>
<ng-template #optionRendererTemplate let-country>
    <vc-country-flag [countryClass]="country?.alpha2?.toLowerCase()"></vc-country-flag>
    <span *ngIf="showName" style="margin-right: 5px">{{ country?.name ?? '' }}</span>
    <span *ngIf="showPhoneCode">+{{ country?.phoneCode ?? '' }}</span>
</ng-template>
<ng-template #flagTemplate let-country>
    <vc-country-flag [countryClass]="country?.alpha2?.toLowerCase()"></vc-country-flag>
</ng-template>
