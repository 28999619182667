import { Component, ContentChildren, EventEmitter, Input, OnDestroy, Optional, Output, QueryList } from '@angular/core';
import { Subscription } from 'rxjs';
import { RadiobuttonDirective } from './radiobutton/radiobutton.directive';
import { FormComponent } from '../form/form.component';
import { BaseFieldComponent } from '../base-field/base-field';

/** Example of radiobutton group usage
 *        <vc-radiogroup
 *             [(value)]="radiobuttonSelected"
 *             [label]="'Radio Buttons'"
 *             (valueChange)="radiobuttonChange()">
 *             <ng-template vcRadiobutton
 *                 *ngFor="let option of radiobuttonOptions"
 *                 [label]="option"
 *                 [value]="option"></ng-template>
 *         </vc-radiogroup>
 *     </ng-template>
 * TS
 *
 * export enum MyOptions {
 *     ZERO = 'ZERO',
 *     ONE = 'ONE',
 *     TWO = 'TWO',
 *     THREE = 'THREE',
 *     FOUR = 'FOUR',
 * }
 *
 *    radiobuttonOptions: MyOptions[] = Object.values(MyOptions);
 *     radiobuttonSelected: MyOptions = MyOptions.ZERO;
 *
 *    valuesChanged(value: MyOptions) {
 *        ......
 *     }
 * */

@Component({
    selector: 'vc-radiogroup',
    templateUrl: './radiogroup.component.html',
    styleUrls: ['./radiogroup.component.scss'],
})
export class RadiogroupComponent<T> extends BaseFieldComponent<T> implements OnDestroy {
    private _subscription = new Subscription();
    labelId: string = `label-${this.id}`;

    /** Value for the radio-group. Should equal the value of the selected radio button if there is a corresponding radio button with a matching value. */
    @Input()
    set value(val: T | null) {
        if (this._value !== val) {
            this._value = val;
            this.valueChange.emit(this._value);
            this.validate();
        }
    }

    get value(): T | null {
        return this._value;
    }

    private _value!: T | null;

    /** Used to set the 'aria-label' attribute on the underlying input element. */
    @Input()
    ariaLabel!: string;

    /** Specifies the direction of the radiobutton items, has two different options 'row' or 'column' */
    @Input()
    direction: 'column' | 'row' = 'column';

    /** Event is fired when a value changes */
    @Output()
    valueChange = new EventEmitter<T | null>();

    @ContentChildren(RadiobuttonDirective)
    set radiobuttons(values: QueryList<RadiobuttonDirective<T>>) {
        this._radiobuttons = values;
    }

    get radiobuttons(): QueryList<RadiobuttonDirective<T>> {
        return this._radiobuttons;
    }

    private _radiobuttons!: QueryList<RadiobuttonDirective<T>>;

    constructor(@Optional() _form: FormComponent) {
        super(_form);
    }

    /** Validation function */
    validate(): boolean {
        this.valid = true;
        if (this.required && this._value == null) {
            this.errorMessage = `${this.label ?? this.fieldText} ${this.requiredText}`;
            this.valid = false;
        }

        if (this.validationFunction != null && this.valid) {
            this.errorMessage = this.validationFunction(this.value);
            this.valid = this.errorMessage == '';
        }

        this.errorMessage = this.valid ? '' : this.errorMessage;

        return this.valid;
    }

    clear() {
        this.readonly && !this.disabled && (this.value = null);
        this.valid = true;
        this.errorMessage = '';
    }

    ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }
}
