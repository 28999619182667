import { Component, ElementRef, EventEmitter, Input, Optional, Output, ViewChild } from '@angular/core';
import { BaseFieldComponent } from '../base-field/base-field';
import { FormComponent } from '../form/form.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { filter, isEqual } from 'lodash-es';

/** Example of chips autocomplete usage
 * HTML
 * <vc-chips-autocomplete label="Chips Autocomplete"
 *     [(values)]="selectedChips"
 *     [required]="true"
 *     [options]="chipsOptions"
 *     [itemRenderer]="chipsAutocompleteItemRenderer"></vc-chips-autocomplete>
 *
 * TS
 *  chipsOptions: { position: number; name: string }[] = [
 *    { position: 1, name: 'Hydrogen' },
 *    { position: 2, name: 'Helium' },
 *     { position: 3, name: 'Lithium' },
 *  ];
 *
 *  selectedChips: { position: number; name: string }[] = [this.chipsOptions[0]];
 *
 *  public chipsAutocompleteItemRenderer = (value: { position: number; name: string }) => value?.name ?? '';
 *
 * */

@Component({
    selector: 'vc-chips-autocomplete',
    templateUrl: './chips-autocomplete.component.html',
    styleUrls: ['./chips-autocomplete.component.scss'],
})
export class ChipsAutocompleteComponent<T> extends BaseFieldComponent<T> {
    filteredOptions!: T[];
    separatorKeysCodes: number[] = [ENTER, COMMA];
    filterValue: any = '';
    private _filterChangeTimer!: any;

    @Input()
    set values(val: T[]) {
        if (val != this._values) {
            this._values = val;
            this._setAutocompleteItems();
            this.valuesChange.emit(this._values);
            this.validate();
        }
    }

    get values(): T[] {
        return this._values;
    }

    private _values: T[] = [];

    /** List of options for chips autocomplete */
    @Input()
    set options(value: T[]) {
        this._options = value;
        this.filteredOptions = this._options;
        this._setAutocompleteItems();
    }

    get options(): T[] {
        return this._options;
    }

    private _options: T[] = [];

    /** Autocomplete placeholder value */
    @Input()
    placeholder!: string;

    /** Identifies the element (or elements) that describes the autocomplete. */
    @Input()
    ariaDescribedBy!: string;

    /** Suffix icon. */
    @Input()
    suffixIcon!: string;

    /** Function for rendering options list and selected values */
    @Input()
    itemRenderer: (data: T) => string = (value: T) => `${value}`;

    /** Debounce interval for filter change. */
    @Input()
    filterDebounceInterval: number = 500;

    /** Two ways data binding for autocomplete value */
    @Output()
    valuesChange = new EventEmitter<T[]>();

    /** Two ways data binding for filter value */
    @Output()
    filterChange = new EventEmitter<string>();

    @ViewChild('autocompleteInput')
    autocompleteInput!: ElementRef<HTMLInputElement>;

    @ViewChild(MatAutocompleteTrigger)
    autocomplete!: MatAutocompleteTrigger;

    constructor(@Optional() _form: FormComponent) {
        super(_form);
    }

    remove(chipData: T): void {
        this.values = filter(this.values, (value) => !isEqual(chipData, value));
        this.filteredOptions = [...this.filteredOptions, chipData];
        this.autocomplete.closePanel();
    }

    selectValue(event: MatAutocompleteSelectedEvent): void {
        this.values = [...this.values, event.option.value];
        this.autocompleteInput.nativeElement.value = '';
    }

    inputValueChanges() {
        // Filter items only when user typing
        if (typeof this.filterValue === 'string' || typeof this.filterValue === 'number') {
            this.filteredOptions = this._filter(this.filterValue);
        }
        this._emitFilterChange();
    }

    /** Validation function */
    validate(): boolean {
        this.valid = true;
        if (this.required && this._values.length == 0) {
            this.errorMessage = `${this.label ?? this.fieldText} ${this.requiredText}`;
            this.valid = false;
        }

        if (this.validationFunction != null && this.valid) {
            this.errorMessage = this.validationFunction(this._values);
            this.valid = this.errorMessage == '';
        }

        this.errorMessage = this.valid ? '' : this.errorMessage;

        return this.valid;
    }

    clear() {
        !this.readonly && !this.disabled && (this.values = []);
        this.filterValue = '';
        this.filterChange.emit(this.filterValue);
        this.filteredOptions = this.options;
        this.valid = true;
        this.touched = false;
    }

    private _setAutocompleteItems() {
        this.filteredOptions = filter(this.filteredOptions, (value) => !this._values.includes(value));
    }

    private _filter(value: string | number): T[] {
        const filterValue = value?.toString()?.toLowerCase();
        return this.options?.filter(
            (option: T) =>
                (this.itemRenderer(option) ?? '').toString()?.toLowerCase()?.includes(filterValue) &&
                !this._values.includes(option)
        );
    }

    private _emitFilterChange() {
        clearTimeout(this._filterChangeTimer);
        this._filterChangeTimer = setTimeout(() => {
            if (typeof this.filterValue === 'string' || typeof this.filterValue === 'number') {
                this.filterChange.emit(this.filterValue.toString());
            }
        }, this.filterDebounceInterval);
    }
}
