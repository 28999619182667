<div [hidden]="showOnlyBrowseButton" class="vc-file-upload">
    <div class="vc-dnd-select-file-container">
        <div
            class="vc-dropzone"
            [ngClass]="fileHover ? 'dropzone-hover' : ''"
            draggable="true"
            (dragenter)="onDragEnter($event)"
            (dragover)="$event.preventDefault()"
            (drop)="onDrop($event)"
            (dragleave)="onDragLeave($event)">
            <div class="vc-dnd-instructions">
                <div class="vc-uploader-inst-and-icon">
                    <span class="material-icons vc-upload-cloud-icon">cloud_upload</span>
                    <div class="vc-upload-requirements">
                        <span class="body-2">Drag and drop your file</span>
                        <div *ngIf="acceptedTypes && acceptedTypes.length > 0">
                            {{ '* Accepted file types: ' + acceptedExtensions }}
                        </div>
                        <div *ngIf="maxFileSize">
                            {{ '* Maximum file size: ' + getFormatSize(maxFileSize) }}
                        </div>
                    </div>
                </div>
                <ng-template *ngTemplateOutlet="selectFilesButton"></ng-template>
            </div>
        </div>
    </div>

    <div *ngIf="files.length > 0" class="vc-dnd-actions">
        <ng-template *ngTemplateOutlet="uploadFilesButton"></ng-template>
        <ng-template *ngTemplateOutlet="resetButton"></ng-template>
    </div>

    <mat-hint *ngIf="errorMessage" class="mat-error vc-error-message">
        <mat-icon class="vc-error-message-icon">error</mat-icon>
        {{ errorMessage }}
    </mat-hint>
    <div *ngIf="files.length > 0" class="vc-selected-files-list">
        <div *ngFor="let file of files; index as i" class="vc-selected-file">
            <div class="vc-selected-file-thumb">
                <img
                    *ngIf="isImage(file); else notImageType"
                    [src]="getThumbnail(file) ?? ''"
                    class="vc-thumbnail"
                    [alt]="'upload thumbnail'" />
                <ng-template #notImageType>
                    <mat-icon fontIcon="description"></mat-icon>
                </ng-template>
            </div>
            <div class="vc-selected-file-description">
                <div class="vc-selected-file-name">{{ file.name }}</div>
                <div class="vc-selected-file-size">{{ getFormatSize(file.size) }}</div>
            </div>
            <div class="vc-selected-file-delete">
                <vc-button
                    mode="icon"
                    aria-label="Remove file"
                    iconName="delete"
                    iconColor="var(--secondary-800)"
                    (trigger)="deleteFileAtIndex(i)">
                </vc-button>
            </div>
        </div>
    </div>
</div>

<input
    #fileUpload
    type="file"
    class="vc-file-input"
    [(ngModel)]="fileName"
    [multiple]="multiple"
    [accept]="acceptedExtensions"
    (change)="handleFileInput($event)" />

<ng-container *ngIf="showOnlyBrowseButton">
    <ng-template *ngTemplateOutlet="selectFilesButton"></ng-template>
</ng-container>

<ng-template #selectFilesButton>
    <vc-button [label]="selectFilesLabel" mode="basic" (trigger)="fileUpload.click()"></vc-button>
</ng-template>

<ng-template #uploadFilesButton>
    <vc-button
        *ngIf="showUploadFilesButton && files.length > 0"
        iconPrefix="cloud_upload"
        [label]="uploadLabel"
        (trigger)="onUploadFiles()"></vc-button>
</ng-template>

<ng-template #resetButton>
    <vc-button
        *ngIf="showResetButton && files.length > 0"
        label="Reset"
        aria-label="Reset"
        mode="basic"
        (click)="reset()">
    </vc-button>
</ng-template>
