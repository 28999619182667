import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { LoginRoutingModule } from './login-routing.module';
import { NotificationService, VcCommonUiLibModule } from '@libs/vc-common-ui-lib';
import { UserContextService, UserPreferenceService, UserService } from '@libs/vc-core-lib';

import { LoginViewComponent } from './login-view/login-view.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ExternalLoginComponent } from './external-login/external-login.component';
import { UserRegistrationComponent } from './user-registration/user-registration.component';

@NgModule({
    declarations: [
        LoginViewComponent,
        LoginFormComponent,
        ResetPasswordComponent,
        ForgotPasswordComponent,
        ExternalLoginComponent,
        UserRegistrationComponent
    ],
    imports: [CommonModule, LoginRoutingModule, VcCommonUiLibModule, MatIconModule],
    providers: [UserContextService, UserPreferenceService, UserService, NotificationService],
})
export class LoginModule {}
