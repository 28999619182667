import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TagsSelectorComponent } from './tags-selector/tags-selector.component';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule } from '@angular/forms';
import { TagGroupPipe } from './pipe/tag-group/tag-group.pipe';
import { TagsComponent } from './tags/tags.component';
import { TagIconPipe } from './pipe/tag-icon/tag-icon.pipe';
import { MatTooltipModule } from '@angular/material/tooltip';
import { VcCommonUiLibModule } from '@libs/vc-common-ui-lib';

@NgModule({
    declarations: [TagsSelectorComponent, TagGroupPipe, TagsComponent, TagIconPipe],
    imports: [
        CommonModule,
        MatIconModule,
        MatFormFieldModule,
        MatChipsModule,
        MatAutocompleteModule,
        FormsModule,
        MatTooltipModule,
        VcCommonUiLibModule,
    ],
    exports: [TagsSelectorComponent, TagsComponent],
})
export class TagsSelectorModule {}
