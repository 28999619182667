import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {
    Authenticator,
    CommonUtil,
    CoreConstant,
    ILoginModel,
    Language,
    LoginService,
    UserContextService,
    WhoAmI,
} from '@libs/vc-core-lib';
import { FormComponent, InputComponent, NotificationService, PasswordComponent } from '@libs/vc-common-ui-lib';
import { finalize, take } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'vc-login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit, AfterViewInit {
    readonly submitButtonText = $localize`:@@LOGIN.BUTTON.SIGN_IN:Sign In`;
    loading: boolean = false;

    mfaEnabled = false;

    greeting = 'Hello!';
    username!: string;
    password!: string;
    mfaToken!: string;

    @ViewChild('loginFormComponent')
    loginFormComponent!: FormComponent;

    @ViewChild('mfaInput')
    mfaInput!: InputComponent;

    @ViewChild('usernameComponent')
    usernameComponent!: InputComponent;

    @ViewChild('userPasswordComponent')
    userPasswordComponent!: PasswordComponent;

    constructor(
        private _router: Router,
        private _loginService: LoginService,
        private _notificationService: NotificationService,
        private _userContextService: UserContextService
    ) {
        this.mfaEnabled = false;

        // Check URL and reset view state if necessary
        const url = this._router.routerState.snapshot.url;
        if (url === '/login') {
            this.mfaEnabled = false;
        }
        if (url === '/login?verify=true' && !this.mfaEnabled) {
            this._removeParam();
        }
    }

    ngOnInit() {
        this.greeting = this._generateGreeting();
    }

    ngAfterViewInit(): void {
        CommonUtil.hideAppLoader();
    }

    signIn() {
        if (!this.loginFormComponent.submit()) return;

        const formData: ILoginModel = {
            username: this.username,
            password: this.password,
        };
        if (this.mfaToken) {
            formData.mfatoken = this.mfaToken;
        }

        this.login(formData);
    }

    backToLogin() {
        this.mfaEnabled = false;
        this.loginFormComponent.clear();
        this._router.navigate(['']);
    }

    private _generateGreeting() {
        const currentTime = new Date().getHours();
        if (currentTime >= 6 && currentTime < 12) {
            return $localize`:@@LOGIN.GOOD_MORNING:Good morning!`;
        }
        if (currentTime >= 12 && currentTime < 18) {
            return $localize`:@@LOGIN.GOOD_AFTERNOON:Good afternoon!`;
        }
        return $localize`:@@LOGIN.GOOD_EVENING:Good evening!`;
    }

    login(event: ILoginModel) {
        event.authenticator = Authenticator.INTERNAL;
        this.loading = true;
        this._userContextService.clear(); // Clear user context & token in case the user navigated BACK from post-login

        this._loginService
            .login(event)
            .pipe(
                take(1),
                finalize(() => setTimeout(() => (this.loading = false), 300))
            )
            .subscribe({
                next: (user: WhoAmI) => {
                    CommonUtil.showAppLoader();
                    const redirectUrl = this._loginService.redirectUrl ?? '/';
                    this._router.navigate([redirectUrl]).then(() => {
                        user.locale = user.locale ?? Language.EN;
                        this._loginService.redirectUrl = null;
                        if (user.locale !== (localStorage.getItem(CoreConstant.VC_LANGUAGE_ABBREVIATION) as Language)) {
                            CommonUtil.changeLanguage(user?.locale ?? Language.ES);
                        }
                    });
                },
                error: (error: any) => {
                    let errorMsg = '';

                    if (error.status == 401) {
                        if (!this.mfaEnabled) {
                            errorMsg = $localize`:@@LOGIN.VALIDATION.USERNAME_PASS:Invalid Username or Password.`;
                            this.usernameComponent.setErrorState(errorMsg);
                            this.userPasswordComponent.setErrorState(errorMsg);
                            this._notificationService.error('', errorMsg, 20000000);
                        } else {
                            errorMsg = $localize`:@@LOGIN.VALIDATION.AUTH_FAILED:Authentication failed.`;
                            this._notificationService.error('', errorMsg, 20000000);
                        }
                    }

                    if (error.status == 403) {
                        this.mfaEnabled = true;
                        this._router.navigate([], {
                            queryParams: {
                                verify: true,
                            },
                        });
                        setTimeout(() => this.mfaInput?.focus(), 0); // autofocus on auth code input field when it appears
                    }
                },
            });
    }

    private _removeParam() {
        this._router.navigate([], {
            replaceUrl: true,
            queryParams: {},
        });
    }
}
