import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'notificationIcon',
})
export class NotificationIconPipe implements PipeTransform {
    transform(type: 'error' | 'info' | 'success' | 'warning' = 'info', defaultIcon?: string): string {
        if (defaultIcon && defaultIcon !== '') {
            return defaultIcon;
        } else {
            switch (type) {
                case 'error':
                    return 'error';
                case 'info':
                    return 'info';
                case 'success':
                    return 'check_circle';
                case 'warning':
                    return 'warning';
                default:
                    return 'info';
            }
        }
    }
}
