import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Optional,
    Output,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { AuthorityUtil, TagModel, TagUtil, UserContextService, WhoAmI } from '@libs/vc-core-lib';
import { map, Subject, take, takeUntil } from 'rxjs';
import { BaseFieldComponent, FormComponent } from '@libs/vc-common-ui-lib';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { filter, find, flow, isEqual, partialRight, property, some } from 'lodash-es';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Tag } from '../models/tag';
import { MatChipInputEvent } from '@angular/material/chips';
import { ReferenceTagService } from '@libs/vc-reference-data-lib';

@Component({
    selector: 'vc-tags-selector',
    templateUrl: './tags-selector.component.html',
    styleUrls: ['./tags-selector.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class TagsSelectorComponent extends BaseFieldComponent<Tag> implements AfterViewInit {
    private _destroy$: Subject<void> = new Subject();
    private _user!: WhoAmI;
    private _hasInternalReadTagsPerm: boolean = false;
    private _hasInternalAssignTagsPerm: boolean = false;
    private _hasExternalReadTagsPerm: boolean = false;
    private _hasExternalCreateTagsPerm: boolean = false;
    private _hasExternalAssignTagsPerm: boolean = false;
    private _hasPrivateReadTagsPerm: boolean = false;
    private _hasPrivateAssignTagsPerm: boolean = false;

    separatorKeysCodes: number[] = [ENTER, COMMA];

    tags: Tag[] = [];
    filteredTags: Tag[] = [];

    filterValue: any = '';

    @Input()
    set values(val: TagModel[]) {
        if (!isEqual(val, this._values)) {
            this._values = val;
            this._valuesAsStrings = TagUtil.getTagsAsStrings(val);
            this.filteredTags = this._filterGroup('');
            this.valuesChange.emit(this._values);
            this.valuesAsStringsChange.emit(this._valuesAsStrings);
            this.validate();
        }
    }
    get values(): TagModel[] {
        return this._values;
    }
    private _values: TagModel[] = [];

    @Input()
    set valuesAsStrings(tags: string[]) {
        if (!isEqual(tags, this._valuesAsStrings)) {
            this._valuesAsStrings = tags;
            this._values = TagUtil.convertArrayStringToTags(tags);
            this.filteredTags = this._filterGroup('');
            this.valuesChange.emit(this._values);
            this.valuesAsStringsChange.emit(this._valuesAsStrings);
            this.validate();
        }
    }
    get valueAsStrings(): string[] {
        return this._valuesAsStrings;
    }
    private _valuesAsStrings: string[] = [];

    @Input()
    showInternalTags = true;

    @Input()
    placeholder!: string;

    @Input()
    ariaDescribedBy!: string;

    @Input()
    allowSelection: boolean = false;

    @Input()
    allowAddNew: boolean = true;

    @Input()
    showAddDescription: boolean = true;

    @Input()
    showClear: boolean = false;

    @Input()
    suffixIcon!: string;

    @Output()
    valuesChange = new EventEmitter<TagModel[]>();

    @Output()
    valuesAsStringsChange = new EventEmitter<string[]>();

    @ViewChild('autocompleteInput')
    autocompleteInput!: ElementRef<HTMLInputElement>;

    @ViewChild(MatAutocompleteTrigger)
    autocomplete!: MatAutocompleteTrigger;

    // verifies user can select External Tags, by either using allowSelection mode, or having Assign Perm as well as Read Permission
    get checkExternalSelect(): boolean {
        return this._hasExternalReadTagsPerm && (this._hasExternalAssignTagsPerm || this.allowSelection);
    }

    // verifies user can select Internal Tags, by either using allowSelection mode, or having Assign Perm as well as Read Permission
    get checkInternalSelect(): boolean {
        return (
            this.showInternalTags &&
            this._hasInternalReadTagsPerm &&
            (this._hasInternalAssignTagsPerm || this.allowSelection)
        );
    }

    // verifies user can select Private Tags, by either using allowSelection mode or having Assign Perm as well as Read Permission
    get checkPrivateSelect(): boolean {
        return this._hasPrivateReadTagsPerm && (this._hasPrivateAssignTagsPerm || this.allowSelection);
    }

    constructor(
        @Optional() form: FormComponent,
        private _referenceTagService: ReferenceTagService,
        private _userContextService: UserContextService
    ) {
        super(form);
        this._userContextService
            .getContext()
            .pipe(takeUntil(this._destroy$))
            .subscribe((user: WhoAmI | null) => {
                if (user) {
                    this._hasInternalReadTagsPerm = user.hasAuthority(AuthorityUtil.PERMISSION_TAG_INTERNAL_READ);
                    this._hasInternalAssignTagsPerm = user.hasAuthority(AuthorityUtil.PERMISSION_TAG_INTERNAL_ASSIGN);
                    this._hasExternalReadTagsPerm = user.hasAuthority(AuthorityUtil.PERMISSION_TAG_EXTERNAL_READ);
                    this._hasExternalAssignTagsPerm = user.hasAuthority(AuthorityUtil.PERMISSION_TAG_EXTERNAL_ASSIGN);
                    this._hasExternalCreateTagsPerm = user.hasAuthority(AuthorityUtil.PERMISSION_TAG_EXTERNAL_CREATE);
                    this._hasPrivateReadTagsPerm = user.hasAuthority(AuthorityUtil.PERMISSION_TAG_PRIVATE_READ);
                    this._hasPrivateAssignTagsPerm = user.hasAuthority(AuthorityUtil.PERMISSION_TAG_PRIVATE_ASSIGN);
                    this._user = user;
                }
            });
    }

    ngAfterViewInit() {
        this._user && this._getTags();
    }

    inputValueChanges() {
        if (typeof this.filterValue === 'string' || typeof this.filterValue === 'number') {
            this.filteredTags = this._filterGroup(this.filterValue);
        }
    }

    add(event: MatChipInputEvent) {
        if (!this.allowAddNew) {
            return;
        }
        const value = (event.value || '').trim();
        if (value) {
            const tag = {} as TagModel;
            tag.name = value;
            tag.isNew = true;
            tag.tagValue = TagUtil.EXT_TAG + value;
            tag.prefix = TagUtil.EXT_TAG;
            tag.displayPrefix = TagUtil.EXT_TAG;
            tag.displayValue = value;

            const existsInTags = find(this.tags, flow(property('tags'), partialRight(some, { name: tag.name })));
            const existsInSelected = this.values.find((value: TagModel) => value.name === tag.name) != null;
            !existsInSelected && !existsInTags && (this.values = [...this.values, tag]);
        }

        event.chipInput!.clear();
        this.autocompleteInput.nativeElement.value = '';
    }

    remove(tag: TagModel): void {
        if (!this._checkEditPermissions(tag)) {
            return;
        }
        this.values = filter(this.values, (value) => !isEqual(tag, value));
        this.filteredTags = this._filterGroup('');
        this.autocomplete.closePanel();
    }

    selectValue(event: MatAutocompleteSelectedEvent): void {
        const tag = event.option.value;
        this.values = [...this.values, tag];
        this.filteredTags = this._filterGroup('');
        this.autocompleteInput.nativeElement.value = '';
    }

    /** Validation function */
    validate(): boolean {
        this.valid = true;
        if (this.required && this.values?.length === 0) {
            this.errorMessage = `${this.label ?? this.fieldText} ${this.requiredText}`;
            this.valid = false;
        }

        if (this.validationFunction != null && this.valid) {
            this.errorMessage = this.validationFunction(this.values);
            this.valid = this.errorMessage == '';
        }

        return this.valid;
    }

    clear() {
        !this.readonly && !this.disabled && (this.values = []);
        this.filterValue = '';
        this.autocompleteInput.nativeElement.value = '';
        this.filteredTags = this.tags;
        this.valid = true;
        this.touched = false;
    }

    refreshTags() {
        this._getTags();
    }

    private _filterGroup(value: string | number): Tag[] {
        const filterValue = value?.toString();
        return this.tags
            .map((group: Tag) => ({ prefix: group.prefix, tags: this._filterTagModels(group.tags, filterValue) }))
            .filter((group) => group.tags.length > 0);
    }

    private _filterTagModels(tags: TagModel[], value: string): TagModel[] {
        const filterValue = value.toLowerCase();

        return tags.filter((tag: TagModel) => {
            const existsInSelected = this._values.find((value: TagModel) => value.name === tag.name) != null;
            return tag.name.toLowerCase().includes(filterValue) && !existsInSelected;
        });
    }

    private _checkEditPermissions(tag: TagModel): boolean {
        if (this.readonly) {
            return false;
        }
        if (tag.isNew || this.allowSelection) {
            return true;
        }
        return (
            (this.checkInternalSelect && (tag.prefix === '/vc' || tag.prefix === '/vc/')) ||
            (this.checkExternalSelect && (tag.prefix === '/ext' || tag.prefix === '/ext/')) ||
            (this.checkPrivateSelect && (tag.prefix === '/pvt' || tag.prefix === '/pvt/'))
        );
    }

    private _getTags() {
        this._referenceTagService
            .getTags(this._user.getOrgId(), this.checkExternalSelect, this.checkInternalSelect, this.checkPrivateSelect)
            .pipe(
                take(1),
                map((tags: TagModel[]) => TagUtil.convertObjectArrayToTags(TagUtil.format(tags)))
            )
            .subscribe((values: TagModel[]) => {
                this.tags = this._getGroup(values);
                this.filteredTags = this.tags;
            });
    }

    private _getGroup(values: TagModel[]): Tag[] {
        const map = new Map<string, TagModel[]>();
        const tags: Tag[] = [];
        values.forEach((item: TagModel) => {
            const key = item.prefix;
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        map.forEach((value: TagModel[], key: string) => {
            tags.push({
                prefix: key,
                tags: value,
            });
        });

        return tags;
    }

    focus() {
        this.autocompleteInput?.nativeElement?.focus();
    }

    ngOnDestroy(): void {
        this._destroy$.next();
        this._destroy$.complete();
    }
}
