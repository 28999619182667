import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/**
 * @deprecated Use Angular's date pipe instead
 */
@Pipe({
    name: 'vcDate',
})
export class VCDatePipe extends DatePipe implements PipeTransform {
    private static DEFAULT_FORMAT: string = 'yyyy-MM-dd HH:mm:ss';
    private static DEFAULT_TIMEZONE: string = 'utc+0';
    transform(value: any, format?: string, timezone?: string): any {
        let setDateFormat: string = format ? format : VCDatePipe.DEFAULT_FORMAT;
        let setTimezone: string = timezone ? timezone : VCDatePipe.DEFAULT_TIMEZONE;
        return super.transform(value, setDateFormat, setTimezone);
    }
}
