<vc-input
    *ngIf="showSearch"
    class="vc-list-search"
    [label]="searchLabel"
    [showClear]="true"
    [prefixIcon]="'search'"
    [(value)]="searchValue"></vc-input>
<mat-selection-list
    class="vc-selection-list"
    [attr.aria-labelledby]="ariaLabeledBy"
    [attr.aria-describedby]="ariaDescribedby"
    [multiple]="multiple"
    [tabIndex]="tabIndex">
    <ng-container *ngIf="(options | search: searchKey: searchValue) as list">
        <mat-list-option *ngFor="let opt of list"
                         class="vc-list-option"
                         [checkboxPosition]="checkboxPosition"
                         [value]="opt"
                         [selected]="checkIfSelected(opt)"
                         (selectedChange)="selectionChange($event, opt)">
            <span *ngIf="!optionTemplate">{{ itemRenderer(opt) }}</span>
            <ng-container [ngTemplateOutlet]="optionTemplate"
                          [ngTemplateOutletContext]="{ $implicit: opt }"></ng-container>
        </mat-list-option>
        <p *ngIf="list.length === 0"
           style="text-align: center">No Result</p>
    </ng-container>
</mat-selection-list>
