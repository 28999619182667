import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
    TrackByFunction,
    ViewChild,
} from '@angular/core';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { isEqual } from 'lodash-es';

export interface PickListSelection<T> {
    source: T[];
    target: T[];
}

@Component({
    selector: 'vc-pick-list',
    templateUrl: './pick-list.component.html',
    styleUrls: ['./pick-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PickListComponent<T> {
    selectedSourceItem: T | undefined;
    selectedTargetItem: T | undefined;

    sourceSearch: string = '';
    targetSearch: string = '';

    /** Initial array of items for source list */
    @Input()
    source!: T[];

    /** Initial array of items for target list */
    @Input()
    target!: T[];

    /** Custom function for identifying items in the source and target lists.
     *
     * See link for more details: https://angular.io/api/core/TrackByFunction */
    @Input()
    trackBy!: TrackByFunction<T>;

    /** Optionally set a header */
    @Input()
    header!: string;

    /** Optionally set a header label for source listbox */
    @Input()
    sourceLabel!: string;

    /** Optionally set a header label for target listbox */
    @Input()
    targetLabel!: string;

    /** Sets the aria-label for the source listbox */
    @Input()
    sourceAriaLabel!: string;

    /** Sets the aria-label for the target listbox */
    @Input()
    targetAriaLabel!: string;

    /** Placeholder for source search input */
    @Input()
    sourceSearchLabel: string = $localize`:@@CORE.BUTTON.SEARCH:Search`;

    /** Placeholder for target search input */
    @Input()
    targetSearchLabel: string = $localize`:@@CORE.BUTTON.SEARCH:Search`;

    /** Placeholder for source search input */
    @Input()
    sourceSearchPlaceholder: string = '';

    /** Placeholder for target search input */
    @Input()
    targetSearchPlaceholder: string = '';

    /** Search key for source */
    @Input()
    sourceSearchKey: string = '';

    /** Search key for target */
    @Input()
    targetSearchKey: string = '';

    /** Whether to show search input for target */
    @Input()
    showTargetSearch: boolean = true;

    /** Whether to show search input for source */
    @Input()
    showSourceSearch: boolean = true;

    /** Whether to show move all to target button [>>] */
    @Input()
    showMoveAllToTarget: boolean = true;

    /** Whether to show move all to source button [<<] */
    @Input()
    showMoveAllToSource: boolean = true;

    /** Whether to allow management of moving items between Target and Source */
    @Input()
    manageMovementBetweenLists: boolean = true;

    /** Emits array of source/targets items after change */
    @Output()
    selectionChange = new EventEmitter<PickListSelection<T>>();

    /** Emits item moving from source to target */
    @Output()
    moveToTarget = new EventEmitter<T>();

    /** Emits item moving from target to source */
    @Output()
    moveToSource = new EventEmitter<T>();

    /** Emits array of items moving to target */
    @Output()
    moveAllToTarget = new EventEmitter<T[]>();

    /** Emits array of items moving to source */
    @Output()
    moveAllToSource = new EventEmitter<T[]>();

    @ViewChild('sourceList')
    sourceList!: MatSelectionList;

    @ViewChild('targetList')
    targetList!: MatSelectionList;

    @ContentChild('sourceItemTemplate')
    sourceItemTemplate!: TemplateRef<any>;

    @ContentChild('targetItemTemplate')
    targetItemTemplate!: TemplateRef<any>;

    get moveToTargetButtonDisabled(): boolean {
        return !this.selectedSourceItem;
    }

    get moveAllToTargetButtonDisabled(): boolean {
        return this.source?.length === 0 ?? true;
    }

    get moveToSourceButtonDisabled(): boolean {
        return !this.selectedTargetItem;
    }

    get moveAllToSourceButtonDisabled(): boolean {
        return this.target?.length === 0 ?? true;
    }

    onSourceChange(event: MatSelectionListChange) {
        this.selectedSourceItem = event?.source?.selectedOptions?.selected[0]?.value;
    }

    onTargetChange(event: MatSelectionListChange) {
        this.selectedTargetItem = event?.source?.selectedOptions?.selected[0]?.value;
    }

    onMoveToTarget() {
        this.moveToTarget.emit(this.selectedSourceItem);
        if (!this.manageMovementBetweenLists) {
            return;
        }
        if (this.selectedSourceItem) {
            this.target = [...this.target, this.selectedSourceItem];
            this.source = this.source.filter((sourceItem) => !isEqual(sourceItem, this.selectedSourceItem));
        }
        this.sourceList?.deselectAll();
        this.selectedSourceItem = undefined;
        this.selectionChange.emit({ source: this.source, target: this.target });
    }

    onMoveToSource() {
        this.moveToSource.emit(this.selectedTargetItem);
        if (!this.manageMovementBetweenLists) {
            return;
        }
        if (this.selectedTargetItem) {
            this.source = [...this.source, this.selectedTargetItem];
            this.target = this.target.filter((targetItem) => !isEqual(targetItem, this.selectedTargetItem));
        }
        this.targetList?.deselectAll();
        this.selectedTargetItem = undefined;
        this.selectionChange.emit({ source: this.source, target: this.target });
    }

    onMoveAllToTarget() {
        this.moveAllToTarget.emit(this.source);
        if (!this.manageMovementBetweenLists) {
            return;
        }
        this.target = [...this.target, ...this.source];
        this.source = [];
        this.sourceList.deselectAll();
        this.selectedSourceItem = undefined;
        this.selectionChange.emit({ source: this.source, target: this.target });
    }

    onMoveAllToSource() {
        this.moveAllToSource.emit(this.target);
        if (!this.manageMovementBetweenLists) {
            return;
        }
        this.source = [...this.source, ...this.target];
        this.target = [];
        this.targetList.deselectAll();
        this.selectedTargetItem = undefined;
        this.selectionChange.emit({ source: this.source, target: this.target });
    }

    drop(event: CdkDragDrop<T[], any, any>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(
                event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        }
        this.selectionChange.emit({ source: this.source, target: this.target });
    }
}
