import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VcCommonUiLibModule } from '@libs/vc-common-ui-lib';

import { AccountSettingsRoutingModule } from './account-settings-routing.module';
import { AccountSettingsViewComponent } from './account-settings-view/account-settings-view.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { UserService } from '@libs/vc-core-lib';
import { MatIconModule } from '@angular/material/icon';
import { ChangePasswordDialogComponent } from '../change-password-dialog/change-password-dialog.component';
import { ReferenceService } from '@libs/vc-reference-data-lib';
import { QRCodeModule } from 'angularx-qrcode';
import { MfaModule, UserDetailsComponent } from '@libs/vc-shared-ui-lib';
import { OrgService } from '@libs/vc-org-lib';

@NgModule({
    declarations: [AccountSettingsViewComponent, AccountSettingsComponent],
    imports: [
        CommonModule,
        AccountSettingsRoutingModule,
        VcCommonUiLibModule,
        MatIconModule,
        ChangePasswordDialogComponent,
        QRCodeModule,
        CommonModule,
        MfaModule,
        UserDetailsComponent,
    ],
    providers: [OrgService, UserService, ReferenceService],
    exports: [AccountSettingsViewComponent],
})
export class AccountSettingsModule {}
